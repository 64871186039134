/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Switch } from 'antd';
import _ from 'lodash';

import { RichTextEditor } from 'components/richTextEditor';
import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { QUESTION_CONSTANTS } from 'utils/constants';
import {
  getNonNpsQuestionTypes,
  getQuestionTypes,
} from 'utils/constants/questionTypes';
import { surveyHelper } from 'utils/helpers';

import MultipleChoiceAnswer from '../surveyBuilderAnswers/MultipleChoiceAnswer';

import './_surveyBuilderQuestion.scss';
import 'draft-js/dist/Draft.css';

function SurveyBuilderQuestion({
  question,
  sectionId,
  isDeletable,
  setQuestion,
  setQuestionsEdited,
  setIsSimilarErrorActive,
  questionErrors,
  duplicateOptionsSections,
}) {
  const { t } = useTranslation();
  const { formatSurveyQuestion, createMarkup } = surveyHelper;
  const { ATTACHMENT, ATTACHMENTS, CHECKBOX, RADIO, RATING, TEXT } =
    QUESTION_CONSTANTS;

  const QUESTION_TYPES = useMemo(() => getQuestionTypes(), []);
  const NON_NPS_QUESTION_TYPES = useMemo(() => getNonNpsQuestionTypes(), []);

  const ref = useRef();

  const isRequiredRatingQuestion =
    !isDeletable && question.question_type === 'rating' && question.is_required;

  const initialQuestionRef = useRef(question);
  const [isExpanded, setIsExpanded] = useState(false);

  const hasQuestionChanged = () => {
    const initialQuestion = initialQuestionRef.current;
    const initialOptions = initialQuestion.options.map(
      // eslint-disable-next-line no-underscore-dangle
      (opt) => createMarkup(opt.label).__html,
    );
    const newOptions = question.options.map(
      // eslint-disable-next-line no-underscore-dangle
      (opt) => createMarkup(opt.label).__html,
    );
    const optionsChanged =
      _.difference(initialOptions, newOptions).length !== 0 ||
      _.difference(newOptions, initialOptions).length !== 0;
    if (!initialQuestion.question_uuid) return false;
    return (
      // eslint-disable-next-line no-underscore-dangle
      createMarkup(initialQuestion.label).__html !==
        // eslint-disable-next-line no-underscore-dangle
        createMarkup(question.label).__html ||
      initialQuestion.question_type !== question.question_type ||
      optionsChanged ||
      !_.isEqual(initialQuestion.attachment_types, question.attachment_types) ||
      initialQuestion.is_required !== question.is_required ||
      initialQuestion.max_number_of_choices !==
        question.max_number_of_choices ||
      initialQuestion.min_number_of_choices !== question.min_number_of_choices
    );
  };

  const getUpdatedQuestionData = (data = {}) => {
    return formatSurveyQuestion({
      ...question,
      ...data,
    });
  };

  const handleSelectQuestionType = (questionType) => {
    let { options } = question;
    if (questionType === RATING) {
      options = options.filter((option) => !option.is_other_option);
    }
    setQuestion(
      getUpdatedQuestionData({
        options,
        max_number_of_choices:
          question.max_number_of_choices || question.options.length,
        question_type: questionType,
      }),
    );
  };

  const handleSetQuestion = (label) => {
    setQuestion(getUpdatedQuestionData({ label }));
  };

  const handleSetOptions = (options) => {
    setQuestion(getUpdatedQuestionData({ options }));
  };

  const handleRequiredChange = (checked) => {
    if (isRequiredRatingQuestion)
      return toast.warn(t('formBuilder.genericErrors.requiredQuestion'));
    return setQuestion(getUpdatedQuestionData({ is_required: checked }));
  };

  const handleSetAllowedAnswers = (minAllowedAnswers, maxAllowedAnswers) => {
    setQuestion(
      getUpdatedQuestionData({
        min_number_of_choices: minAllowedAnswers,
        max_number_of_choices: maxAllowedAnswers,
      }),
    );
  };

  const handleAttachmentTypeChange = (event) => {
    const attachmentTypes = [...question.attachment_types] || [];
    const { value } = event.target;
    if (event.target.checked) attachmentTypes.push(value);
    else attachmentTypes.splice(attachmentTypes.indexOf(value), 1);
    setQuestion(getUpdatedQuestionData({ attachment_types: attachmentTypes }));
  };

  useEffect(() => {
    if (setQuestionsEdited) {
      setQuestionsEdited(hasQuestionChanged());
    }
  }, [question]);

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className="surveyBuilderQuestionWrapper" ref={ref}>
      <div className="surveyBuilderQuestionRow">
        <div className="survey-builder__question-row-question">
          <img
            src="/assets/icons/icon_expand.svg"
            style={{ marginTop: '0.75rem' }}
            className={isExpanded ? 'up pointer' : 'down pointer'}
            onClick={() => setIsExpanded(!isExpanded)}
            alt=""
          />
          <div className="editor-container" style={{ flexGrow: 1 }}>
            <RichTextEditor
              placeholder={t('formBuilder.placeholders.question')}
              setState={handleSetQuestion}
              content={question.label}
              error={questionErrors?.label?.error}
              pasteFormatted
            />
          </div>
        </div>
        <SelectAndCreateOptionDropdown
          disabled={isRequiredRatingQuestion}
          options={NON_NPS_QUESTION_TYPES}
          selectedOption={QUESTION_TYPES[question.question_type]?.id}
          onChange={handleSelectQuestionType}
          showSearch={false}
          popupMatchSelectWidth={false}
          placement="bottomLeft"
        />
      </div>
      {isExpanded &&
        (question.question_type === CHECKBOX ||
          question.question_type === RADIO ||
          question.question_type === RATING) && (
          <MultipleChoiceAnswer
            question={question}
            setQuestion={setQuestion}
            sectionId={sectionId}
            questionId={question.id}
            isCompulsoryQuestion={isRequiredRatingQuestion}
            type={question.question_type}
            options={question.options}
            setOptions={handleSetOptions}
            minAllowedAnswers={question.min_number_of_choices || 1}
            maxAllowedAnswers={question.max_number_of_choices}
            onAllowedAnswersChange={handleSetAllowedAnswers}
            setIsSimilarErrorActive={setIsSimilarErrorActive}
            questionErrors={questionErrors}
            duplicateOptionsSections={duplicateOptionsSections}
          />
        )}
      {isExpanded && question.question_type === TEXT && (
        <input type="text" disabled className="textInput" />
      )}
      {isExpanded && question.question_type === ATTACHMENT && (
        <div className="attachmentsWrapper">
          <p>{t('formBuilder.allowedAttachments')}</p>
          <ul className="attachmentsList">
            <li className="attachmentElement">
              <input
                type="checkbox"
                name="attachmentRadio"
                id={`radio-image-${question.id}`}
                value={ATTACHMENTS.IMAGE}
                onChange={handleAttachmentTypeChange}
                defaultChecked
                checked={question.attachment_types.includes(ATTACHMENTS.IMAGE)}
              />
              <label htmlFor={`radio-image-${question.id}`}>
                {t('imageVideo')}
              </label>
            </li>
            <li className="attachmentElement">
              <input
                type="checkbox"
                name="attachmentRadio"
                id={`audio-image-${question.id}`}
                value={ATTACHMENTS.AUDIO}
                onChange={handleAttachmentTypeChange}
                checked={question.attachment_types.includes(ATTACHMENTS.AUDIO)}
              />
              <label htmlFor="radio-audio">{t('audio')}</label>
            </li>
          </ul>
        </div>
      )}
      <div className="questionFooter">
        {isRequiredRatingQuestion ? (
          <small>{t('formBuilder.genericErrors.requiredRatingQuestion')}</small>
        ) : null}
        <div
          className={`compulsory-rating ${
            isRequiredRatingQuestion ? 'disabled-rating' : ''
          }`}
        >
          <p>{t('required')}</p>
          <Switch
            className="primary-antd-switch-input"
            onChange={handleRequiredChange}
            checked={question.is_required}
            disabled={isRequiredRatingQuestion}
          />
        </div>
      </div>
    </div>
  );
}

export default SurveyBuilderQuestion;
