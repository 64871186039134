import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { Header, Loader } from 'components';
import DashboardAlerts from 'components/dashboardAlerts/DashboardAlerts';
import {
  EDIT_PROFILE_URL,
  INSIGHTS_URL,
  SETTINGS_URL,
} from 'utils/constants/urlConstants';
import { useOnboardingRedirect } from 'utils/hooks';

import './_dashboardLayout.scss';

function DashboardLayoutHeader({ title, subTitle }) {
  return (
    <div className="dashboard-layout-header">
      <h2>{title}</h2>
      <p>{subTitle}</p>
    </div>
  );
}

function DashboardLayout() {
  useOnboardingRedirect();
  const { pathname } = useLocation();
  const parentEntity = useSelector(
    (state) => state.authentication.parentEntity,
  );

  const isNewDesignLayout = useMemo(() => {
    const urlsToCheck = [SETTINGS_URL, EDIT_PROFILE_URL, INSIGHTS_URL];
    if (urlsToCheck.some((url) => pathname.includes(url))) {
      return true;
    }
    return false;
  }, [pathname]);

  if (isEmpty(parentEntity)) return <Loader />;

  return (
    <>
      <Header />
      {isNewDesignLayout ? (
        <main className="cxmeter-main-v2">
          <div className="cxmeter-container-v2">
            <Outlet />
          </div>
        </main>
      ) : (
        <main className="cxmeter-main">
          <div className="cxmeter-container">
            <DashboardAlerts />
            <Outlet />
          </div>
        </main>
      )}
    </>
  );
}
DashboardLayout.DashboardLayoutHeader = DashboardLayoutHeader;
export default DashboardLayout;
