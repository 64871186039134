import { useLocation, useNavigate } from 'react-router-dom';

import { Avatar } from 'antd';

import { URLS } from 'utils/constants';

function EntityLogo({ entity, isSelectedParentEntity, shape = 'circle' }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogoClick = (e) => {
    if (
      !isSelectedParentEntity ||
      (isSelectedParentEntity && entity?.last_onboarding_url)
    )
      return;
    e.stopPropagation();
    if (pathname !== URLS.DASHBOARD_URL) {
      navigate(URLS.DASHBOARD_URL);
    }
  };

  return (
    <div role="presentation" onClick={handleLogoClick}>
      <Avatar
        size="large"
        shape={shape}
        src={entity?.logo || undefined}
        style={{ backgroundColor: entity?.logo ? 'transparent' : '#d28450' }}
      >
        {!entity?.logo && entity?.name
          ? entity?.name.charAt(0).toUpperCase()
          : 'O'}
      </Avatar>
    </div>
  );
}

export default EntityLogo;
