import { createSlice } from '@reduxjs/toolkit';

import {
  changePassword,
  editProfile,
  getParentEntitiesList,
  getParentEntityAction,
  getUserAndInitialiseData,
  updateParentEntity,
  updateUser,
} from './authenticationActions';

const INITIAL_STATE = {
  isActiveOrg: true,
  user: {},
  parentEntity: {},
  lastSelectedParentEntity: {},
  token: localStorage.getItem('access_token'),
  isLoading: false,
  isAppLoading: true,
  parentEntities: {
    loading: false,
    data: [],
  },
};

const authenticationSlice = createSlice({
  name: 'authenticationSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetAuthenticationSlice: (state, action) => {
      return { ...INITIAL_STATE, isAppLoading: false, ...action.payload };
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAppLoading = false;
    },
    setToken: (state, action) => {
      state.token = action.payload?.access;
    },
    setAppLoading: (state, action) => {
      state.isAppLoading = action.payload;
    },
    setParentEntity: (state, action) => {
      state.parentEntity = action.payload ?? {};
      state.isAppLoading = false;
    },
    setLastOnboardingStep: (state, action) => {
      state.parentEntity.last_onboarding_url = action.payload;
    },
    setQrLinked: (state, action) => {
      state.parentEntity.has_linked_qr_codes = action.payload;
    },
    activateOrg: (state) => {
      state.isActiveOrg = true;
    },
    deactivateOrg: (state) => {
      state.isActiveOrg = false;
    },
    setParentEntities: (state, action) => {
      state.parentEntities.data = action.payload ?? [];
    },
    setLastSelectedParentEntity: (state, action) => {
      state.lastSelectedParentEntity = action.payload ?? {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParentEntitiesList.pending, (state) => {
      state.parentEntities.loading = true;
    });
    builder.addCase(getParentEntitiesList.rejected, (state) => {
      state.parentEntities.loading = false;
    });
    builder.addCase(getParentEntitiesList.fulfilled, (state, action) => {
      state.parentEntities.loading = false;
      state.parentEntities.data = action.payload;
    });

    builder.addCase(getParentEntityAction.fulfilled, (state, action) => {
      state.isActiveOrg = action.payload?.is_active;
      state.parentEntity = action.payload;
    });
    builder.addCase(getUserAndInitialiseData.pending, (state) => {
      state.isAppLoading = true;
    });
    builder.addCase(getUserAndInitialiseData.rejected, (state) => {
      state.isAppLoading = false;
    });
    builder.addCase(getUserAndInitialiseData.fulfilled, (state, action) => {
      state.isAppLoading = false;
      state.user = action.payload?.user;
      state.parentEntity = action.payload?.parentEntity;
    });
    builder.addCase(editProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editProfile.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });

    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    builder.addCase(updateParentEntity.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateParentEntity.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateParentEntity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.parentEntity = action.payload;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });
    return builder;
  },
});
export const {
  resetAuthenticationSlice,
  setUser,
  setToken,
  setParentEntity,
  setAppLoading,
  setLastOnboardingStep,
  setQrLinked,
  activateOrg,
  deactivateOrg,
  setParentEntities,
  setLastSelectedParentEntity,
} = authenticationSlice.actions;
export default authenticationSlice;

export const AUTHENTICATION_STATE = INITIAL_STATE;
