import { t } from 'i18next';

export const RADIO = 'radio';
export const CHECKBOX = 'checkbox';
export const RATING = 'rating';
export const TEXT = 'text';
export const ATTACHMENT = 'attachment';
export const NPS = 'nps';

export const getQuestionTypes = () => ({
  [RADIO]: { id: RADIO, name: t('formBuilder.questionTypes.radio') },
  [CHECKBOX]: { id: CHECKBOX, name: t('formBuilder.questionTypes.checkbox') },
  [RATING]: { id: RATING, name: t('formBuilder.questionTypes.rating') },
  [TEXT]: { id: TEXT, name: t('formBuilder.questionTypes.text') },
  [ATTACHMENT]: {
    id: ATTACHMENT,
    name: t('formBuilder.questionTypes.attachment'),
  },
  [NPS]: { id: NPS, name: t('formBuilder.questionTypes.nps') },
});

export const getNonNpsQuestionTypes = () =>
  Object.values(getQuestionTypes()).filter(
    (questionType) => questionType.id !== NPS,
  );

export const ATTACHMENTS = { IMAGE: 'image/video', AUDIO: 'audio' };

export const OPTION_TYPE_MAPPING = {
  [RADIO]: 'radio_option',
  [CHECKBOX]: 'checkbox_option',
  [RATING]: 'rating_option',
};
