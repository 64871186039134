export function getFullURL(path) {
  return `${window.location.origin}${path}`;
}

export function getPathNameFromFullURL(url) {
  if (!url) return url;
  try {
    const { pathname } = new URL(url);
    return pathname;
  } catch (error) {
    return url;
  }
}
