import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { Empty, Switch } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import InsightsFiltersModal from 'components/insightsReports/modals/InsightsFiltersModal';
import { Icon, Spinner } from 'components/ui';
import UnavailableFeatureWrapper from 'components/unavailableFeatures/UnavailableFeatureWrapper';
import { DashboardLayout } from 'layouts';
import getInsights from 'redux/insights/insightsActions';
import {
  INSIGHTS_FILTERS,
  LATEST_FILTER,
  MONTHLY_INSIGHTS,
  QUARTERLY_INSIGHTS,
} from 'utils/constants';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

import './_insights.scss';

function Insights() {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const {
    insights: {
      data: insights,
      loading,
      date_title: dateTitle,
      insights_type: insightType,
    },
  } = useSelector((state) => state.insightsData);
  const { parentEntity, user } =
    useSelector((state) => state.authentication) ?? {};
  const {
    allowed_analytics: { analytics } = {},
    name,
    logo,
  } = parentEntity ?? {};
  const showInsights = analytics?.insights || user?.is_superuser;

  const reportRef = useRef();

  const formik = useFormik({
    initialValues: {
      filter: INSIGHTS_FILTERS[0],
      viewBy: MONTHLY_INSIGHTS,
      isOpenFiltersModal: false,
    },
  });

  const {
    values: { filter, viewBy },
  } = formik;

  const generateInsightsHandler = async (viewFilter) => {
    const view = viewFilter ?? viewBy;
    const { isError } = await dispatch(
      getInsights({
        year: filter.value ? dayjs(filter.value).year() : null,
        view_by: view,
      }),
    );
    if (!isError && viewFilter !== undefined) {
      formik.setFieldValue('viewBy', viewFilter);
    }
  };

  const handleViewByToggle = () => {
    generateInsightsHandler(
      viewBy === MONTHLY_INSIGHTS ? QUARTERLY_INSIGHTS : MONTHLY_INSIGHTS,
    );
  };

  const printInsights = useReactToPrint({
    content: () => reportRef.current,
    pageStyle: '@page { size: auto;  margin: 15mm; } }',
    documentTitle: `${name}`,
  });

  const handlePrint = () => {
    printInsights();
  };

  useEffect(() => {
    if (showInsights) generateInsightsHandler();
  }, [filter, showInsights]);

  return (
    <UnavailableFeatureWrapper
      featureName={t('insights.title')}
      className="insight__page-container"
      isFeatureAllowed={showInsights}
    >
      <DashboardLayout.DashboardLayoutHeader
        title={t('insights.title')}
        subTitle={t('insights.subTitle')}
      />
      <div className="insights__container">
        {loading ? <Spinner wrapperStyles={{ position: 'fixed' }} /> : null}
        <div className="insights__detail" ref={reportRef}>
          <div className="insights__org__info">
            {logo ? (
              <div
                className="logo"
                style={{ backgroundImage: `url(${logo})` }}
              />
            ) : null}
            {name ? <h3>{name}</h3> : null}
          </div>
          <div className="insights__detail__header">
            <div className="insights__filters">
              <div className="insights__selected__filter">
                <p className="m-0">
                  {filter.label} {t('insights.title')}
                </p>
                <h4 className="m-0">{dateTitle}</h4>
              </div>
              <div>
                <button
                  onClick={() =>
                    formik.setFieldValue('isOpenFiltersModal', true)
                  }
                  className="insights__change__filter cstm-btn tertiary-cstm-btn "
                >
                  <Icon
                    style={{ fontSize: 18, color: '#de0024' }}
                    type="edit"
                  />
                  <span className="change__filter">{t('change')}</span>
                </button>
              </div>
            </div>
            <div className="print__insights">
              {isEmpty(insights) ? null : (
                <button
                  className="cstm-btn secondary-cstm-btn"
                  style={{ fontSize: 16 }}
                  onClick={handlePrint}
                >
                  <Icon
                    style={{ fontSize: 20, color: '#de0024' }}
                    type="print"
                  />
                  <span>{t('printPdf')}</span>
                </button>
              )}
            </div>
          </div>
          {filter.id === LATEST_FILTER ? null : (
            <div className="insights__view_by_toggle">
              <span>{t('month')} </span>
              <Switch
                className="primary-antd-switch-input"
                checked={viewBy === QUARTERLY_INSIGHTS}
                onChange={handleViewByToggle}
              />
              <span> {t('quarter')}</span>
            </div>
          )}
          {isEmpty(insights) ? (
            <Empty description={t('insights.noDataFound')} />
          ) : (
            <div className="insights__detail__data">
              {insights.map((insight) => {
                const positiveAspects = insight.positive.filter(
                  (item) => item.key,
                );
                const negativeAspects = insight.negative.filter(
                  (item) => item.key,
                );
                return (
                  <div key={insight.id} className="insight__data">
                    <div className="insight__general__summary">
                      <h6 className="insight__filter__type">
                        {insightType === MONTHLY_INSIGHTS
                          ? t('insights.monthlyInsights')
                          : t('insights.quarterlyInsights')}
                      </h6>
                      <h5 className="insight__filter__date">
                        {insight.date_range}
                      </h5>
                      <div className="insight__general__summary__detail">
                        <p>{insight.summary}</p>
                      </div>
                    </div>
                    <div className="insight__aspects">
                      {positiveAspects.length ? (
                        <div className="insight__positive__aspects">
                          <div className="insight__aspect">
                            <div>
                              <h5 className="insight__aspect_title">
                                {t('insights.results.positiveAspects')}
                              </h5>
                            </div>
                            <div className="insight__key__words">
                              <div className="key__word__container">
                                {positiveAspects.map((item, index) => (
                                  <span
                                    className="insight__key__pill"
                                    key={index}
                                  >
                                    {item.key}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <div className="insight__aspect__points">
                              {positiveAspects.map((aspect, i) => (
                                <div key={i} className="aspect__point">
                                  <h6>
                                    {i + 1}. {aspect.key} ({aspect.percentage}{' '}
                                    {t('insights.results.positivePercent')})
                                  </h6>
                                  <p>{aspect.description}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {negativeAspects.length ? (
                        <div className="insight__negative__aspects">
                          <div className="insight__aspect">
                            <div>
                              <h5 className="insight__aspect_title">
                                {t('insights.results.negativeAspects')}
                              </h5>
                            </div>
                            <div className="insight__key__words">
                              <div className="key__word__container">
                                {negativeAspects.map((item, index) => (
                                  <span
                                    className="insight__key__pill"
                                    key={index}
                                  >
                                    {item.key}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <div className="insight__aspect__points">
                              {negativeAspects.map((aspect, i) => (
                                <div key={i} className="aspect__point">
                                  <h6>
                                    {i + 1}. {aspect.key} ({aspect.percentage}{' '}
                                    {t('insights.results.negativePercent')})
                                  </h6>
                                  <p>{aspect.description}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="insights__generation__date">
                        <h6>{t('date')}:</h6>
                        <p>{dayjs().format('DD-MM-YYYY')}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <InsightsFiltersModal formik={formik} />
      </div>
    </UnavailableFeatureWrapper>
  );
}
export default Insights;
