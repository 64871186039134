import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js';

import './_paymentCardDetails.scss';

export default function PaymentCardDetail({ formik }) {
  const { t } = useTranslation();
  const elements = useElements();
  const cardNumberElement = elements?.getElement(CardNumberElement);
  const expElement = elements?.getElement(CardExpiryElement);
  const cvvElement = elements?.getElement(CardCvcElement);

  useEffect(() => {
    if (cardNumberElement) {
      cardNumberElement.on('change', () => {
        formik.validateForm();
      });
      cardNumberElement.on('blur', () => {
        formik.validateForm();
      });
    }
  }, [cardNumberElement]);

  useEffect(() => {
    if (cvvElement) {
      cvvElement.on('change', () => {
        formik.validateForm();
      });
      cvvElement.on('blur', () => {
        formik.validateForm();
      });
    }
  }, [cvvElement]);

  useEffect(() => {
    if (expElement) {
      expElement.on('change', () => {
        formik.validateForm();
      });
      expElement.on('blur', () => {
        formik.validateForm();
      });
    }
  }, [expElement]);

  return (
    <div className="cxmeter-form">
      <label
        className={`control ${
          formik.touched.cardholderName && formik.errors.cardholderName
            ? 'control-invalid'
            : ''
        }`}
      >
        <input
          name="cardholderName"
          type="text"
          placeholder={`${t('cardHolderName')} *`}
          value={formik.values.cardholderName}
          onChange={formik.handleChange}
        />
        {formik.touched.cardholderName && (
          <span className="control-error">{formik.errors.cardholderName}</span>
        )}
      </label>
      <label
        className={`control ${
          formik.touched.cardNumber && formik.errors.cardNumber
            ? 'control-invalid'
            : ''
        }`}
      >
        <CardNumberElement
          className="field"
          options={{
            placeholder: formik.values.cardNumber
              ? `${formik.values.cardNumber} *`
              : `${t('cardNumber')} *`,
            style: {
              base: {
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
              },
              invalid: {
                color: formik.errors.cardNumber ? 'red' : 'black',
              },
            },
          }}
        />
        {formik.touched.cardNumber && (
          <span className="control-error">{formik.errors.cardNumber}</span>
        )}
      </label>
      <div className="control-group">
        <label
          className={`control ${
            formik.touched.exp && formik.errors.exp ? 'control-invalid' : ''
          }`}
        >
          <CardExpiryElement
            className="field"
            options={{
              placeholder: formik.values.exp
                ? `${formik.values.exp} *`
                : 'Expiration Date (MM/YY)',
              style: {
                base: {
                  fontWeight: '500',
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  fontSize: '16px',
                },
                invalid: {
                  color: 'red',
                },
              },
            }}
          />
          {formik.touched.exp && (
            <span className="control-error">{formik.errors.exp}</span>
          )}
        </label>
        <label
          className={`control ${
            formik.touched.cvv && formik.errors.cvv ? 'control-invalid' : ''
          }`}
        >
          <CardCvcElement
            className="field"
            options={{
              placeholder: 'CVV (123) *',
              style: {
                base: {
                  fontWeight: '500',
                  fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  fontSize: '16px',
                },
                invalid: {
                  color: 'red',
                },
              },
            }}
          />
          {formik.touched.cvv && (
            <span className="control-error">{formik.errors.cvv}</span>
          )}
        </label>
      </div>
    </div>
  );
}
