import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Loader } from 'components';
import { URLS } from 'utils/constants';
import usePopStateListener from 'utils/hooks/usePopStateListener';

const AUTH_REDIRECT_ROUTES = [URLS.SIGNUP_URL, URLS.LOGIN_URL];

export default function AuthRedirector({ children, to }) {
  const { token, isAppLoading } = useSelector((state) => state.authentication);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const historyChanged = usePopStateListener();

  useEffect(() => {
    if (token && AUTH_REDIRECT_ROUTES.includes(pathname)) {
      navigate(to);
    }
  }, [pathname, historyChanged]);

  return token && isAppLoading ? <Loader /> : children;
}
