import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ChangePaymentPlanModal from 'components/changePaymentPlan/ChangePaymentPlanModal';
import WarningModal from 'components/ui/modal/WarningModal';
import TranslationLink from 'i18n/TranslationLink';
import {
  CXMETER_PACKAGES_LINK,
  FINANCIAL_ADMIN,
  PARENT_ENTITY_ADMIN,
} from 'utils/constants';

function UnavailableFeatureModal({ featureName }) {
  const { t } = useTranslation();
  const { parentEntity, user } = useSelector((state) => state.authentication);
  const { allowed_analytics: { business_insights_plan: planName } = {} } =
    parentEntity ?? {};

  const canUpgrade =
    user?.type === PARENT_ENTITY_ADMIN || user?.type === FINANCIAL_ADMIN;

  const [isOpenChangePlanModal, setIsOpenChangePlanModal] = useState(false);

  const upgradePlan = () => {
    setIsOpenChangePlanModal(true);
  };

  const subTitle = (
    <div>
      <p>
        <Trans
          i18nKey={`lockedFeatureModal.${
            canUpgrade ? 'upgradeMessage' : 'requestUpgradeMessage'
          }`}
          components={{
            planName: <b>{planName}</b>,
            plansLink: <TranslationLink to={CXMETER_PACKAGES_LINK} />,
          }}
        />
      </p>
      {canUpgrade ? (
        <p>
          <Trans i18nKey="lockedFeatureModal.upgradePrompt" />
        </p>
      ) : null}
    </div>
  );

  return (
    <>
      <WarningModal
        wrapperStyles={{
          height: 'unset',
          width: 'unset',
          backgroundColor: 'white',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          borderRadius: 50,
        }}
        bodyStyles={{ width: 550, background: '#fbeff0' }}
        title={t('lockedFeatureModal.title', { feature: featureName })}
        subTitle={subTitle}
        {...(canUpgrade
          ? { primaryActionTitle: t('upgrade'), primaryAction: upgradePlan }
          : {})}
      />
      {canUpgrade ? (
        <ChangePaymentPlanModal
          onClose={() => setIsOpenChangePlanModal(false)}
          isOpenModal={isOpenChangePlanModal}
        />
      ) : null}
    </>
  );
}

export default UnavailableFeatureModal;
