import { useTranslation } from 'react-i18next';

import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'antd';
import { isEmpty } from 'lodash';

import { getFormattedAddress } from 'utils/helpers';

import PaymentCard from './PaymentCard';

export default function PaymentMethod({
  methodName,
  cards,
  onAddNewCard,
  onEditBillingContact,
  actions,
  actionHandler,
  children,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="payment-method-header">
        <h5>
          {methodName}
          &nbsp;{t('settings.payments.paymentMethods.title')}
        </h5>
        {isEmpty(cards) ? (
          <button
            type="button"
            onClick={onAddNewCard}
            className="btn p-0 text-primary"
          >
            {t('add')}
          </button>
        ) : actions.length === 1 ? (
          <button
            type="button"
            onClick={actionHandler}
            className="btn p-0 text-primary"
          >
            {actions[0].label}
          </button>
        ) : (
          <Dropdown
            menu={{
              items: actions,
              onClick: actionHandler,
            }}
            trigger={['click']}
            placement="topRight"
          >
            <span>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </span>
          </Dropdown>
        )}
      </div>
      {children}
      {isEmpty(cards) ? (
        <div>
          {t('settings.payments.paymentMethods.noBackup', {
            paymentMethod: methodName,
          })}
        </div>
      ) : (
        cards.map((method) => (
          <div className="payment-method-detail" key={method.id}>
            <PaymentCard
              payBy={method.brand}
              last4={method.last_4_digits}
              billingContact={getFormattedAddress(method?.billing_address)}
              onEditBillingContact={() => onEditBillingContact(method)}
            />
          </div>
        ))
      )}
    </>
  );
}
