import dayjs from 'dayjs';

import {
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  FEEDBACK_QUARTERS_FILTER,
  QUESTION_OPTIONS_FILTER,
  REGION_FILTER,
  SURVEY_QUESTION_FILTER,
} from 'components/filters/filterTypes';
import { setQuestionOptions } from 'redux/surveys/surveysSlice';

const YEAR_TO_DATE_RANGE = {
  startDate: dayjs().startOf('year').format('YYYY-MM-DD'), // First day of current year
  endDate: dayjs().format('YYYY-MM-DD'), // Today's date
  label: 'Custom Range',
};

export const COMPARISON_METHODS = [
  {
    id: BRANCH_FILTER,
    name: 'location',
    primaryFilters: {},
    filters: {
      [EXPERIENCE_FILTER]: {},
      [DATE_RANGE_FILTER]: YEAR_TO_DATE_RANGE,
    },
  },
  {
    id: REGION_FILTER,
    name: 'region',
    primaryFilters: {},
    filters: {
      [EXPERIENCE_FILTER]: {},
      [DATE_RANGE_FILTER]: YEAR_TO_DATE_RANGE,
    },
  },
  {
    id: EXPERIENCE_FILTER,
    name: 'experience',
    primaryFilters: {},
    filters: {
      [REGION_FILTER]: {},
      [BRANCH_FILTER]: {},
      [DATE_RANGE_FILTER]: YEAR_TO_DATE_RANGE,
    },
  },
  {
    id: FEEDBACK_QUARTERS_FILTER,
    name: 'filters.dateRange',
    filters: {
      [REGION_FILTER]: {},
      [BRANCH_FILTER]: {},
      [EXPERIENCE_FILTER]: {},
    },
  },
  {
    id: QUESTION_OPTIONS_FILTER,
    name: 'question',
    primaryFilters: {
      [SURVEY_QUESTION_FILTER]: {},
    },
    resetDropdownsAction: setQuestionOptions,
    emptyPrimaryFiltersMessage: 'locationComparison.noResults.emptyQuestion',
    filters: {
      [REGION_FILTER]: {},
      [BRANCH_FILTER]: {},
      [DATE_RANGE_FILTER]: YEAR_TO_DATE_RANGE,
    },
  },
];

export const PERCENTAGE_PREVIEW = 'percentage';
export const VALUES_PREVIEW = 'values';
export const BOTH_PREVIEW = 'both';

export const COMPARISON_PREVIEWS = [
  { id: VALUES_PREVIEW, name: 'values' },
  {
    id: PERCENTAGE_PREVIEW,
    name: '%',
  },
  {
    id: BOTH_PREVIEW,
    name: 'bothValues',
  },
];
