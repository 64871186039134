import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { getDropdownLocations } from 'redux/paginatedDropdowns/paginatedDropdownsActions';

import './_branchExpFilter.scss';

function MultiBranchListFilter({ value, fetchLocations = true, ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: locations, loading } = useSelector(
    (state) => state.paginatedDropdowns.locations,
  );

  useEffect(() => {
    if (fetchLocations) {
      dispatch(getDropdownLocations());
    }
  }, [fetchLocations]);

  return (
    <div>
      <SelectAndCreateOptionDropdown
        selectMode="multiple"
        options={locations.results}
        paginationData={locations}
        nextDataAction={getDropdownLocations}
        isPaginationLoading={loading}
        selectedOption={value}
        placeholder={`${t('filters.select.location')} *`}
        isRemoteSearchable
        labelInValue
        {...rest}
      />
    </div>
  );
}

export default MultiBranchListFilter;
