import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from 'antd';

import EntityLogo from './EntityLogo';

const { Text } = Typography;

function OrganizationCard({ org, setOpen, switchOrg, switchingOrgData }) {
  const { t } = useTranslation();

  const [hoveredOrgCard, setHoveredOrgCard] = useState(null);

  const handleSwitchOrg = async (newOrg) => {
    await switchOrg({ id: newOrg.id });
    setOpen(false);
  };

  return (
    <div
      role="presentation"
      onClick={() => handleSwitchOrg(org)}
      onMouseEnter={() => setHoveredOrgCard(org)}
      onMouseLeave={() => setHoveredOrgCard(null)}
      key={org.id}
      className={`p-3 org-card 
        ${hoveredOrgCard?.id === org.id ? 'hovered' : ''} 
        ${switchingOrgData.loading ? 'disabled-element' : ''}
      `}
    >
      <div className="org-title">
        <EntityLogo entity={org} shape="square" />
        <Text ellipsis={{ tooltip: { title: org.name } }}>
          <span>{org.name}</span>
        </Text>
      </div>
      {hoveredOrgCard?.id === org.id || switchingOrgData.org?.id === org.id ? (
        <button className="cstm-btn text-cstm-btn">
          {switchingOrgData.org?.id === org.id ? t('switching') : t('switch')}
        </button>
      ) : null}
    </div>
  );
}

export default OrganizationCard;
