/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import {
  faChevronLeft,
  faChevronRight,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { Icon } from 'components/ui';
import RelativeTime from 'components/ui/relativeTime/RelativeTime';
import { clearCurrentReview } from 'redux/reviewsListing/reviewsListing.slice';
import { getCurrentReview } from 'redux/reviewsListing/reviewsListingActions';
import { ANONYMOUS, HAPPY } from 'utils/constants';
import { ATTACHMENT, NPS } from 'utils/constants/questionTypes';
import { getIconSrc, getSegmentIconSrc } from 'utils/helpers/iconHelper';

import ReviewComments from './ReviewComments';
import ReviewQuestionSection from './ReviewQuestionSection';
import ReviewResolutionSection from './ReviewResolutionSection';
import ReviewMetaSection from './reviewMetaSection';

import './_feedback.scss';
import 'components/dashboard/configurationPanel/_configurationPanel.scss';

function ReviewPanel({
  isOpen,
  setIsOpen,
  reviewId,
  reviewIndex,
  totalReviews,
  handleView,
  showNav = true,
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useSelector(
    (state) => state.reviews.currentReview,
  );

  const feedbackRef = useRef();
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const handleCloseConfig = () => {
    setIsOpen(false);
    if (searchParams.get('openFeedback')) {
      searchParams.delete('openFeedback');
      setSearchParams(searchParams);
    }
  };

  const handleNextReview = () => {
    if (reviewIndex < totalReviews - 1) handleView(reviewIndex + 1);
  };

  const handlePrevReview = () => {
    if (reviewIndex) handleView(reviewIndex - 1);
  };

  const printInsights = useReactToPrint({
    content: () => feedbackRef.current,
    pageStyle: '@page { size: auto;  margin: 15mm; } }',
    documentTitle: `${data?.entity?.name} - ${data?.experience?.name}`,
  });

  const handlePrint = () => {
    printInsights();
  };

  useEffect(() => {
    if (reviewId) dispatch(getCurrentReview(reviewId));
    return () => dispatch(clearCurrentReview());
  }, [reviewId]);

  const { attachmentQuestions, attachmentCount, nonNPSQuestions } =
    useMemo(() => {
      const attachments = [];
      const nonNps = [];
      let count = 0;
      (data?.questions ?? []).forEach((question) => {
        if (question.question_type === ATTACHMENT) {
          attachments.push(question);
          count += question.answer.images.length;
          count += question.answer.videos.length;
          if (question.answer?.audio) {
            count += 1;
          }
        } else if (question.question_type !== NPS) {
          nonNps.push(question);
        }
      });
      return {
        attachmentQuestions: attachments,
        nonNPSQuestions: nonNps,
        attachmentCount: count,
      };
    }, [data?.questions]);

  return (
    <article className={`feedback-panel ${isOpen ? 'feedback-open' : ''}`}>
      <div className="feedback-backdrop" onClick={() => setIsOpen(false)} />
      <div className="feedback-content-container">
        {isLoading ? (
          <div className="spinnerContainer">
            <Spinner />
          </div>
        ) : (
          <div className="feedback-content" ref={feedbackRef}>
            <header className="feedback-header">
              <div className="feedback-header-controls">
                {isEmpty(data) ? null : (
                  <button
                    className="cstm-btn secondary-cstm-btn print-feedback-detail-btn"
                    style={{ paddingBlock: 6 }}
                    onClick={handlePrint}
                  >
                    <Icon
                      style={{ fontSize: 20, color: '#de0024' }}
                      type="print"
                    />
                    <span>{t('printPdf')}</span>
                  </button>
                )}
                {data?.customer?.email && (
                  <a href={`mailto:${data.customer.email}`}>
                    <button
                      style={{ paddingBlock: 7 }}
                      className="cstm-btn secondary-cstm-btn"
                      type="button"
                    >
                      <FontAwesomeIcon icon={faCommentDots} />
                      <span>{t('inputFields.email')}</span>
                    </button>
                  </a>
                )}
                {showNav && (
                  <>
                    <button
                      className="button button-arrow p-1"
                      type="button"
                      onClick={handlePrevReview}
                      disabled={reviewIndex === 0}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button
                      className="button button-arrow p-1"
                      type="button"
                      onClick={handleNextReview}
                      disabled={reviewIndex === totalReviews - 1}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </>
                )}
                <button
                  className="button p-1"
                  type="button"
                  onClick={handleCloseConfig}
                >
                  <img
                    alt="Close Feedback Button"
                    src="/assets/icons/icon_closeFeedback.svg"
                  />
                </button>
              </div>
              <div className="feedback-header-content">
                {data?.segment ? (
                  <img
                    alt="driver"
                    src={getSegmentIconSrc(data.segment.name)}
                  />
                ) : null}
                <hgroup className="feedback-title">
                  <h2>{data?.customer?.name || t(ANONYMOUS)}</h2>
                  <RelativeTime
                    date={data?.created_at ? dayjs(data?.created_at) : dayjs()}
                    tooltipDateFormat="ddd, MMM DD, YYYY  hh:mm A"
                  />
                </hgroup>
              </div>
            </header>
            <section className="feedback-meta-group">
              <ReviewMetaSection
                icon="/assets/icons/icon-devices.svg"
                heading={t('device')}
                value={data?.device}
              />
              <ReviewMetaSection
                icon="/assets/icons/icon_location2.svg"
                heading={t('location')}
                value={data?.entity?.name}
              />
              <ReviewMetaSection
                icon={
                  data?.experience?.name && getIconSrc(data?.experience?.name)
                }
                heading={t('experience')}
                value={data?.experience?.name}
              />
              {data?.customer?.email && (
                <ReviewMetaSection
                  icon="/assets/icons/icon_emailFeedbackMeta.svg"
                  heading={t('inputFields.email')}
                  value={data?.customer?.email}
                />
              )}
            </section>
            <hr className="feedback-separator" />
            <section className="feedback-tabs">
              <button
                onClick={() => {
                  setActiveTab(0);
                }}
                className={activeTab === 0 ? 'feedback-tabs-active' : ''}
              >
                {t('userFeedback')}
              </button>
              <button
                onClick={() => {
                  setActiveTab(1);
                }}
                className={activeTab === 1 ? 'feedback-tabs-active' : ''}
              >
                {t('attachments')} ({attachmentCount})
              </button>
              <button
                onClick={() => {
                  setActiveTab(3);
                }}
                className={activeTab === 3 ? 'feedback-tabs-active' : ''}
              >
                {t('teamComments')}
              </button>
            </section>

            <section className="feedback-detail-print-container">
              {/* This section code is for only print purpose */}
              <div>
                <div className="print-tab-heading">
                  <h3>{t('userFeedback')}</h3>
                </div>
                {!isEmpty(nonNPSQuestions) ? (
                  <div className="feedback-questions">
                    {nonNPSQuestions.map((question, idx) => (
                      <ReviewQuestionSection key={idx} question={question} />
                    ))}
                  </div>
                ) : (
                  <p>{t('feedbacks.noFeedback')}</p>
                )}
              </div>
              <div>
                <div className="print-tab-heading">
                  <h3>
                    {t('attachments')} ({attachmentCount})
                  </h3>
                </div>
                {attachmentCount ? (
                  <div className="feedback-attachments">
                    {attachmentQuestions.map((question, idx) => (
                      <ReviewQuestionSection key={idx} question={question} />
                    ))}
                  </div>
                ) : (
                  <p>{t('feedbacks.noAttachments')}</p>
                )}
              </div>
              <div>
                <div className="print-tab-heading">
                  <h3>{t('teamComments')}</h3>
                </div>
                {!isEmpty(data?.feedback_comments) ? (
                  <div className="feedback-questions">
                    <ReviewComments
                      comments={data?.feedback_comments}
                      feedbackId={data?.id}
                    />
                  </div>
                ) : (
                  <p>{t('feedbacks.noComments')}</p>
                )}
              </div>
            </section>
            <section className="feedback-detail-container">
              {activeTab === 0 ? (
                <div className="feedback-questions">
                  {nonNPSQuestions.map((question, idx) => (
                    <ReviewQuestionSection key={idx} question={question} />
                  ))}
                </div>
              ) : null}
              {activeTab === 1 ? (
                attachmentCount ? (
                  <div className="feedback-attachments">
                    {attachmentQuestions.map((question, idx) => (
                      <ReviewQuestionSection key={idx} question={question} />
                    ))}
                  </div>
                ) : (
                  <p>{t('feedbacks.noAttachments')}</p>
                )
              ) : null}
              {activeTab === 3 ? (
                <div className="feedback-questions">
                  <ReviewComments
                    comments={data?.feedback_comments}
                    feedbackId={data?.id}
                  />
                </div>
              ) : null}
            </section>
            {data?.segment?.name !== HAPPY && (
              <ReviewResolutionSection review={data} />
            )}
          </div>
        )}
      </div>
    </article>
  );
}

export default ReviewPanel;
