import dayjs from 'dayjs';

import * as URLS from 'utils/constants/urlConstants';

export const ANONYMOUS = 'anonymous';
export const HAPPY = 'happy';
export const UNHAPPY = 'unhappy';
export const NEUTRAL = 'neutral';

export const PARENT_ENTITY_ADMIN = 1;
export const ENTITY_ADMIN = 2;
export const FINANCIAL_ADMIN = 3;
export const REST_API_DOCUMENTATION_LINK =
  'https://hail-moonstone-468.notion.site/CXMeter-API-Documentation-135544d0e6a380ef8eceff5ca7d4b457';
export const CXMETER_PACKAGES_LINK = 'https://www.cxmeter.io/pricing';

export const UNPROCESSED_STATUS = 'UNP';
export const RESOLVED_STATUS = 'RES';

export const UNPROCESSED = 'unprocessed';
export const RESOLVED = 'resolved';
export const ACCESS_BLOCKED = 'blocked';

export const FORBIDDEN_CODE = 403;
export const UNAUTHORIZED_CODE = 401;
export const BAD_REQUEST_CODE = 400;

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGINATED_DROPDOWN_SIZE = 50;

export const MAX_IMAGE_SIZE = 1024 * 1024 * 10; // 10 MB
export const MIN_IMAGE_SIZE_FOR_COMPRESSION = 1024 * 1024; // 1MB
export const MIN_IMAGE_SIZE_AFTER_COMPRESSION = 1024 * 300; // 300KB

export const DEFAULT_MAP_BOX_COORD = [-96.63, 33.22];

export const DELIVERY = 'Delivery';
export const DRIVE_THRU = 'Drive Thru';

export const ON_TIME = 'On Time';
export const ORDER_ACCURACY = 'Order Accuracy';

export const CXMETER = 'CXMeter';
export const THEME_PRIMARY_COLOR = '#de0024';
export const THEME_SECONDARY_COLOR = '#798897';

export const DEVICES = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
};

const { MOBILE, TABLET, DESKTOP } = DEVICES;

export const DEVICE_PREVIEWS = {
  [MOBILE]: { className: 'mobile', device: MOBILE },
  [TABLET]: { className: 'tablet', device: TABLET },
  [DESKTOP]: { className: 'desktop', device: DESKTOP },
};

export const SENTIMENTS = {
  POSITIVE: 'Positive',
  NEUTRAL: 'Neutral',
  NEGATIVE: 'Negative',
};

export const BASIC_DETAIL_STEP = { name: 'organizationDetail' };
export const FEEDBACK_FORM_STEP = { name: 'formSetup', link: '/' };
export const BRANCH_SETUP_STEP = {
  name: 'locationSetup',
  link: URLS.BRANCH_LIST_URL,
};
export const PAYMENT_SETUP_STEP = {
  name: 'paymentSetup',
  link: URLS.PAYMENT_URL,
};
export const SAAS_AGREEMENT_ONBOARDING_STEP = {
  name: 'saaSAgreement',
  link: URLS.ONBOARDING_SAAS_AGREEMENT,
};
export const ONBOARDING_STEPS = [
  BASIC_DETAIL_STEP,
  BRANCH_SETUP_STEP,
  SAAS_AGREEMENT_ONBOARDING_STEP,
  PAYMENT_SETUP_STEP,
];

export const QR_SETUP = { name: 'surveys' };
export const BRANCH = { name: 'location' };
export const EXPERIENCE = { name: 'customerExperience' };
export const QR_SETUP_STEPS = [QR_SETUP, BRANCH, EXPERIENCE];

// rating api ranking
export const TABLE_PAGE_SIZE = 10;
export const STATUS = [
  { id: UNPROCESSED_STATUS, name: UNPROCESSED },
  { id: RESOLVED_STATUS, name: RESOLVED },
];

export const DESCENDING = 'desc';

export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';

export const PRIMARY_PAYMENT_CARD_ACTIONS = [
  {
    label: 'edit',
    key: 'edit',
  },
];
export const BACKUP_PAYMENT_CARD_ACTIONS = [
  ...PRIMARY_PAYMENT_CARD_ACTIONS,
  {
    label: 'remove',
    key: 'remove',
  },
  {
    label: 'makePrimary',
    key: 'make_primary',
  },
];

export const APP_ENV = {
  local: 'LOCAL',
  dev: 'DEV',
  stage: 'STAGE',
  prod: 'PROD',
};

export const ALLOWED_SURVEY_IMAGES_TYPES = [
  'blp',
  'bmp',
  'dib',
  'bufr',
  'cur',
  'pcx',
  'dcx',
  'dds',
  'ps',
  'eps',
  'fit',
  'fits',
  'fli',
  'flc',
  'ftc',
  'ftu',
  'gbr',
  'gif',
  'grib',
  'h5',
  'hdf',
  'png',
  'apng',
  'jp2',
  'j2k',
  'jpc',
  'jpf',
  'jpx',
  'j2c',
  'icns',
  'ico',
  'im',
  'iim',
  'tif',
  'tiff',
  'jfif',
  'jpe',
  'jpg',
  'jpeg',
  'mpg',
  'mpeg',
  'mpo',
  'msp',
  'palm',
  'pcd',
  'pxr',
  'pbm',
  'pgm',
  'ppm',
  'pnm',
  'psd',
  'bw',
  'rgb',
  'rgba',
  'sgi',
  'ras',
  'tga',
  'icb',
  'vda',
  'vst',
  'webp',
  'wmf',
  'emf',
  'xbm',
  'xpm',
];

export const ALLOWED_SURVEY_IMAGES_TYPES_STRING =
  ALLOWED_SURVEY_IMAGES_TYPES.map((type) => `.${type}`).join(',');

export const PAGINATED_RESPONSE = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

// INSIGHTS
export const LATEST_FILTER = 'latest';
export const YEAR_TO_DATE_FILTER = 'year-to-date';
export const YEAR_TO_YEAR_FILTER = 'year-to-year';

export const MONTHLY_INSIGHTS = 'Monthly';
export const QUARTERLY_INSIGHTS = 'Quarterly';

export const INSIGHTS_FILTERS = [
  {
    id: LATEST_FILTER,
    label: 'latest',
    value: null,
  },
  {
    id: YEAR_TO_DATE_FILTER,
    label: 'yearToDate',
    value: dayjs(),
  },
  {
    id: YEAR_TO_YEAR_FILTER,
    label: 'year',
    value: null,
  },
];

export const PAGINATION_AND_SORTING_PARAMS = {
  page: 1,
  size: 10,
  sort_by: 'created_at',
  sort_order: 'ascend',
};

export const TABLE_DATA = {
  data: PAGINATED_RESPONSE,
  loading: false,
};
export const SINGLE_ITEM_DATA = {
  data: null,
  loading: false,
};

export const USER_ROLES_LIST = [
  { name: 'orgAdmin', id: PARENT_ENTITY_ADMIN },
  { name: 'locAdmin', id: ENTITY_ADMIN },
  { name: 'financeAdmin', id: FINANCIAL_ADMIN },
];
