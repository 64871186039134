import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

/**
 * @author
 * @function StatusFilter
 * */
import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import { generateQuarters } from 'utils/helpers';

function FeebackQuartersFilter({ placeholder, ...rest }) {
  const { t } = useTranslation();
  const { parentEntity } = useSelector((state) => state.authentication);

  const quarters = useMemo(
    () =>
      generateQuarters(
        parentEntity.first_feedback_date ?? parentEntity?.created_at,
      ),
    [parentEntity],
  );

  return (
    <SelectWithCustomSuffixIcon
      currentFilterClassName="quarters-list-filter"
      id="quarters-list"
      name="quarters"
      options={quarters}
      isRemoteSearchable={false}
      placeholder={placeholder ?? t('filters.select.quarter')}
      {...rest}
    />
  );
}

export default FeebackQuartersFilter;
