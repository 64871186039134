import { toast } from 'react-toastify';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  customSurveyFormsListAPICall,
  deleteSurveyAPICall,
  duplicateSurveyAPICall,
  getEntitySurveysAPICall,
  getQuestionOptionsAPICall,
  getSurveyAPICall,
  getSurveysQuestionsAPICall,
  surveyCreateAPICall,
  surveyTemplateCreateAPICall,
  surveyTemplatesListAPICall,
  surveyUpdateAPICall,
  templateSurveyFormsListAPICall,
} from 'apis/survey';
import { showErrorToast, surveyHelper } from 'utils/helpers';

import { setSelectedSurvey } from './surveysSlice';

const { convertToRawSurvey } = surveyHelper;

export const getEntitySurveyById = createAsyncThunk(
  'surveys/getEntitySurveyById',
  async (id) => getSurveyAPICall(id).catch(showErrorToast),
);

export const getTemplateSurveyForms = createAsyncThunk(
  'surveys/getTemplateForms',
  async (params, { rejectWithValue }) =>
    templateSurveyFormsListAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const getCustomSurveyForms = createAsyncThunk(
  'surveys/getCustomForms',
  async (params, { rejectWithValue }) =>
    customSurveyFormsListAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const getEntitySurveys = createAsyncThunk(
  'surveys/getEntitySurveys',
  async () => getEntitySurveysAPICall().catch(showErrorToast),
);

export const getSurveysTemplates = createAsyncThunk(
  'surveys/getSurveysTemplates',
  async () => surveyTemplatesListAPICall().catch(showErrorToast),
);

export const createSurveyTemplate = createAsyncThunk(
  'surveys/createSurveyTemplate',
  async (survey) => surveyTemplateCreateAPICall(survey).catch(showErrorToast),
);

export const createEntitySurvey = createAsyncThunk(
  'surveys/createEntitySurvey',
  async (survey) => surveyCreateAPICall(survey).catch(showErrorToast),
);

export const updateEntitySurvey = createAsyncThunk(
  'surveys/updateEntitySurvey',
  async ({ surveyId, survey }) =>
    surveyUpdateAPICall(surveyId, survey).catch(showErrorToast),
);

export const deleteEntitySurvey = createAsyncThunk(
  'surveys/deleteEntitySurvey',
  async (surveyId, { dispatch }) =>
    deleteSurveyAPICall(surveyId)
      .then((res) => {
        toast.success(res);
        dispatch(setSelectedSurvey(null));
      })
      .catch(showErrorToast),
);

export const duplicateSurvey = createAsyncThunk(
  'surveys/duplicateSurvey',
  async (surveyId, { dispatch, rejectWithValue }) =>
    duplicateSurveyAPICall(surveyId, null, true)
      .then((res) => {
        dispatch(setSelectedSurvey(convertToRawSurvey(res)));
        return res;
      })
      .catch((e) => showErrorToast(e, rejectWithValue)),
);

export const getSurveysQuestions = createAsyncThunk(
  'surveys/getSurveysQuestions',
  async () => getSurveysQuestionsAPICall().catch(showErrorToast),
);

export const getQuestionOptions = createAsyncThunk(
  'surveys/getQuestionOptions',
  async (params) => getQuestionOptionsAPICall(params).catch(showErrorToast),
);
