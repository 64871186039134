import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Tooltip } from 'antd';

import CancelPaymentPlanModal from 'components/cancelPaymentPlan/CancelPaymentPlanModal';
import ChangePaymentPlanModal from 'components/changePaymentPlan/ChangePaymentPlanModal';
import RelativeTime from 'components/ui/relativeTime/RelativeTime';
import {
  getSubscribedPlan,
  resubscribePlan,
} from 'redux/payments/paymentsActions';

const currentPlanTranslationKey = 'settings.payments.currentPlan';

export default function CurrentPlan() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isActiveOrg } = useSelector((s) => s.authentication);
  const { data: subscribedPlan } =
    useSelector((s) => s.payments?.subscribedPlan) ?? {};
  const { isLoading: isResubscribing } =
    useSelector((s) => s.payments?.resubscribed) ?? {};
  const [isOpenChangePlanModal, setIsOpenChangePlanModal] = useState(false);
  const [isOpenCancelPlanModal, setIsOpenCancelPlanModal] = useState(false);

  const cancelPlanHandler = () => {
    setIsOpenCancelPlanModal(true);
  };

  const resubscribePlanHandler = () => {
    dispatch(resubscribePlan());
  };

  useEffect(() => {
    if (subscribedPlan && location.state?.openChangePaymentPlanModal) {
      setIsOpenChangePlanModal(true);
    }
    return () => {
      location.state = null;
    };
  }, [subscribedPlan]);

  useEffect(() => {
    dispatch(getSubscribedPlan());
  }, []);

  return (
    <>
      <div className="current-plan-container">
        <div>
          <img
            width={40}
            height={40}
            src="/assets/icons/icon-payment.svg"
            alt="icon"
          />
        </div>
        <div className="current-plan">
          <h4 className="plan-name">{subscribedPlan?.name}</h4>
          <div className="plan-details">
            <div>
              <h6 className="text-uppercase">
                {t(`${currentPlanTranslationKey}.subscriptionPeriod`)}
              </h6>
              <p>{t('monthly')}</p>
            </div>
            <div className="border-separator">
              <h6 className="text-uppercase">{t('status')}</h6>
              <p>{subscribedPlan?.subscription_alert}</p>
            </div>
            <div className="border-separator">
              <h6 className="text-uppercase">
                {t(`${currentPlanTranslationKey}.nextInvoiceDate`)}:
              </h6>
              <p>
                {subscribedPlan?.next_invoice_date ? (
                  <RelativeTime
                    date={subscribedPlan?.next_invoice_date}
                    tooltipDateFormat="MMM, DD YYYY"
                  />
                ) : (
                  <span className="no-data-indicator">-</span>
                )}
              </p>
            </div>
            <div className="border-separator">
              <h6 className="text-uppercase">
                {t(`${currentPlanTranslationKey}.estimatedPayment`)}:
              </h6>
              <p>
                {subscribedPlan?.next_invoice_price ? (
                  `$${subscribedPlan?.next_invoice_price}`
                ) : (
                  <span className="no-invoice-price">$0</span>
                )}{' '}
                {subscribedPlan?.additional_cost
                  ? `$${t(`${currentPlanTranslationKey}.additionalCost`, {
                      cost: subscribedPlan?.additional_cost,
                    })}`
                  : null}
              </p>
            </div>
          </div>
          <div className="plan-actions">
            <button
              className="btn"
              type="button"
              onClick={() => setIsOpenChangePlanModal(true)}
            >
              <span>{t(`${currentPlanTranslationKey}.changePlan`)}</span>
              <Tooltip title={t(`${currentPlanTranslationKey}.seePlanDetails`)}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </button>
            {isActiveOrg && !subscribedPlan?.is_resubscribable && (
              <Popconfirm
                title={t('areYouSure')}
                description={t(
                  `${currentPlanTranslationKey}.cancelPlanWarning`,
                  {
                    action: 'cancel',
                    subscribedPlan: subscribedPlan?.name,
                  },
                )}
                onConfirm={cancelPlanHandler}
                okText={t('yes')}
                cancelText={t('no')}
                cancelButtonProps={{ className: 'button button-secondary' }}
                okButtonProps={{ className: 'button button-primary' }}
              >
                <button className="btn" type="button">
                  <span>{t('settings.payments.cancelPlan.title')}</span>
                  <Tooltip title={t('settings.payments.cancelPlan.subTitle')}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </button>
              </Popconfirm>
            )}
            {subscribedPlan?.is_resubscribable && (
              <Popconfirm
                title={t('areYouSure')}
                description={t(
                  `${currentPlanTranslationKey}.cancelPlanWarning`,
                  {
                    action: 'resubscribe',
                    subscribedPlan: subscribedPlan?.name,
                  },
                )}
                onConfirm={resubscribePlanHandler}
                cancelButtonProps={{ className: 'button button-secondary' }}
                okButtonProps={{
                  loading: isResubscribing,
                  className: 'button button-primary',
                }}
              >
                <button className="btn" type="button">
                  <span>{t('settings.payments.resubscribe.title')}</span>
                  <Tooltip title={t('settings.payments.resubscribe.subTitle')}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                </button>
              </Popconfirm>
            )}
          </div>
        </div>
      </div>
      <ChangePaymentPlanModal
        onClose={() => setIsOpenChangePlanModal(false)}
        isOpenModal={isOpenChangePlanModal}
      />
      <CancelPaymentPlanModal
        onClose={() => setIsOpenCancelPlanModal(false)}
        isOpenModal={isOpenCancelPlanModal}
      />
    </>
  );
}
