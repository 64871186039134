/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  faPlus,
  faSpinner,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { OnboardingSurveyPreview, SurveyThumbnail } from 'components';
import Loader from 'components/loader/Loader';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import {
  deleteEntitySurvey,
  getEntitySurveys,
} from 'redux/surveys/surveysActions';
import { setSelectedSurvey } from 'redux/surveys/surveysSlice';
import { FEEDBACK_FORM_STEP, URLS } from 'utils/constants';

import '../listSurveyTemplates/_listSurveyTemplates.scss';
import '../regOrgaisation/_regOrganisation.scss';

export default function SurveysList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    deleteSurveysLoading,
    selectedSurvey,
    entitySurveys: { data: entitySurveys, isEntitySurveysLoading },
  } = useSelector((s) => s.surveys);

  const fetchSurveys = () => {
    dispatch(getEntitySurveys());
  };

  const handleCreateNewSurvey = () => {
    navigate(URLS.SURVEY_TEMPLATES);
  };

  const handleOpenSurvey = (survey, experience) => {
    dispatch(setSelectedSurvey({ ...survey, experience }));
  };

  const handleContinueClick = () => {
    navigate(URLS.BRANCH_LIST_URL);
  };

  const handleDeleteSurvey = async (id) => {
    dispatch(deleteEntitySurvey(id));
  };

  useEffect(() => {
    fetchSurveys();
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={FEEDBACK_FORM_STEP} />
        <OnboardingLayout.Form
          title={
            _.isEmpty(entitySurveys)
              ? "Let's Build Your Customer Feedback Form"
              : 'Here are all the forms you created'
          }
          subtitle="Create a feedback form for your organization"
        >
          {isEntitySurveysLoading ? <Loader /> : null}

          <div className="mb-20">
            {Object.keys(entitySurveys).map((item, val) => (
              <div key={val}>
                <ul className="templatesList">
                  {entitySurveys[item].surveys.map((survey, idx) => (
                    <li key={idx} className="surveyTemplate">
                      {selectedSurvey?._id.$oid === survey._id.$oid && (
                        <FontAwesomeIcon
                          className="deleteIcon"
                          icon={
                            deleteSurveysLoading[survey._id.$oid]
                              ? faSpinner
                              : faXmarkCircle
                          }
                          onClick={() => handleDeleteSurvey(survey._id.$oid)}
                        />
                      )}
                      <SurveyThumbnail
                        data={survey}
                        onChange={() => handleOpenSurvey(survey, item)}
                        selected={selectedSurvey?._id.$oid === survey._id.$oid}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <div
              className="addItem"
              onClick={handleCreateNewSurvey}
              tabIndex={0}
              role="button"
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          onClickSkip={_.isEmpty(entitySurveys) ? handleContinueClick : null}
          onClickContinue={handleContinueClick}
          sidepaneClassName={selectedSurvey ? 'border-left' : null}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        {selectedSurvey ? (
          <OnboardingSurveyPreview survey={selectedSurvey} isTemplate />
        ) : null}
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}
