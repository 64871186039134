import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';

import {
  CandleStickChart,
  HorizontalBarChart,
  LineChart,
} from 'components/charts';
import { RATING } from 'utils/constants/questionTypes';
import { markupToText } from 'utils/helpers';
import {
  formatDate,
  getDateRangeBetweenLabels,
  getEndDate,
} from 'utils/helpers/reviewsHelpers';

import './_ratingPerformers.scss';

/**
 * @author
 * @function RatingPerformers
 * */
export function RatingPerformers({
  questionAnalytics,
  title,
  redirectToReviewsPage,
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const graphs = { BAR: 0, CANDLESTICK: 1, LINE: 2 };
  const [graphType, setGraphType] = useState(graphs.CANDLESTICK);
  let analytics = questionAnalytics || [];

  const parseCandleStickData = () =>
    analytics?.reduce((acc, i) => {
      acc.push({
        x: i.counts,
        rating_stats: i.rating_stats,
        y: markupToText(i.label),
      });
      return acc;
    }, []);

  const parseLineChartData = () =>
    analytics.reduce((acc, i) => {
      // eslint-disable-next-line array-callback-return
      Object.entries(i.trend).map(([key, value]) => {
        if (!acc[key]) {
          acc[key] = {};
        }
        acc[key][markupToText(i.label)] = value;
      });
      return acc;
    }, {});

  const parseBarChartData = () =>
    analytics.reduce((acc, i) => {
      acc[markupToText(i.label)] = i.rating_stats.mean;
      return acc;
    }, {});

  useEffect(() => {
    analytics = analytics.map((analytic) => {
      return {
        ...analytic,
        label: analytic?.label?.replace(/&nbsp;/g, ' ').trim(),
      };
    });

    switch (graphType) {
      case graphs.CANDLESTICK:
        setData(parseCandleStickData());
        break;
      case graphs.BAR:
        setData(parseBarChartData());
        break;
      case graphs.LINE:
        setData(parseLineChartData());
        break;
      default:
        throw Error(`invalid graph type ${graphType}`);
    }
  }, [graphType, analytics]);

  const getOptionId = (label) => {
    const question = analytics.find((d) => markupToText(d.label) === label);
    return question.option_id;
  };

  const handleOnChartClick = ({ label, value, datasetLabel }) => {
    const searchParams = { type: RATING };
    if (graphType === graphs.LINE) {
      const dateRange = getDateRangeBetweenLabels(Object.keys(data));
      searchParams.start_date = formatDate(label);
      searchParams.end_date = getEndDate(label, dateRange);
      searchParams.option_id = getOptionId(datasetLabel);
    } else {
      searchParams.option_id = getOptionId(label);
      searchParams.rating = value;
    }

    redirectToReviewsPage(searchParams);
  };

  return (
    <div className="ratingGraphContainer chartContainer happinessOverTimeContainer">
      <div className="titleBtnsBlock">
        <Tooltip
          placement="top"
          title={<p dangerouslySetInnerHTML={{ __html: title }} />}
        >
          <h3
            className="title"
            title=""
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Tooltip>
        <ul className="filterBtns">
          <li>
            <span
              onClick={() => setGraphType(graphs.BAR)}
              className={graphType === graphs.BAR ? 'active' : ''}
              tabIndex={0}
              role="button"
            >
              {t('overall')}
            </span>
          </li>
          <li>
            <span
              onClick={() => setGraphType(graphs.CANDLESTICK)}
              tabIndex={0}
              role="button"
              className={graphType === graphs.CANDLESTICK ? 'active' : ''}
            >
              {t('distribution')}
            </span>
          </li>
          <li>
            <span
              onClick={() => setGraphType(graphs.LINE)}
              tabIndex={0}
              role="button"
              className={graphType === graphs.LINE ? 'active' : ''}
            >
              {t('trend')}
            </span>
          </li>
        </ul>
      </div>

      <div className="pieChartHolder">
        {graphType === graphs.CANDLESTICK && data?.map !== undefined && (
          <CandleStickChart
            onClick={handleOnChartClick}
            showModal={isModalOpen}
            onModalClose={() => setIsModalOpen(false)}
            data={data}
            title={title}
            boxColor="#4285f4"
            xLabel={t('performance')}
          />
        )}
        {graphType === graphs.LINE && (
          <LineChart
            onClick={handleOnChartClick}
            data={data}
            autoScale={false}
            yScales={{ min: 0, max: 5 }}
            xLabel={t('date')}
            yLabel={t('ratingScore')}
          />
        )}
        {graphType === graphs.BAR && (
          <HorizontalBarChart
            onClick={handleOnChartClick}
            data={data}
            label={t('responses')}
            showRatingGradient
            autoScale={false}
            xScales={{ min: 1, max: 5 }}
            xLabel={t('ratingScore')}
          />
        )}
      </div>
    </div>
  );
}

export default RatingPerformers;
