import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProfileAvatar } from 'components';
import { Dropdown, Icon } from 'components/ui';
import { logout } from 'utils/helpers';

import Profile from './Profile';

export default function ProfilePopover() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.authentication);
  const { t } = useTranslation();
  const handleLogout = () => {
    navigate(pathname, {
      search: '',
      replace: true,
      state: { refreshState: true },
    });
    logout();
  };

  return (
    <Dropdown
      dropdownClass="cxmeter-nav-profile"
      items={[
        {
          children: <Profile user={user} />,
        },
        {
          icon: <Icon type="logout" />,
          label: t('logout'),
          className: 'enabled-link',
          onClick() {
            handleLogout();
          },
        },
      ]}
    >
      <ProfileAvatar
        size={41}
        photoUrl={user.profile_photo}
        firstName={user.first_name}
        lastName={user.last_name}
        userId={user.id}
      />
    </Dropdown>
  );
}
