import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import WarningModal from 'components/ui/modal/WarningModal';

const statusTranslationKey = 'settings.locations.changeStatusModal';
function NewLocationCostModal({
  isOpenPriceWarningModal,
  onClose,
  onCreateNewLocation,
}) {
  const { t } = useTranslation();
  const plan = useSelector((state) => {
    const {
      payments: {
        subscribedPlan: { data },
      },
    } = state;
    return state.payments.paymentPlans.plans?.find(
      (p) => p.title === data?.name,
    );
  });

  const cost = plan?.cost_per_branch ?? 0;

  const subTitle = (
    <>
      <p>{t(`${statusTranslationKey}.newLocationSubtitle`)}</p>
      <p className="m-0">
        {cost === 0
          ? t(`${statusTranslationKey}.noExtraCharges`)
          : t(`${statusTranslationKey}.paying`, {
              amount: cost,
            })}
      </p>
    </>
  );

  const createNewLocation = () => {
    onClose();
    onCreateNewLocation();
  };

  return isOpenPriceWarningModal ? (
    <WarningModal
      title={t(`${statusTranslationKey}.title`)}
      subTitle={subTitle}
      primaryAction={createNewLocation}
      secondaryActionTitle={t('cancel')}
      secondaryAction={onClose}
    />
  ) : null;
}

export default NewLocationCostModal;
