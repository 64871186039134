import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Image, Modal } from 'antd';

import { AudioPlayer } from 'components';

export default function ReviewAttachment({ question }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSrc, setModalSrc] = useState(null);

  const showModal = (image) => {
    setModalSrc(image);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="feedback-attachments">
      {question?.answer?.audio?.length ? (
        <div className="feedback-attachments-section feedback-audio">
          <p>{t('feedbacks.voiceNote')}</p>
          <AudioPlayer src={question.answer.audio} />
        </div>
      ) : null}
      {question?.answer?.images?.length ? (
        <div className="feedback-attachments-section feedback-images">
          <p>{t('pictures')}</p>
          <div className="feedback-picture-attachments">
            {question.answer.images.map((image) => (
              <div className="feedback-image-container" key={image}>
                <Image
                  src={image}
                  wrapperClassName="feedback-image"
                  preview={false}
                />
                <button className="button" onClick={() => showModal(image)}>
                  {t('view')}
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {question?.answer?.videos?.length ? (
        <div className="feedback-attachments-section feedback-audio">
          <p>{t('videos')}</p>
          {question.answer.videos.map((video, idx) => (
            // eslint-disable-next-line
            <video width="320" controls key={idx}>
              <source src={video} />
            </video>
          ))}
        </div>
      ) : null}

      <Modal
        title={t('picture')}
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        width={700}
        footer={null}
      >
        <img
          src={modalSrc}
          alt="modal content"
          className="feedback-modal-image"
        />
      </Modal>
    </div>
  );
}
