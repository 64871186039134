/* eslint-disable react/no-unstable-nested-components */
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Empty, Menu, Select, Tooltip } from 'antd';
import { isEmpty } from 'lodash';

function OptionTooltip({ text }) {
  return (
    <Tooltip
      arrow={false}
      overlayClassName="custom-tooltip"
      title={text}
      mouseEnterDelay={0.5}
      getPopupContainer={() => document.body}
    >
      <span className="ellipsis">{text}</span>
    </Tooltip>
  );
}

const MultiLevelSelect = forwardRef(
  (
    {
      selectedOption,
      parentKey = 'id',
      childeKey = 'id',
      parentLabelKey = 'name',
      childLabelKey = 'name',
      childrenKey = 'children',
      options,
      onChange,
      placeholder,
      suffixIcon,
      dropdownError,
      wrapperStyles = {},
      removeFocusStyle = true,
      wrapperClassName = '',
      triggerSubMenuAction = 'hover',
      popupMatchSelectWidth = true,
      menuStyles = {},
    },
    ref,
  ) => {
    const menuRef = useRef();
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState(false);

    const formattedOptions = useMemo(() => {
      return options.map((parent) => ({
        key: parent[parentKey],
        label: parent[parentLabelKey],
        children: (parent[childrenKey] ?? []).map((child) => ({
          key: child[childeKey],
          label: child[childLabelKey],
          data: child,
        })),
        data: parent,
      }));
    }, [
      options,
      parentKey,
      childeKey,
      parentLabelKey,
      childLabelKey,
      childrenKey,
    ]);

    const handleSearch = (value) => {
      setSearchValue(value.toLowerCase());
    };

    const onDropdownVisibleChange = (isOpen) => {
      setOpen(isOpen);
    };

    const { filteredMenuItems } = useMemo(() => {
      const getMenuOption = (option) => ({
        key: option.key,
        label: <OptionTooltip text={option.label} />,
        children: option.children.map((child) => ({
          className: 'multi-level-select-dropdown-sub-menu',
          key: child.key,
          label: <OptionTooltip text={child.label} />,
          onClick: () => onChange({ parent: option, child }),
        })),
      });
      if (!searchValue) {
        return {
          filteredMenuItems: formattedOptions.map(getMenuOption),
        };
      }

      const menuItems = [];

      formattedOptions.forEach((parent) => {
        const matchedChildren = parent.children.filter((child) =>
          child.label.toLowerCase().includes(searchValue),
        );

        if (
          parent.label.toLowerCase().includes(searchValue) ||
          matchedChildren.length
        ) {
          const menuOption = getMenuOption(parent);
          menuItems.push(menuOption);
        }
      });

      return { filteredMenuItems: menuItems };
    }, [searchValue, formattedOptions]);

    const formattedSelectedOption = useMemo(() => {
      if (isEmpty(selectedOption)) return null;
      const { parent, child } = selectedOption;
      return {
        value: parent.key,
        label: `${parent.label}${child ? ` - ${child.label}` : ''}`,
      };
    }, [selectedOption]);

    useImperativeHandle(ref, () => ({
      open,
      setOpen,
    }));

    return (
      <div
        style={wrapperStyles}
        className={`multi-level-select-dropdown ${
          dropdownError ? 'invalid-dropdown' : ''
        } ${removeFocusStyle ? 'remove-default-focus-styles' : ''}
            ${wrapperClassName}`}
      >
        <Select
          className="antd-custom-select-dropdown"
          placeholder={placeholder}
          value={formattedSelectedOption}
          onSearch={handleSearch}
          labelInValue
          showSearch
          open={open}
          onDropdownVisibleChange={onDropdownVisibleChange}
          filterOption={false}
          popupMatchSelectWidth={popupMatchSelectWidth}
          options={formattedSelectedOption ? [formattedSelectedOption] : []}
          dropdownRender={() =>
            filteredMenuItems.length ? (
              <Menu
                className="multi-level-select-dropdown-menu"
                style={menuStyles}
                triggerSubMenuAction={triggerSubMenuAction}
                mode="vertical"
                items={filteredMenuItems}
                ref={menuRef}
              />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          {...(suffixIcon !== undefined ? { suffixIcon } : {})}
        />
      </div>
    );
  },
);

export default MultiLevelSelect;
