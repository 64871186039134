import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const INITIAL_STATE = {
  searchText: '',
  selectedSegment: 0,
  selectedDrivers: [0],
  selectedExperience: 0,
  selectedStartDate: null,
  selectedEndDate: null,
  selectedState: null,
  selectedCity: null,
  selectedBranch: null,
  selectedTimeline: null,
  selectedStatus: null,
  selectedReview: null,
  selectedDate: dayjs().format('YYYY-MM-DD'),
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState: INITIAL_STATE,
  reducers: {
    setFilterSearchText: (state, action) => {
      if (state.searchText !== action.payload) {
        state.searchText = action.payload;
      }
    },
    setFilterDateRange: (state, action) => {
      state.selectedStartDate = action.payload?.startDate;
      state.selectedEndDate = action.payload?.endDate;
    },
    setFilterBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.selectedStatus = action.payload;
      if (state.selectedStatus !== action.payload) {
        state.selectedStatus = action.payload;
      }
    },
    setFilterReview: (state, action) => {
      state.selectedReview = action.payload;
      if (state.selectedReview !== action.payload) {
        state.selectedReview = action.payload;
      }
    },
    setFilterLocation: (state, action) => {
      state.selectedBranch = action.payload?.branch;
      state.selectedCity = action.payload?.city;
      state.selectedState = action.payload?.state;
    },
    setFilterTimeline: (state, action) => {
      state.selectedTimeline = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    resetFilters: () => INITIAL_STATE,
  },
});

const { actions, reducer } = filterSlice;
export const {
  setFilterSearchText,
  setFilterDateRange,
  setFilterLocation,
  setFilterTimeline,
  setSelectedDate,
  setFilterStatus,
  setFilterReview,
  resetFilters,
  setFilterBranch,
} = actions;

export default reducer;
