import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';

import { chartColors } from 'utils/constants';
import {
  chartHelper,
  formatNumberToK,
  keepOnlyKeys,
  toTitleCase,
} from 'utils/helpers';

import lineChartOptions from './options';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend,
);

function LineChart({
  data,
  autoScale,
  onClick,
  yScales = { min: -100, max: 100 },
  xLabel = '',
  yLabel = '',
  nps = null,
  datalabels = {
    display: false,
    align: 'start',
    anchor: 'start',
    color: 'black',
  },
}) {
  const { t } = useTranslation();

  const parseChartData = () => {
    if (_.isEmpty(data)) {
      return {
        labels: [],
        datasets: [],
      };
    }
    const dataVals = Object.values(data);
    const { getColor } = chartHelper;
    const additionalDataKeys = ['happy', 'neutral', 'unhappy', 'total'];
    const setup = {
      labels: Object.keys(data),
      datasets: Object.keys(dataVals[0])
        .filter((key) => !additionalDataKeys.includes(key))
        .map((key, index) => ({
          label: toTitleCase(key.replace('_', ' ')),
          datasetLabel: key.replace('_', ' '),
          data: dataVals.map((d) => {
            if (typeof d[key] === 'object') {
              return d[key].happiness_index !== 0
                ? d[key].happiness_index
                : undefined;
            }
            return d[key] !== 0 ? d[key] : undefined;
          }),
          additionalData: keepOnlyKeys(data, additionalDataKeys),
          totalCount: dataVals.map((d) =>
            formatNumberToK(d?.total ?? d[key].total),
          ),
          borderColor: getColor(index, chartColors),
          borderWidth: 2,
          backgroundColor: 'white',
          pointRadius: 6,
          pointHoverRadius: 6,
          datalabels,
        })),
    };
    if (nps) {
      setup.datasets.push({
        label: t('dashboard.nps'),
        datasetLabel: t('dashboard.nps'),
        data: dataVals.map(() => nps),
        borderColor: getColor(1, chartColors),
        borderWidth: 2,
        backgroundColor: 'white',
        pointRadius: 1,
        datalabels: {
          display: false,
          align: 'start',
          anchor: 'start',
          color: 'black',
        },
      });
    }
    return setup;
  };
  const chartOptions = lineChartOptions(onClick, yScales, xLabel, yLabel);
  return _.isEmpty(data) ? (
    <span>{t('noDataFound')}</span>
  ) : (
    <Line
      style={{ minHeight: 300 }}
      options={_.omit(chartOptions, autoScale ? ['scales'] : [])}
      data={parseChartData()}
    />
  );
}

export default LineChart;
