// CustomCheckbox.js
import './_Checkbox.scss';

export default function Checkbox({
  id,
  label,
  checked,
  checkedStyles = {},
  uncheckedStyles = {},
  ...props
}) {
  return (
    <label htmlFor={id} className="custom-checkbox-container">
      <input
        type="checkbox"
        id={id}
        className="custom-checkbox"
        checked={checked}
        style={checked ? checkedStyles : uncheckedStyles}
        {...props}
      />
      <span className="custom-checkbox-label">{label}</span>
    </label>
  );
}
