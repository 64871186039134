import { Outlet } from 'react-router-dom';

import { Loader } from 'components';
import DesktopSignupWarning from 'components/ui/modal/SignupDesktopModal';
import { useOnboardingRedirect } from 'utils/hooks';

export default function OnboardingNavigator() {
  const { isFetchingInitialData } = useOnboardingRedirect();

  return (
    <>
      <DesktopSignupWarning hideWarning={isFetchingInitialData} />
      {isFetchingInitialData ? (
        <Loader from="OnboardingNavigator" />
      ) : (
        <Outlet />
      )}
    </>
  );
}
