import UnavailableFeatureModal from './UnavailableFeatureModal';

function UnavailableFeatureWrapper({
  wrapperRef,
  isFeatureAllowed = false,
  wrapperStyles = {},
  className = '',
  children,
  featureName = '',
}) {
  return (
    <>
      {isFeatureAllowed ? null : (
        <UnavailableFeatureModal featureName={featureName} />
      )}
      <div
        ref={wrapperRef}
        className={`${className} ${isFeatureAllowed ? '' : 'disabled-blured'}`}
        style={wrapperStyles}
      >
        {children}
      </div>
    </>
  );
}

export default UnavailableFeatureWrapper;
