import { t } from 'i18next';
import { copyContentStateWithPrefix } from 'utils/helpers/richTextEditorHelpers';
import {
  formatSurveyQuestion,
  formatSurveySection,
} from 'utils/helpers/surveyHelpers';

import { NPS, RATING } from './questionTypes';

const npsSectionId = 'section-id-nps';
export const NPS_QUESTION_ID = 'nps-question';
const sectionId = 'section-id-initial';
export const INITIAL_QUESTION_ID = 'initial-question';

export const getInitialSurvey = () => ({
  title: '',
  sections: [
    formatSurveySection(npsSectionId, [
      formatSurveyQuestion({
        id: NPS_QUESTION_ID,
        label: copyContentStateWithPrefix(
          t('formBuilder.defaults.npsQuestionLabel'),
        ),
        question_type: NPS,
      }),
    ]),
    formatSurveySection(sectionId, [
      formatSurveyQuestion({
        id: INITIAL_QUESTION_ID,
        label: copyContentStateWithPrefix(''),
        question_type: RATING,
      }),
    ]),
  ],
  is_template: false,
  is_collecting_nps_response: true,
  is_full_form_enabled: false,
  respondent_question: {
    label: copyContentStateWithPrefix(
      t('formBuilder.defaults.customerDetailFormTitle'),
    ),
    name_placeholder: t('name'),
    email_placeholder: t('email'),
    is_active: true,
  },
  welcome_text: copyContentStateWithPrefix(
    t('formBuilder.defaults.welcomeText'),
  ),
});
