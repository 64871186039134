import { forwardRef, useImperativeHandle, useRef } from 'react';

import { faCaretDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isNil, isObject, isString } from 'lodash';

import useClickOutside from 'utils/hooks/useClickOutside';

import MultiLevelSelect from './MultiLevelSelect';
import SelectAndCreateOptionDropdown from './SelectAndCreateOptionDropdown';

function CustomIcon({
  isRemoveable,
  onRemove,
  onChange,
  onClear,
  isClearable,
  value,
  onIconClick,
}) {
  const shouldClearValue =
    isClearable &&
    !(
      isNil(value) ||
      (isString(value) && isEmpty(value)) ||
      (isObject(value) && isEmpty(value))
    );
  const showCrossIcon = shouldClearValue || isRemoveable;

  const onClearOrRemove = (e) => {
    e.stopPropagation();
    if (showCrossIcon) {
      if (shouldClearValue) {
        onChange(null);
        if (onClear) onClear();
      } else if (showCrossIcon) {
        onRemove?.();
      }
    } else {
      onIconClick();
    }
  };

  return (
    <div
      role="presentation"
      className="select-dropdown-custom-icon"
      onClick={onClearOrRemove}
    >
      <FontAwesomeIcon icon={showCrossIcon ? faXmark : faCaretDown} />
    </div>
  );
}

const SelectWithCustomSuffixIcon = forwardRef(
  (
    {
      id,
      value: selectedOption,
      isClearable = true,
      isRemoveable,
      placeholder,
      onChange,
      options,
      onRemove,
      isRoundedDropDown = false,
      currentFilterClassName = '',
      filtersCommonClassName = '',
      disabledItems,
      onClear,
      showSearch = true,
      isRemoteSearchable = true,
      isMultiLevelSelect = false,
      ...rest
    },
    ref,
  ) => {
    const selectRef = useRef(null);
    const isOpenRef = useRef(false);

    const clickOutsideRef = useClickOutside({
      onClickOutside: () => {
        isOpenRef.current = false;
      },
    });

    const handleIconClick = () => {
      const { open, setOpen } = selectRef.current;
      if (!isOpenRef.current && !open) {
        setOpen(true);
        isOpenRef.current = true;
      } else {
        isOpenRef.current = false;
      }
    };

    useImperativeHandle(ref, () => ({
      selectRef: selectRef.current,
    }));

    return (
      <div
        ref={clickOutsideRef}
        id={id}
        role="button"
        className={`select-with-custom-suffix-icon ${currentFilterClassName} ${filtersCommonClassName} ${
          isRoundedDropDown ? 'rounded-dropdown' : ''
        }`}
      >
        <CustomIcon
          onChange={onChange}
          value={
            isMultiLevelSelect
              ? selectedOption?.parent?.key
              : selectedOption?.value
          }
          isClearable={isClearable}
          onClear={onClear}
          isRemoveable={isRemoveable}
          onRemove={onRemove}
          onIconClick={handleIconClick}
        />
        {isMultiLevelSelect ? (
          <MultiLevelSelect
            ref={selectRef}
            options={options ?? []}
            showSearch={showSearch}
            onChange={onChange}
            isRemoteSearchable={isRemoteSearchable}
            useParentAsPopupContainer={false}
            selectedOption={selectedOption}
            suffixIcon={null}
            placeholder={placeholder}
            {...rest}
          />
        ) : (
          <SelectAndCreateOptionDropdown
            ref={selectRef}
            options={options ?? []}
            onChange={onChange}
            selectedOption={selectedOption}
            placeholder={placeholder}
            labelInValue
            showSearch={showSearch}
            isRemoteSearchable={isRemoteSearchable}
            suffixIcon={null}
            disabledItems={disabledItems}
            useParentAsPopupContainer={false}
            {...rest}
          />
        )}
      </div>
    );
  },
);

export default SelectWithCustomSuffixIcon;
