import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Alert } from 'antd';
import { isEmpty } from 'lodash';

import AppQRCodesModal from 'components/appQRCodes/AppQRCodesModal';
import { PARENT_ENTITY_ADMIN, URLS } from 'utils/constants';

import './_DashboardAlerts.scss';

export default function DashboardAlerts() {
  const { parentEntity, user } = useSelector((state) => state.authentication);
  const [isOpenQRModal, setIsOpenQRModal] = useState(false);

  const showDashboardAlerts =
    user.type === PARENT_ENTITY_ADMIN &&
    !isEmpty(parentEntity) &&
    !parentEntity?.has_linked_qr_codes;

  return showDashboardAlerts ? (
    <div className="alerts-container">
      <Alert
        style={{ fontSize: 18, textAlign: 'center' }}
        message={
          <Trans
            i18nKey="dashboard.qrMessage"
            components={{
              surveyLink: <Link to={URLS.SURVEYS_URL} />,
            }}
          />
        }
        type="warning"
      />
      <AppQRCodesModal
        isOpenModal={isOpenQRModal}
        onClose={() => setIsOpenQRModal(false)}
      />
    </div>
  ) : null;
}
