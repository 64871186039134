import { useTranslation } from 'react-i18next';

import { Tooltip } from 'antd';

function FeedbackFormSelector({
  selectedFeedbackForm,
  openFeedbackTemplates,
  canAttachFeedbackForm,
}) {
  const { t } = useTranslation();
  return (
    <Tooltip
      getPopupContainer={(target) => target.parentElement}
      title={
        canAttachFeedbackForm
          ? ''
          : t('settings.manageEmails.linkFeedbackFormWarning')
      }
    >
      <button
        disabled={!canAttachFeedbackForm}
        className={`cstm-btn tertiary-cstm-btn feedback-template-selector text-ellipsis ${
          selectedFeedbackForm ? 'selected' : ''
        }`}
        onClick={selectedFeedbackForm ? null : openFeedbackTemplates}
      >
        {selectedFeedbackForm ? null : (
          <img src="/assets/icons/icon_feedbackForm.svg" alt="Feedback" />
        )}
        <span className="text-ellipsis">
          {selectedFeedbackForm ?? t('attachFeedbackForm')}
        </span>
        {selectedFeedbackForm ? (
          <span
            role="presentation"
            onClick={openFeedbackTemplates}
            className="replace-feedback-form"
          >
            {t('replace')}
          </span>
        ) : null}
      </button>
    </Tooltip>
  );
}

export default FeedbackFormSelector;
