/* eslint-disable consistent-return */
import { configureStore } from '@reduxjs/toolkit';

import { ACCESS_BLOCKED, FORBIDDEN_CODE } from 'utils/constants';

import rootReducer from './rootReducer';

const abortActionMiddleware = () => (next) => (action) => {
  const { payload } = action ?? {};
  const { status, data } = payload?.response ?? {};
  if (
    payload?.invalidRefreshToken ||
    (data?.access === ACCESS_BLOCKED && status === FORBIDDEN_CODE)
  ) {
    return;
  }
  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(abortActionMiddleware),
});

export default store;
