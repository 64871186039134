import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CityDropDown from 'components/demographics/cityDropDown/CityDropDown';
import CountryDropDown from 'components/demographics/countryDropDown/CountryDropDown';
import StateDropDown from 'components/demographics/stateDropDown/StateDropDown';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import {
  onCountryChangeAction,
  onStateChangeAction,
} from 'redux/demographics/demographicsSlice';

export default function PaymentBillingDetails({ formik }) {
  const { values, setFieldValue, setValues, errors, touched, handleChange } =
    formik;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onStateChange = (id) => {
    dispatch(onStateChangeAction());
    setValues({ ...values, city: '', state: id });
  };

  const onCountryChange = (id) => {
    dispatch(onCountryChangeAction());
    dispatch(onStateChangeAction());
    setValues({ ...values, city: '', state: '', country: id });
  };

  return (
    <div className="cxmeter-form">
      <FormikLabeledInput
        formik={formik}
        placeholder={t('streetAddress')}
        inputName="street_address"
        label={t('streetAddress')}
      />
      <label
        className={`control ${
          touched.country && errors.country ? 'control-invalid' : ''
        }`}
      >
        <span className="required-field">
          {t('inputFields.country')}
          <span>*</span>
        </span>
        <CountryDropDown
          value={values.country}
          onChange={onCountryChange}
          error={
            touched.country && errors.country ? (
              <span className="txt-error">{errors.country}</span>
            ) : null
          }
        />
      </label>
      <div className="onboarding-location-dropdowns">
        <label
          className={`control ${
            touched.state && errors.state ? 'control-invalid' : ''
          }`}
        >
          <span className="required-field">
            {t('inputFields.state')}
            <span>*</span>
          </span>
          <StateDropDown
            listHeight={200}
            placement="topLeft"
            onChange={onStateChange}
            countryId={values.country}
            value={values.state}
            error={
              touched.state && errors.state ? (
                <span className="txt-error">{errors.state}</span>
              ) : null
            }
          />
        </label>
        <label
          className={`control ${
            touched.city && errors.city ? 'control-invalid' : ''
          }`}
        >
          <span className="required-field">
            {t('inputFields.city')}
            <span>*</span>
          </span>
          <CityDropDown
            listHeight={200}
            placement="topLeft"
            onChange={(id) => setFieldValue('city', id)}
            value={values.city}
            stateId={values.state}
            error={
              touched.city && errors.city ? (
                <span className="txt-error">{errors.city}</span>
              ) : null
            }
          />
        </label>
        <label
          className={`control ${
            touched.zip_code && errors.zip_code ? 'control-invalid' : ''
          }`}
        >
          <span className="required-field text-capitalize">
            {t('inputFields.zipCode')}
            <span>*</span>
          </span>
          <input
            name="zip_code"
            type="text"
            placeholder={t('inputFields.zipCode')}
            value={values.zip_code}
            onChange={handleChange}
          />
          {touched.zip_code && errors.zip_code ? (
            <span className="txt-error">{errors.zip_code}</span>
          ) : null}
        </label>
      </div>
    </div>
  );
}
