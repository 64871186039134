import './_loader.scss';

function Loader({ styles = {} }) {
  return (
    <div className="loading" style={styles}>
      Loading&#8230;
    </div>
  );
}

export default Loader;
