/* eslint-disable no-underscore-dangle */
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import i18next from 'i18next';

export const setCardErrors = (elements, isEdit = false) => {
  const { t } = i18next;
  const errors = {};
  const cardNumberElement = elements?.getElement(CardNumberElement);
  const expElement = elements?.getElement(CardExpiryElement);
  const cvvElement = elements?.getElement(CardCvcElement);

  const errroMessage = t('settings.payments.cardFieldError');

  if (
    cardNumberElement &&
    (cardNumberElement._empty || cardNumberElement._invalid) &&
    !isEdit
  ) {
    errors.cardNumber = errroMessage;
  } else {
    errors.cardNumber = null;
  }

  if (expElement && (expElement._empty || expElement._invalid)) {
    errors.exp = errroMessage;
  } else {
    errors.exp = null;
  }

  if (cvvElement && (cvvElement._invalid || cvvElement._empty) && !isEdit) {
    errors.cvv = errroMessage;
  } else {
    errors.cvv = null;
  }
  return errors;
};

export const buildAddress = (countries, states, cities, address) => {
  return {
    country: countries.find((o) => o.id === address.countryId)?.short_code,
    line1: `${address.street_address}`,
    city: cities.find((o) => o.id === address.cityId)?.name,
    state: states.find((o) => o.id === address.stateId)?.name,
    postal_code: `${address.zip_code}`,
  };
};
