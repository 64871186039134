/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { URLS } from 'utils/constants';
import {
  getPathNameFromFullURL,
  isLocationChildRoute,
  isPaymentChildRoute,
} from 'utils/helpers';

import usePopStateListener from './usePopStateListener';

export default function useOnboardingRedirect() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    parentEntity,
    token,
    user,
    isAppLoading,
    parentEntities: { data: parentEntities, loading },
  } = useSelector((state) => state.authentication);
  const lastCompletedStep = getPathNameFromFullURL(
    parentEntity?.last_onboarding_url,
  );

  const [isFetchingInitialData, setFetchingInitialData] =
    useState(isAppLoading);

  const historyChanged = usePopStateListener();

  useEffect(() => {
    if (!isAppLoading) {
      if (token && pathname !== URLS.REGISTER_ORG_URL) {
        // Register org route will be available in each case if user has loggedin.
        if (lastCompletedStep === null) {
          if (
            pathname.startsWith(URLS.SIGNUP_URL) &&
            pathname !== URLS.SIGNUP_WELCOME_URL // Welcome page redirection is handled by itself
          ) {
            navigate(URLS.DASHBOARD_URL, { replace: true });
          }
        } else if (!isEmpty(user) && isEmpty(parentEntities) && !loading) {
          navigate(URLS.REGISTER_ORG_URL, { replace: true });
        } else if (
          pathname !== lastCompletedStep &&
          lastCompletedStep &&
          !isLocationChildRoute(lastCompletedStep, pathname) &&
          !isPaymentChildRoute(lastCompletedStep, pathname)
        ) {
          navigate(lastCompletedStep, {
            replace: true,
          });
        }
      }

      setFetchingInitialData(false);
    }
  }, [isAppLoading, historyChanged, parentEntity, parentEntities]);

  return { isFetchingInitialData, isAppLoading, lastCompletedStep };
}
