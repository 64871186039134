/* eslint-disable react/no-danger */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Ratings } from 'components/ratings';
import { Icon } from 'components/ui';
import { HAPPY, NEUTRAL, UNHAPPY } from 'utils/constants';
import {
  ATTACHMENT,
  ATTACHMENTS,
  CHECKBOX,
  RADIO,
  RATING,
  TEXT,
} from 'utils/constants/questionTypes';
import { createMarkup } from 'utils/helpers/surveyHelpers';

export default function SurveyPreviewForm({ survey, innerRef, isTemplate }) {
  const { t } = useTranslation();
  const [selectedMood, setSelectedMood] = useState('');
  const { parentEntity } = useSelector((s) => s.authentication);
  const [npsSection, ...sections] = survey?.sections ?? [];
  const npsQuestion = (npsSection?.questions ?? [])[0];
  const welcomeText = survey?.welcome_text
    ? createMarkup(survey.welcome_text)
    : null;

  return (
    <section className="survey-content-container" ref={innerRef}>
      <div className="logo">
        <img src={parentEntity.logo} alt="Logo" width={75} />
      </div>
      {welcomeText ? (
        <div
          className="text-center mb-4"
          dangerouslySetInnerHTML={welcomeText}
        />
      ) : null}
      {npsQuestion && npsQuestion.is_active ? (
        <div className="nps section-block">
          <div
            dangerouslySetInnerHTML={
              isTemplate
                ? { __html: npsQuestion?.label }
                : createMarkup(npsQuestion?.label)
            }
          />
          <div className="nps-reactions">
            <button onClick={() => setSelectedMood(HAPPY)}>
              <img
                src="/assets/icons/icon_happy.svg"
                className={selectedMood === HAPPY ? 'selected' : ''}
                alt={HAPPY}
              />
            </button>
            <button onClick={() => setSelectedMood(NEUTRAL)}>
              <img
                src="/assets/icons/icon_neutral.svg"
                className={selectedMood === NEUTRAL ? 'selected' : ''}
                alt={NEUTRAL}
              />
            </button>
            <button onClick={() => setSelectedMood(UNHAPPY)}>
              <img
                src="/assets/icons/icon_unhappy.svg"
                className={selectedMood === UNHAPPY ? 'selected' : ''}
                alt={UNHAPPY}
              />
            </button>
          </div>
        </div>
      ) : null}

      {(sections ?? []).map((section) => (
        <div key={section.id} className="section-block">
          {section.questions.map((question) => (
            <div key={question.id} className="question-block">
              <div className="question-label">
                <div
                  dangerouslySetInnerHTML={
                    isTemplate
                      ? { __html: question?.label }
                      : createMarkup(question?.label)
                  }
                />
                {question.is_required && <span className="required">*</span>}
              </div>
              {question.question_type === CHECKBOX ? (
                <div>
                  {t('maxAllowedChoices', {
                    count: question.max_number_of_choices || 0,
                  })}
                </div>
              ) : null}

              {(question.question_type === RADIO ||
                question.question_type === CHECKBOX ||
                question.question_type === RATING) &&
                question.options && (
                  <ul className="question-options">
                    {question.options?.map((op, idx) => (
                      <li
                        key={idx}
                        className={`question-option ${
                          question.question_type === RATING
                            ? 'rating-option'
                            : ''
                        }`}
                      >
                        {question.question_type !== RATING ? (
                          <input
                            className="question-choice-input"
                            type={question.question_type}
                            id={`${question.id}-${idx}`}
                            name={`question-${question.id}`}
                          />
                        ) : null}
                        <div
                          htmlFor={`${question.id}-${idx}`}
                          dangerouslySetInnerHTML={
                            isTemplate
                              ? { __html: op.label }
                              : createMarkup(op?.label)
                          }
                        />
                        {question.question_type === RATING ? <Ratings /> : null}
                      </li>
                    ))}
                  </ul>
                )}
              {question.question_type === TEXT && (
                <textarea type="text" className="text-question-answer" />
              )}
              {question.question_type === ATTACHMENT ? (
                <div className="question-attachments">
                  {question.attachment_types.includes(ATTACHMENTS.IMAGE) && (
                    <label htmlFor="image-attachment" className="attachment">
                      <input type="file" hidden id="image-attachment" />
                      <Icon type="imagesmode" />
                      <span>
                        {t('upload')}
                        <br />
                        {t('imageVideo')}
                      </span>
                    </label>
                  )}
                  {question.attachment_types.includes(ATTACHMENTS.AUDIO) && (
                    <label htmlFor="audio-attachment" className="attachment">
                      <input type="file" hidden id="audio-attachment" />
                      <Icon type="mic" />
                      <span>{t('recordAudio')}</span>
                    </label>
                  )}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ))}
      <div className="logo">
        <img src="/assets/images/appLogo.svg" alt="Logo" width={120} />
      </div>
    </section>
  );
}
