import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { Wrapper as GoogleMapApiWrapper } from '@googlemaps/react-wrapper';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfigProvider } from 'antd';

import { THEME_PRIMARY_COLOR, THEME_SECONDARY_COLOR } from 'utils/constants';

import App from './App';
import { GOOGLE_MAPS_API_KEY, GOOGLE_WEB_CLIENT_ID } from './config';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_WEB_CLIENT_ID}>
    <GoogleMapApiWrapper apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: '#FEAD44EE',
                algorithm: true, // Enable algorithm
                colorBorder: '#FEAD44EE',
                colorBorderBg: '#FEAD44EE',
                colorText: '#FEAD44EE',
              },
              Tabs: {
                colorPrimary: THEME_PRIMARY_COLOR, // Primary color for selected tab
                colorTextActive: THEME_PRIMARY_COLOR, // Text color for selected tab
                colorPrimaryHover: THEME_PRIMARY_COLOR, // Hover color for selected tab
                colorText: THEME_SECONDARY_COLOR, // Text color for non-selected tabs
                colorTextHover: THEME_SECONDARY_COLOR, // Hover text color for non-selected tabs
                colorBorder: THEME_PRIMARY_COLOR, // Border color for selected tab
                colorBgContainer: 'white', // Transparent background
              },
              token: {
                colorPrimary: '#FEAD44EE',
                borderRadius: 2,
                // colorBgContainer: '#fff',
              },
            },
          }}
        >
          <App />
        </ConfigProvider>
      </Provider>
    </GoogleMapApiWrapper>
  </GoogleOAuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
