import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChangePaymentPlanModal from 'components/changePaymentPlan/ChangePaymentPlanModal';

const translationKey = 'settings.locations.googleReviews.unlockGoogleReviews';

function UnlockGoogleReviewsCard() {
  const { t } = useTranslation();

  const [isOpenChangePlanModal, setIsOpenChangePlanModal] = useState(false);

  const handleUnlockGoogleReviews = () => {
    setIsOpenChangePlanModal(true);
  };

  return (
    <>
      <div className="unlock-googl-reviews-container">
        <div className="google-review-description">
          <h4>{t(`${translationKey}.title`)}</h4>
          <p>{t(`${translationKey}.subTitle`)}</p>
          <button
            onClick={handleUnlockGoogleReviews}
            className="cstm-btn primary-cstm-btn"
          >
            {t(`upgrade`)}
          </button>
        </div>
        <div className="unlock-reviews-preview-img">
          <img
            width={175}
            height={125}
            src="/assets/icons/icon_unlockGoogleReviews.svg"
            alt="Google Logo"
            className="google-logo"
          />
        </div>
      </div>
      <ChangePaymentPlanModal
        onClose={() => setIsOpenChangePlanModal(false)}
        isOpenModal={isOpenChangePlanModal}
      />
    </>
  );
}

export default UnlockGoogleReviewsCard;
