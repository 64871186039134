import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { GoogleLogin } from '@react-oauth/google';

import { loginWithGoogleAPICall } from 'apis/authentication';
import { Loader } from 'components/loader';
import { showErrorToast } from 'utils/helpers';
import { useLogin } from 'utils/hooks';

import './_loginWithGoogle.scss';

function LoginWithGoogle() {
  const { t } = useTranslation();
  const { postLogin } = useLogin();
  const [loading, setLoading] = useState(false);

  const handleLoginWithGoogle = ({ credential }) => {
    setLoading(true);
    loginWithGoogleAPICall({
      auth_token: credential,
    })
      .then(async ({ user, token }) => {
        await postLogin(user, token);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorToast(error);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="loginWithGoogleBtn">
      <GoogleLogin
        onSuccess={handleLoginWithGoogle}
        onError={() => {
          toast.success(t('toasts.failedGoogleLogin'));
        }}
        text="continue_with"
        theme="filled_blue"
      />
    </div>
  );
}

export default LoginWithGoogle;
