/* eslint-disable no-use-before-define */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';

import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import { changePassword } from 'redux/authentication/authenticationActions';
import { showErrorToast } from 'utils/helpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { changePasswordSchema } from 'utils/validations';

import './_changePassword.scss';

const initialValues = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};

function ChangePassword() {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const { user: currentUser } = useSelector((state) => state.authentication);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      await handleSubmit(values, resetForm);
    },
    validationSchema: changePasswordSchema(currentUser.is_google_login),
  });

  async function handleSubmit(data, resetForm) {
    try {
      const { isError } = await dispatch(
        changePassword({ id: currentUser.id, data }),
        formik,
      );
      if (!isError) {
        resetForm();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      resetForm();
      showErrorToast(error);
    }
  }

  return (
    <div className="settings-non-table-container">
      <div className="settings-non-table-container-header">
        <h3>
          {t(currentUser.is_google_login ? 'add' : 'change')}{' '}
          {t('inputFields.password')}
        </h3>
        <p>{t('profile.changePassword.subTitle')}</p>
      </div>
      <div className="settings-non-table-container-content">
        <form className="cxmeter-form small" onSubmit={formik.handleSubmit}>
          {!currentUser.is_google_login && (
            <FormikLabeledInput
              formik={formik}
              label={t('inputFields.oldPassword')}
              inputName="old_password"
              placeholder={t('inputFields.oldPassword')}
            />
          )}
          <div className="control-group">
            <FormikLabeledInput
              formik={formik}
              label={t('inputFields.newPassword')}
              inputName="new_password"
              placeholder={t('inputFields.newPassword')}
            />
            <FormikLabeledInput
              formik={formik}
              label={t('inputFields.confirmPassword')}
              inputName="confirm_password"
              placeholder={t('inputFields.confirmPassword')}
            />
          </div>
        </form>
      </div>
      <div className="settings-non-table-container-footer">
        <button
          type="button"
          className="cstm-btn primary-cstm-btn"
          onClick={formik.handleSubmit}
          aria-busy={loading}
          disabled={loading || !formik.dirty}
        >
          {t('profile.changePassword.savePassword')}
        </button>
      </div>
    </div>
  );
}

export default ChangePassword;
