import { toast } from 'react-toastify';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import {
  changePasswordAPICall,
  editUserAPICall,
  getUserAPICall,
  sendForgotPasswordEmailAPICall,
  signupAPICall,
} from 'apis/authentication';
import {
  parentEntityGetAPICall,
  parentEntityListAPICall,
  parentEntityPatchAPICall,
  parentEntitySelectAPICall,
  patchUserAPICall,
} from 'apis/cxmeter';
import { ENTITY_ADMIN } from 'utils/constants';
import {
  getParentEntity,
  getUser,
  showErrorToast,
  storeParentEntity,
  storeUser,
  translateToast,
} from 'utils/helpers';

import { setParentEntity, setUser } from './authenticationSlice';

export const getParentEntityAction = createAsyncThunk(
  'authentication/getParentEntityAction',
  async () => {
    const user = getUser();
    let parentEntity = getParentEntity();
    if (!isEmpty(parentEntity) && user.type !== ENTITY_ADMIN) {
      parentEntity = await parentEntityGetAPICall();
    }
    return parentEntity;
  },
);

export const getParentEntitiesList = createAsyncThunk(
  'authentication/getParentEntitiesList',
  async (params) => {
    return parentEntityListAPICall(params).catch(showErrorToast);
  },
);

export const selectParentEntity = createAsyncThunk(
  'authentication/selectParentEntity',
  async (params, { dispatch }) => {
    return parentEntitySelectAPICall(params)
      .then((pe) => {
        storeParentEntity(pe);
        dispatch(setParentEntity(pe));
        return pe;
      })
      .catch(showErrorToast);
  },
);

export const getUserAndInitialiseData = createAsyncThunk(
  'authentication/getUserAndInitialiseData',
  async () => {
    try {
      let parentEntity;
      const user = await getUserAPICall('me');
      if (user) {
        storeUser(user);
        parentEntity = getParentEntity();
        if (!isEmpty(parentEntity)) {
          parentEntity = await parentEntityGetAPICall();
        }
      }
      return { user, parentEntity };
    } catch (e) {
      showErrorToast(e);
      return Promise.reject();
    }
  },
);

export const editProfile = createAsyncThunk(
  'authentication/editProfile',
  async (params, { dispatch }) => {
    return editUserAPICall(params)
      .then((data) => {
        toast.success(translateToast('updated', 'profile.title'));
        getUserAPICall('me').then((user) => {
          dispatch(setUser(user));
        });
        return data;
      })
      .catch(showErrorToast);
  },
);

export const changePassword = createAsyncThunk(
  'authentication/change_password',
  async (params, { rejectWithValue }) => {
    return changePasswordAPICall(params.id, params.data)
      .then((data) => {
        toast.success(translateToast('changed', 'password'));
        return data;
      })
      .catch((e) => showErrorToast(e, rejectWithValue));
  },
);

export const updateParentEntity = createAsyncThunk(
  'authentication/update_parent_entity',
  async (params) => {
    return parentEntityPatchAPICall(params).catch(showErrorToast);
  },
);

export const updateUser = createAsyncThunk(
  'authentication/updateUser',
  async (params) => {
    return patchUserAPICall(params).catch(showErrorToast);
  },
);

export const signupUser = createAsyncThunk(
  'authentication/signupUser',
  async (params, { rejectWithValue }) => {
    return signupAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    );
  },
);

export const forgotPassword = createAsyncThunk(
  'authentication/forgotPassword',
  async (params, { rejectWithValue }) => {
    return sendForgotPasswordEmailAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    );
  },
);
