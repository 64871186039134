/* eslint-disable react/jsx-filename-extension */
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Switch } from 'antd';
import { isEmpty } from 'lodash';

import { Icon } from 'components/ui';
import RelativeTime from 'components/ui/relativeTime/RelativeTime';
import i18next from 'i18next';
import { firstValueData } from 'utils/helpers/emailsManagementHelper';

const { t } = i18next;

export const getRegionsColumns = ({
  onEditRegion,
  onDeleteRegion,
  deleteRegionsLoading,
}) => [
  {
    title: t('name'),
    dataIndex: 'name',
    key: 'name',
    width: 'auto',
    className: 'region-name',
    onCell: () => ({
      style: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: t('createdAt'),
    className: 'region-creation-date',
    render: (text, rec) =>
      rec.created_at ? <RelativeTime date={rec.created_at} /> : '-',
    width: 210,
    key: 'created_at',
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
      },
    }),
  },
  {
    title: t('locations'),
    render: (text, rec) =>
      !isEmpty(rec.locations) ? firstValueData(rec.locations) : null,
    width: 210,
    key: 'locations',
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
      },
    }),
  },
  {
    title: t('actions'),
    key: 'actions',
    width: 100,
    render: (text, rec) => {
      const isDeleting = deleteRegionsLoading[rec.id];
      return (
        <span
          style={{ pointerEvents: isDeleting ? 'none' : 'all' }}
          className="settings-tables-actions"
        >
          <button disabled={isDeleting} onClick={() => onEditRegion(rec)}>
            <Icon type="visibility" />
          </button>
          <Popconfirm
            disabled={isDeleting}
            title={t('settings.locations.regions.deleteRegion.title')}
            description={t('settings.locations.regions.deleteRegion.subTitle', {
              name: rec.name,
            })}
            onConfirm={() => onDeleteRegion(rec)}
            okText={t('yes')}
            cancelText={t('no')}
            cancelButtonProps={{ className: 'button button-secondary' }}
            okButtonProps={{ className: 'button button-primary' }}
          >
            <button disabled={isDeleting}>
              {isDeleting ? (
                <FontAwesomeIcon width={24} height={24} icon={faSpinner} />
              ) : (
                <Icon type="delete" />
              )}
            </button>
          </Popconfirm>
        </span>
      );
    },
  },
];

export const getLocationsColumns = ({
  onSelectLocation,
  onChangeLocationStatus,
}) => [
  {
    title: t('name'),
    dataIndex: 'name',
    key: 'name',
    width: 'auto',
    className: 'location-name',
    onCell: () => ({
      style: {
        maxWidth: 300,
        whiteSpace: 'normal',
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: t('region'),
    key: 'region',
    render: (text, rec) =>
      rec.region ? (
        <span className="attached-region-name">{rec.region}</span>
      ) : (
        <button
          className="attach-region"
          onClick={() => onSelectLocation(rec, true)}
        >
          {t('attach')}
        </button>
      ),
    onCell: () => ({
      style: {
        whiteSpace: 'normal',
        maxWidth: 300,
        wordWrap: 'break-word',
      },
    }),
  },
  {
    title: t('linkedCustomerExperience'),
    className: 'attached-experiences-count',
    dataIndex: 'experiences_count',
    key: 'experiences_count',
    width: 210,
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
        width: 230,
      },
    }),
  },
  {
    title: t('activationStatus'),
    align: 'center !important',
    render: (text, rec) => {
      return (
        <Switch
          className="primary-antd-switch-input"
          checked={rec.is_active}
          onChange={() => onChangeLocationStatus(rec)}
        />
      );
    },
    onCell: () => ({
      style: {
        whiteSpace: 'nowrap',
        width: 150,
      },
    }),
  },
  {
    title: t('actions'),
    key: 'actions',
    width: 100,
    render: (text, rec) => {
      return (
        <span className="settings-tables-actions">
          <button onClick={() => onSelectLocation(rec)}>
            <Icon type="visibility" />
          </button>
        </span>
      );
    },
  },
];
