/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTranslation } from 'react-i18next';

import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  faCopy,
  faPencilAlt,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popconfirm, Tooltip, Typography } from 'antd';

import galleryIcon from '../../assets/icons/icon_gallery.svg';

import './_surveyThumbnail.scss';

export default function SurveyThumbnail({
  data,
  onChange,
  selected,
  template,
  onEdit,
  icon,
  isDeleteLoading = false,
  onDelete,
  onCopy,
  isCopyLoading,
  styles = {},
}) {
  const { t } = useTranslation();
  function handleDuplicateClick() {
    if (!isCopyLoading) {
      onCopy();
    }
  }

  return (
    <div
      style={styles}
      className={`survey-thumbnail addItem ${selected ? 'selectedItem' : ''} ${
        isDeleteLoading ? 'disabled-actions' : ''
      }`}
      tabIndex={0}
      role="button"
      onClick={() => onChange?.(data)}
    >
      {template && <div className="template">template</div>}

      <div className="preview">
        <img src={data.preview} alt="survey thumbnail" />
        <img
          src={icon || galleryIcon}
          alt="experience"
          className="experienceIcon"
        />
      </div>
      <Typography.Text
        style={{ maxWidth: '90%' }}
        ellipsis={{
          tooltip: {
            title: data.title,
            getPopupContainer: (target) => target.parentElement,
          },
        }}
      >
        {data.title}
      </Typography.Text>
      <div className="actions">
        {onEdit && (
          <FontAwesomeIcon
            className="actionIcon"
            icon={faPencilAlt}
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          />
        )}
        {onDelete && !template && data.is_deletable && (
          <Popconfirm
            title={t('areYouSure')}
            description={t('settings.feedbackForms.deletePopup.title')}
            onConfirm={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            onCancel={(e) => {
              e.stopPropagation();
            }}
            okText={t('yes')}
            cancelText={t('no')}
            cancelButtonProps={{ className: 'button button-secondary' }}
            okButtonProps={{ className: 'button button-primary' }}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            getPopupContainer={(target) => target.parentNode}
          >
            <FontAwesomeIcon
              className="actionIcon"
              icon={isDeleteLoading ? faSpinner : faTrash}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        )}
        {onCopy && !template && (
          <Tooltip title={t('settings.feedbackForms.createDuplicate')}>
            <FontAwesomeIcon
              className="actionIcon"
              icon={isCopyLoading ? faSpinner : faCopy}
              onClick={(e) => {
                e.stopPropagation();
                handleDuplicateClick();
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}
