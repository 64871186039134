import dayjs from 'dayjs';
import { isEmpty, isEqual, mapValues, pick } from 'lodash';

import store from 'redux/store';

export const compareBranchExpFilters = (filter1, filter2) => {
  if (isEmpty(filter1) && isEmpty(filter2)) {
    return true;
  }
  const extractRelevantData = (obj) => {
    return mapValues(obj, (item) => pick(item, ['value', 'label']));
  };

  return isEqual(extractRelevantData(filter1), extractRelevantData(filter2));
};

export const getActiveFilterId = (savedFilters, appliedFilter) => {
  return savedFilters.find(
    ({ filter_data: { dateRangeFilter, branchExpFilter } }) => {
      return (
        dateRangeFilter?.startDate ===
          appliedFilter?.dateRangeFilter?.startDate &&
        dateRangeFilter?.endDate === appliedFilter?.dateRangeFilter?.endDate &&
        branchExpFilter?.branch?.value ===
          appliedFilter?.branchExpFilter?.branch?.value &&
        branchExpFilter?.experience?.value ===
          appliedFilter?.branchExpFilter?.experience?.value &&
        branchExpFilter?.region?.value ===
          appliedFilter?.branchExpFilter?.region?.value
      );
    },
  );
};

export const getDashboardDefaultDateRange = () => {
  const { parentEntity } = store.getState().authentication;
  const firstFeedbackDate =
    parentEntity?.first_feedback_date ?? parentEntity?.created_at;
  const oneYearDate = dayjs().subtract(12, 'months').format('YYYY-MM-DD');
  const startDate =
    firstFeedbackDate && dayjs(firstFeedbackDate).isAfter(oneYearDate)
      ? dayjs(firstFeedbackDate).format('YYYY-MM-DD')
      : oneYearDate;
  return {
    startDate,
    endDate: dayjs().format('YYYY-MM-DD'),
  };
};
