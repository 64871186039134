import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import WidgetsConfigModal from 'components/modals/WidgetsConfigModal';
import {
  getConfigWidgets,
  postConfigWidgets,
} from 'redux/dashboard/dashboardActions';
import { setConfigWidgets } from 'redux/dashboard/dashboardSlice';

import './_configurationPanel.scss';

function ConfigurationPanel({ isOpen, setIsOpen }) {
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState(false);
  const { isLoading, data } = useSelector(
    (state) => state.dashboard.configWidgets,
  );

  const dispatch = useDispatch();

  const handleCloseConfig = () => {
    dispatch(setConfigWidgets()); // set widgets states to default
    setIsOpen(false);
  };

  const toggleSingleSurveyQuestions = (checked, currentSurveyIndex) => {
    const tempSurveys = [...data];
    const currentSurvey = { ...tempSurveys[currentSurveyIndex] };
    currentSurvey.questions = currentSurvey.questions.map((q) => ({
      ...q,
      is_enabled: checked,
    }));
    tempSurveys[currentSurveyIndex] = currentSurvey;
    dispatch(setConfigWidgets(tempSurveys));
    setIsDirty(true);
  };

  const toggleQuestionSelection = (
    checked,
    currentSurveyIndex,
    questionIndex,
  ) => {
    const tempSurveys = [...data];
    const currentSurvey = {
      ...tempSurveys[currentSurveyIndex],
      questions: [
        ...tempSurveys[currentSurveyIndex].questions.slice(0, questionIndex),
        {
          ...tempSurveys[currentSurveyIndex].questions[questionIndex],
          is_enabled: checked,
        },
        ...tempSurveys[currentSurveyIndex].questions.slice(questionIndex + 1),
      ],
    };
    tempSurveys[currentSurveyIndex] = currentSurvey;

    dispatch(setConfigWidgets(tempSurveys));
    setIsDirty(true);
  };

  const handleSaveConfigurations = async () => {
    const widgets = data.reduce((acc, val) => {
      acc.push(
        val.questions.map((v) => ({
          question: v.id,
          is_enabled: v.is_enabled,
        })),
      );
      return acc;
    }, []);
    await dispatch(postConfigWidgets({ widgets: widgets.flat() }));
    setIsOpen(false);
    setIsDirty(false);
  };

  const handleCancelConfigurations = () => {
    handleCloseConfig();
  };

  useEffect(() => {
    dispatch(getConfigWidgets());
  }, []);

  return (
    <div>
      <div className="headerDropdownsWrapper spb">
        <span>{t('dashboard.surveyDrivers.title')}</span>
      </div>
      <WidgetsConfigModal
        data={data}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
        isOpen={isOpen}
        isLoading={isLoading}
        toggleQuestionSelection={toggleQuestionSelection}
        toggleSingleSurveyQuestions={toggleSingleSurveyQuestions}
        onClose={handleCloseConfig}
        onCancel={handleCancelConfigurations}
        onSave={handleSaveConfigurations}
      />
    </div>
  );
}

export default ConfigurationPanel;
