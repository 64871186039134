import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import { STATUS } from 'utils/constants';

import './_reviewsFilters.scss';

/**
 * @author
 * @function StatusFilter
 * */

function StatusFilter({ placeholder, ...rest }) {
  const { t } = useTranslation();
  const statuses = useMemo(
    () => STATUS.map((status) => ({ ...status, name: t(status.name) })),
    [],
  );

  return (
    <SelectWithCustomSuffixIcon
      currentFilterClassName="status-list-filter"
      id="status-list"
      name="status"
      options={statuses}
      isRemoteSearchable={false}
      placeholder={placeholder ?? t('status')}
      {...rest}
    />
  );
}

export default StatusFilter;
