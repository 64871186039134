import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { QuestionAnalytics } from 'components';

import './_widgets.scss';

export default function Widgets({ filters, redirectToReviewsPage }) {
  const { t } = useTranslation();
  const enabledWidgets = useSelector((state) => state.dashboard.enabledWidgets);

  return enabledWidgets.length ? (
    <section className="chartsWrapper">
      {enabledWidgets.map((widget) => (
        <Card className="chartCard" key={widget.id}>
          <QuestionAnalytics
            key={widget.id}
            questionUUID={widget.uuid}
            filters={filters}
            redirectToReviewsPage={redirectToReviewsPage}
          />
        </Card>
      ))}
    </section>
  ) : (
    <div className="emptyWidgets">{t('dashboard.manageWidgets')}</div>
  );
}
