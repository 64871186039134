import { forwardRef } from 'react';

import useClickOutside from 'utils/hooks/useClickOutside';

function EmailModalInput(
  {
    formik,
    label,
    id,
    onKeyDown,
    placeholder,
    isTextInput = true,
    children,
    closeGroupSuggestions,
    ...props
  },
  ref,
) {
  const clickOutsideRef = useClickOutside({
    onClickOutside: closeGroupSuggestions,
  });

  return (
    <div ref={clickOutsideRef}>
      <label className="email-input-label" htmlFor={id}>
        <span className="label-name">{label}</span>
        {isTextInput ? (
          <input
            ref={ref}
            type="text"
            name={id}
            placeholder={placeholder}
            onChange={formik.handleChange}
            value={formik.values[id]}
            {...props}
          />
        ) : (
          <input
            ref={ref}
            id={id}
            type="text"
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            {...props}
          />
        )}
      </label>
      {formik.touched[id] && (
        <span className="control-error">{formik.errors[id]}</span>
      )}
      {children}
    </div>
  );
}

export default forwardRef(EmailModalInput);
