import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui';

import './_searchbar.scss';

function Searchbar({ value, onChange, placeholder }) {
  const { t } = useTranslation();
  const [text, setText] = useState(value);

  const onEnterPress = (e) => {
    const { code } = e;
    if (code === 'Enter') {
      e.preventDefault();
      onChange(text);
    }
  };

  const onClearSearch = () => {
    setText('');
    onChange('');
  };

  useEffect(() => {
    if (!value) setText('');
  }, [value]);

  return (
    <label style={{ maxWidth: '100%' }} className="control control-icon">
      <Icon
        type="search"
        style={{ cursor: 'pointer' }}
        onClick={() => onChange(text)}
      />
      <input
        onKeyDown={onEnterPress}
        name="name"
        placeholder={placeholder ?? t('searchDots')}
        value={text}
        onChange={({ target: { value: v } }) => setText(v)}
      />
      {text ? (
        <button onClick={onClearSearch}>
          <Icon type="close" />
        </button>
      ) : null}
    </label>
  );
}

export default Searchbar;
