import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import WarningModal from './WarningModal';

const { useDetectMobileScreen } = require('utils/hooks');

function DesktopSignupWarning({ pageName = 'sign up', hideWarning }) {
  const { t } = useTranslation();
  const isMobile = useDetectMobileScreen();
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(true);

  useEffect(() => {
    if (isMobile) {
      setIsOpenWarningModal(true);
    }
  }, [isMobile]);

  return isMobile && isOpenWarningModal && !hideWarning ? (
    <WarningModal
      wrapperStyles={{ zIndex: 11111 }}
      title={t('warning')}
      subTitle={t('signup.signupDeviceWarning', { pageName })}
      primaryAction={() => setIsOpenWarningModal(false)}
      primaryActionTitle={t('ok')}
    />
  ) : null;
}

export default DesktopSignupWarning;
