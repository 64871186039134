import { LocationThumbnail } from 'components/locationThumbnail';

import './_locationSelector.scss';

export default function LocationSelector({ onChange, value, locations }) {
  return (
    <div className="location-selector__container">
      {locations.map((location) => (
        <LocationThumbnail
          selectOnMount
          selected={value?.id === location.id}
          key={location.id}
          onSelect={() => onChange(location)}
        >
          {location.name}
        </LocationThumbnail>
      ))}
    </div>
  );
}
