import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Stepper } from 'components';
import OrganizationSwitcher from 'components/organizationSwitcher/OrganizationSwitcher';
import { ONBOARDING_STEPS } from 'utils/constants';
import { logout } from 'utils/helpers';

import './_onboardingLayout.scss';

function Header({
  step,
  showLogoutButton = true,
  showOrgSwitcher = true,
  showStepper = true,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogout = () => {
    navigate(pathname, {
      search: '',
      replace: true,
      state: { refreshState: true },
    });
    logout();
  };

  return (
    <header className="onboarding-header">
      {showOrgSwitcher ? <OrganizationSwitcher /> : null}
      <div className="onboarding-brand">
        <img src="/assets/images/appLogo.svg" alt="logo" />
        {showLogoutButton ? (
          <button
            onClick={handleLogout}
            type="button"
            className="button button-secondary"
          >
            {t('logout')}
          </button>
        ) : null}
      </div>
      {showStepper ? (
        <Stepper steps={ONBOARDING_STEPS} selectedStep={step} />
      ) : null}
    </header>
  );
}

function Footer({
  onClickContinue,
  disableContinue,
  onClickBack,
  backText,
  isLoading = false,
  hideBackButton = false,
  continueText,
}) {
  const { t } = useTranslation();
  return (
    <footer className="onboarding-actions">
      {hideBackButton ? null : (
        <button
          type="button"
          className="button button-secondary"
          onClick={onClickBack}
        >
          {backText ?? t('back')}
        </button>
      )}

      <button
        type="button"
        disabled={disableContinue || isLoading}
        onClick={onClickContinue}
        className="button button-primary"
        aria-busy={isLoading ? true : null}
      >
        {continueText ?? t('continue')}
      </button>
    </footer>
  );
}

function Sidepane({ children }) {
  const { t } = useTranslation();
  return (
    <figure className="onboarding-sidepane">
      <div className="infoBanner-container">
        {children ?? (
          <div className="infoBanner">
            <h2>
              🚀
              <br />
              {t('onboarding.sidepane.title')}
            </h2>
            <p>{t('onboarding.sidepane.subTitle')}</p>
          </div>
        )}
      </div>
    </figure>
  );
}

function Main({ children }) {
  return <div className="onboarding-content">{children}</div>;
}

function Form({ children, title, subtitle }) {
  return (
    <div className="onboarding-form">
      <section>
        <hgroup className="onboarding-title">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </hgroup>
      </section>
      <section>{children}</section>
    </div>
  );
}

function OnboardingLayout({ children, centered = false }) {
  return (
    <article className={`onboarding ${centered ? 'onboarding-centered' : ''}`}>
      {children}
    </article>
  );
}

OnboardingLayout.Main = Main;
OnboardingLayout.Sidepane = Sidepane;
OnboardingLayout.Header = Header;
OnboardingLayout.Form = Form;
OnboardingLayout.Footer = Footer;
export default OnboardingLayout;
