import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import AppQRCodes from 'components/appQRCodes/AppQRCodes';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { updateParentEntity } from 'redux/authentication/authenticationActions';
import { URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import './_wellcome.scss';

export default function WellcomeScreen() {
  const dispatch = useDispatch();
  const { name, last_onboarding_url: lastUrl } =
    useSelector((state) => state.authentication.parentEntity) ?? {};
  const { t } = useTranslation();
  const lastUrlRef = useRef(lastUrl);

  usePageTitle(t('pages.welcome'));

  useEffect(() => {
    dispatch(updateParentEntity({ last_onboarding_url: null, name }));
  }, []);

  if (lastUrlRef.current === null) {
    return <Navigate to={URLS.DASHBOARD_URL} />;
  }

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header
          showLogoutButton={false}
          showOrgSwitcher={false}
          showStepper={false}
        />
        <OnboardingLayout.Form title={t('onboarding.welcome.title')}>
          <p>{t('onboarding.welcome.subTitle')}</p>
          <Link to={URLS.SURVEYS_URL} className="button button-primary">
            {t('surveys')}
          </Link>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        <div className="auth-divider divider-vertical">{t('or')}</div>
        <div>
          <p>{t('onboarding.welcome.downloadApp')}</p>
          <AppQRCodes />
          <div className="button-group">
            <Link to={URLS.DASHBOARD_URL} className="button button-primary">
              {t('onboarding.welcome.continueToDashboard')}
            </Link>
          </div>
        </div>
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}
