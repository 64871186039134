import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Popconfirm, Popover } from 'antd';

import '../_qrSetup.scss';

export default function TriDotMenu({
  onEdit,
  onDelete,
  onDuplicate,
  onViewSurvey,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState();

  const handleSelectItem = (onClickAction) => {
    setOpen(false);
    onClickAction();
  };

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      content={
        <List bordered>
          <List.Item onClick={() => handleSelectItem(onEdit)}>Modify</List.Item>
          <List.Item onClick={() => handleSelectItem(onViewSurvey)}>
            {t('viewSurvey')}
          </List.Item>
          <List.Item onClick={() => handleSelectItem(onDuplicate)}>
            {t('duplicateQr')}
          </List.Item>
          <Popconfirm
            title={t('areYouSure')}
            description={t('deleteRecordWarning')}
            okText={t('yes')}
            cancelText={t('no')}
            cancelButtonProps={{ className: 'button button-secondary' }}
            okButtonProps={{ className: 'button button-primary' }}
            onConfirm={() => {
              handleSelectItem(onDelete);
            }}
          >
            <List.Item>{t('remove')}</List.Item>
          </Popconfirm>
        </List>
      }
      trigger="click"
      overlayInnerStyle={{ padding: 0 }}
      overlayClassName="dotMenu"
    >
      <button className="dotMenu" onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faEllipsisVertical} size="lg" />
      </button>
    </Popover>
  );
}
