import { React } from 'react';
import { useTranslation } from 'react-i18next';

import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch } from 'antd';

import { RichTextEditor } from 'components/richTextEditor';

import './_surveyCustomerDetail.scss';

export default function SurveyCustomerDetail({ formik }) {
  const { t } = useTranslation();
  const { values, errors } = formik;
  const {
    label,
    name_placeholder: namePlaceholder,
    email_placeholder: emailPlaceholder,
    is_active: isActive,
  } = values.respondent_question;

  const handleRequiredChange = () => {
    formik.setFieldValue(`respondent_question.is_active`, !isActive);
  };

  const handleRespondentQuestion = (respondentQuestionLabel) => {
    formik.setFieldValue('respondent_question.label', respondentQuestionLabel);
  };

  const handelRespondentInfoChange = ({ target: { name, value } }) => {
    formik.setFieldValue(`respondent_question.${name}`, value);
  };

  return (
    <div className="customerDetailBlock">
      <div className="surveyBuilderSection">
        <div className="topMessage">
          <div className="editor-container">
            <RichTextEditor
              readOnly={!isActive}
              placeholder={t('formBuilder.placeholders.respondentQuestion')}
              setState={handleRespondentQuestion}
              content={label ?? ''}
              error={errors.respondent_question?.label}
            />
          </div>
        </div>
        <div className="block">
          <div className="form">
            <div className="inputBlock">
              <div className="inputHolder">
                <div className="icons">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <input
                  disabled={!isActive}
                  placeholder={t('inputFields.namePlaceholder')}
                  onChange={handelRespondentInfoChange}
                  name="name_placeholder"
                  value={namePlaceholder}
                />
              </div>
              {errors.respondent_question?.name_placeholder && (
                <span className="control-error">
                  {errors.respondent_question?.name_placeholder}
                </span>
              )}
            </div>
            <div className="inputBlock">
              <div className="inputHolder">
                <div className="icons">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <input
                  disabled={!isActive}
                  placeholder={t('inputFields.emailPlaceholder')}
                  onChange={handelRespondentInfoChange}
                  name="email_placeholder"
                  value={emailPlaceholder}
                />
              </div>
              {errors.respondent_question?.email_placeholder && (
                <span className="control-error">
                  {errors.respondent_question?.email_placeholder}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="enableDetailSwitch">
          <p>{isActive ? t('enabled') : t('disabled')}</p>
          <Switch
            className="primary-antd-switch-input"
            onChange={handleRequiredChange}
            checked={isActive}
          />
        </div>
      </div>
    </div>
  );
}
