import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui';

import './_Payment.scss';

export default function PaymentCard({
  payBy,
  last4,
  billingContact,
  onEditBillingContact,
}) {
  const { t } = useTranslation();
  return (
    <div className="card-details">
      <div>
        <h6>{t('payBy')}</h6>
        <p className="text-capitalize">{payBy}</p>
      </div>
      <div>
        <h6>{t('cardNumber')}</h6>
        <p>
          **** **** **** &nbsp;
          {last4}
        </p>
      </div>
      <div>
        <h6>{t('billingAddress')}</h6>
        <p>
          {billingContact}
          {onEditBillingContact ? (
            <button className="link" onClick={onEditBillingContact}>
              <Icon type="edit_square" />
            </button>
          ) : null}
        </p>
      </div>
    </div>
  );
}
