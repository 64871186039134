import { useTranslation } from 'react-i18next';

import WarningModal from 'components/ui/modal/WarningModal';

export default function SurveyEditedModal({
  onClose,
  isOpenModal,
  primaryAction,
  secondaryAction,
}) {
  const { t } = useTranslation();
  return (
    <WarningModal
      onClose={onClose}
      isOpen={isOpenModal}
      bodyStyles={{ width: 550 }}
      title={t('formBuilder.editedModal.title')}
      subTitle={
        <div style={{ maxHeight: 'calc(100% - 100px' }}>
          {t('formBuilder.editedModal.updateQuestionPrompt')}
          <br />
          {t('formBuilder.editedModal.newQuestionWarning')}
          <br />
          {t('formBuilder.editedModal.updateOriginalInfo')}
        </div>
      }
      primaryActionTitle={t('formBuilder.editedModal.primaryCta')}
      primaryAction={primaryAction}
      secondaryActionTitle={t('formBuilder.editedModal.secondaryCta')}
      secondaryAction={secondaryAction}
    />
  );
}
