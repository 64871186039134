import i18next from 'i18next';
import { ENTITY_ADMIN } from 'utils/constants';
import { onlyLettersAndSpacesRegex } from 'utils/constants/regex';
import { isNumberOrParsableString } from 'utils/helpers';

import Yup from './Yup';
import translateFieldError from './validationTranslations';

const tfe = translateFieldError;

const userSchema = () => {
  const { t } = i18next;
  const entitiesError = t('settings.users.entitiesError');

  return Yup.object().shape({
    first_name: Yup.string()
      .requiredTrimmed(tfe('blank', 'firstName'), tfe('required', 'firstName'))
      .matches(onlyLettersAndSpacesRegex, tfe('lettersOnly', 'firstName'))
      .range(
        2,
        64,
        tfe('minLength', 'firstName', 2),
        tfe('maxLength', 'firstName', 64),
      ),
    last_name: Yup.string()
      .requiredTrimmed(tfe('blank', 'lastName'), tfe('required', 'lastName'))
      .matches(onlyLettersAndSpacesRegex, tfe('lettersOnly', 'lastName'))
      .range(
        2,
        64,
        tfe('minLength', 'lastName', 2),
        tfe('maxLength', 'lastName', 64),
      ),
    email: Yup.string().required(tfe('required', 'email')).customEmail(),
    entities: Yup.array().when('type', {
      is: (type) => parseInt(type, 10) === ENTITY_ADMIN,
      then: (schema) =>
        schema.required(t(entitiesError)).min(1, t(entitiesError)),
      otherwise: (schema) => schema.notRequired(),
    }),
    type: Yup.string().trim().required(tfe('required', 'userType')),
    showPasswordFields: Yup.boolean().required(),
    id: Yup.mixed().nullable(),
    alreadyExist: Yup.boolean().nullable(),
    password: Yup.string()
      .min(8, tfe('minLengthPassword', 'password', 8))
      .max(128, tfe('maxLengthPassword', 'password', 128))
      .when(['id', 'showPasswordFields', 'alreadyExist'], {
        is: (id, showPasswordFields, alreadyExist) =>
          !isNumberOrParsableString(id) ||
          alreadyExist ||
          (isNumberOrParsableString(id) && showPasswordFields),
        then: (schema) => schema.required(tfe('required', 'password')),
        otherwise: (schema) => schema.notRequired(),
      }),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], tfe('passwordsMustMatch'))
      .when(['id', 'showPasswordFields', 'alreadyExist'], {
        is: (id, showPasswordFields, alreadyExist) =>
          !isNumberOrParsableString(id) ||
          alreadyExist ||
          (isNumberOrParsableString(id) && showPasswordFields),
        then: (schema) => schema.required(tfe('required', 'confirmPassword')),
        otherwise: (schema) => schema.notRequired(),
      }),
  });
};

export default userSchema;
