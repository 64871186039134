import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import './_dateFilter.scss';

function DateSelector({ value, onChange }) {
  const { t } = useTranslation();
  const dateFormat = 'YYYY-MM-DD';
  const dateType = { default: 0, custom: 1 };
  const [selectedDateOption, setSelectedDateOption] = useState(
    dateType.default,
  );
  const [selectedDate, setSelectedDate] = useState(value);
  const { RangePicker } = DatePicker;
  const { parentEntity } = useSelector((s) => s.authentication);

  const defaultStartDate =
    parentEntity?.first_feedback_date ?? parentEntity?.created_at;

  const definedOpts = [
    {
      id: 1,
      label: t('filters.dateFilter.last7Days'),
      startDate: dayjs().subtract(7, 'days'),
      endDate: dayjs(),
    },
    {
      id: 2,
      label: t('filters.dateFilter.lastMonth'),
      startDate: dayjs().subtract(1, 'month').startOf('month'),
      endDate: dayjs().subtract(1, 'month').endOf('month'),
    },
    {
      id: 3,
      label: t('filters.dateFilter.last30Days'),
      startDate: dayjs().subtract(30, 'days'),
      endDate: dayjs(),
    },
    {
      id: 4,
      label: t('filters.dateFilter.lastQuarter'),
      startDate: dayjs().subtract(1, 'quarter').startOf('quarter'),
      endDate: dayjs().subtract(1, 'quarter').endOf('quarter'),
    },
    {
      id: 5,
      label: t('filters.dateFilter.ytd'),
      startDate: dayjs().startOf('year'),
      endDate: dayjs(),
    },
  ];

  const reset = () => {
    onChange({});
  };

  const rangeChangeHandler = (range) => {
    const [startDate, endDate] = range ?? [];
    setSelectedDate({
      startDate,
      endDate,
      label: t('filters.dateFilter.customRange'),
    });
  };

  const apply = () => {
    onChange({
      startDate: selectedDate.startDate
        ? selectedDate.startDate.format(dateFormat)
        : dayjs(defaultStartDate).format(dateFormat),
      endDate: selectedDate.endDate
        ? selectedDate.endDate.format(dateFormat)
        : dayjs().format(dateFormat),
      label: selectedDate.label,
    });
  };

  const select = (key) => () => {
    setSelectedDateOption(key);
    if (key === dateType.custom) {
      setSelectedDate({
        startDate: dayjs(defaultStartDate),
        endDate: dayjs(),
        label: t('filters.dateFilter.customRange'),
      });
    }
  };

  return (
    <div className="dateSelectorContainer">
      <div className="dateSelectorHeader">
        <button
          type="button"
          className={`dateSelectorHeaderButton ${
            selectedDateOption === dateType.default
              ? 'selectedDateSelectorHeaderButton'
              : ''
          }`}
          onClick={select(dateType.default)}
        >
          {t('default')}
        </button>
        <button
          type="button"
          className={`dateSelectorHeaderButton ${
            selectedDateOption === dateType.custom
              ? 'selectedDateSelectorHeaderButton'
              : ''
          }`}
          onClick={select(dateType.custom)}
        >
          {t('custom')}
        </button>
      </div>

      {dateType.default === selectedDateOption && (
        <div className="customOptContainer">
          {definedOpts.map((date, idx) => (
            <button
              type="button"
              onClick={() => setSelectedDate(date)}
              key={idx}
              className={selectedDate.id === date.id ? 'selected' : ''}
            >
              <div>{date.label}</div>
              <div>
                {' '}
                {date.startDate?.format(dateFormat)} -{' '}
                {date.endDate?.format(dateFormat)}
              </div>
            </button>
          ))}
        </div>
      )}

      {dateType.custom === selectedDateOption && (
        <div className="customBody">
          <RangePicker
            defaultValue={[
              dayjs(defaultStartDate, dateFormat),
              dayjs(dayjs().format(dateFormat), dateFormat),
            ]}
            minDate={dayjs(defaultStartDate, dateFormat)}
            maxDate={dayjs()}
            onChange={rangeChangeHandler}
          />
        </div>
      )}

      <div className="drp-buttons">
        <button className="applyBtn" type="button" onClick={apply}>
          {t('apply')}
        </button>
        <button className="cancelBtn" type="button" onClick={reset}>
          {t('reset')}
        </button>
      </div>
    </div>
  );
}

export default DateSelector;
