import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'antd';
import { isEmpty } from 'lodash';

import { SettingsPageContext } from 'contexts';
import SettingsPageContextProvider from 'layouts/settingsPageLayout/SettingsPageContextProvider';
import SettingsPageLayout from 'layouts/settingsPageLayout/SettingsPageLayout';
import {
  deleteQr,
  getQrList,
  printAllQrs,
  updateQrListRow,
} from 'redux/qrSetup/qrSetupActions';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { QR_LIST_TABS } from 'utils/constants/settingsPage';
import { attachKeysToDataSource, getAntdPaginationProps } from 'utils/helpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

import { QrListColumns } from './columns';
import PrintQrModal from './components/PrintQrModal';
import QrDetailModal from './components/QrDetail';
import ShareModal from './components/ShareModal';

import './_qrSetup.scss';

export function QrList() {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const {
    qrList: { data: qrList, loading },
    qrTableRowsLoading,
    isPrintingAllQrs,
  } = useSelector((s) => s.qrSetup);
  const [selectedQr, setSelectedQr] = useState();
  const [qrToPrint, setQrToPrint] = useState();
  const [qrToShare, setQrToShare] = useState();

  const { activeTab, params, onChangePagination } =
    useContext(SettingsPageContext);

  const onChange = (pagination, filters, sorter) => {
    const page = pagination.current !== params.page ? pagination.current : 1;
    const sortBy = sorter?.field ?? params.sort_by;
    const sortOrder = sorter?.order ?? params.sort_order;
    onChangePagination(page, DEFAULT_PAGE_SIZE, sortBy, sortOrder);
  };

  const onEdit = (rec) => {
    setSelectedQr(rec);
  };
  const onDelete = async (rec) => {
    const { isError } = await dispatch(deleteQr(rec.id));
    if (!isError) onChange({ current: 1 });
  };
  const onDuplicate = (rec) => {
    setSelectedQr({ isDuplicate: true, qrIdToDuplicate: rec.id });
  };
  const onShareUrl = (rec) => {
    setQrToShare(rec);
  };
  const onDetailClose = () => {
    setSelectedQr(null);
  };
  const onDetailSaveAfter = () => {
    dispatch(getQrList(params));
  };
  const onNew = () => {
    setSelectedQr('new');
  };
  const onUpdateQrRow = (
    id,
    serialNumber,
    updatedParams,
    rowIndex,
    columnKey,
  ) => {
    dispatch(
      updateQrListRow({
        id,
        serialNumber,
        params: updatedParams,
        rowIndex,
        columnKey,
      }),
    );
  };

  const printAllQr = () => {
    if (!isPrintingAllQrs) {
      dispatch(printAllQrs()).then((res) => {
        const pdfUrl = URL.createObjectURL(res.data);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = pdfUrl;

        const printCheckInterval = setInterval(() => {
          if (iframe.contentWindow) {
            iframe.contentWindow.print();
            clearInterval(printCheckInterval);
          }
        }, 1000);

        iframe.onafterprint = function () {
          document.body.removeChild(iframe);
        };
      });
    }
  };

  const onViewSurvey = (survey) => {
    if (survey?.url) window.open(survey.url);
  };

  useEffect(() => {
    dispatch(getQrList(params));
  }, [params]);

  return (
    <>
      <SettingsPageLayout
        pageTitle={t('settings.qrSetup.title')}
        pageSubTitle={t('settings.qrSetup.subTitle')}
        tabs={QR_LIST_TABS}
      >
        {{
          generalAction: activeTab.isActiveSubTab ? null : (
            <div className="controls">
              <button
                type="button"
                className="print-all-qrs cstm-btn secondary-cstm-btn"
                size="large"
                aria-busy={isPrintingAllQrs ? true : null}
                onClick={printAllQr}
                style={
                  isPrintingAllQrs
                    ? { cursor: 'not-allowed', pointerEvents: 'none' }
                    : {}
                }
              >
                {isPrintingAllQrs ? null : <FontAwesomeIcon icon={faPrint} />}
                <span>{t('settings.qrSetup.printAll')}</span>
              </button>
              <button
                className="generate-new-qr-btn cstm-btn primary-cstm-btn"
                type="button"
                size="large"
                onClick={onNew}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>{t('generateNew')}</span>
              </button>
            </div>
          ),
          currentTabContent: (
            <div className="qr-list-table">
              <Table
                loading={loading}
                columns={QrListColumns(
                  onEdit,
                  onViewSurvey,
                  onDelete,
                  onUpdateQrRow,
                  onShareUrl,
                  setQrToPrint,
                  onDuplicate,
                  qrTableRowsLoading,
                )}
                scroll={{ x: 'max-content' }}
                dataSource={attachKeysToDataSource(qrList.results)}
                onChange={onChange}
                pagination={{
                  ...getAntdPaginationProps({
                    data: qrList,
                    currentPage: params.page ?? 1,
                    onChangePagination,
                  }),
                }}
              />
            </div>
          ),
        }}
      </SettingsPageLayout>
      {!isEmpty(selectedQr) && (
        <QrDetailModal
          selectedQr={selectedQr}
          onClose={onDetailClose}
          onSaveAfter={onDetailSaveAfter}
          onPrint={setQrToPrint}
        />
      )}
      {!isEmpty(qrToPrint) && (
        <PrintQrModal qr={qrToPrint} onClose={() => setQrToPrint(null)} />
      )}
      {!isEmpty(qrToShare) && (
        <ShareModal qr={qrToShare} onClose={() => setQrToShare(null)} />
      )}
    </>
  );
}

function MangaeQrList() {
  return (
    <SettingsPageContextProvider tabs={QR_LIST_TABS}>
      <QrList />
    </SettingsPageContextProvider>
  );
}

export default MangaeQrList;
