import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'components/ui';
import { copyToClipboard } from 'utils/helpers';

import GoogleLocationModal from './GoogleLocationModal';

const translationKey = 'settings.locations.googleReviews.googleReviewCard';

function GoogleReviewCard({ formik }) {
  const { t } = useTranslation();
  const { values } = formik;
  const [isOpenGoogleLocationModal, setIsOpenGoogleLocationModal] =
    useState(false);

  const handleCopyApiKey = () => {
    copyToClipboard(values.google_place_id, 'Place Id');
  };

  const handleOpenGooglePlacesModal = () => {
    setIsOpenGoogleLocationModal(true);
  };

  const handleRemoveGoogleReviews = () => {
    formik.setFieldValue('google_place_id', null);
  };

  return (
    <div
      className={`google-reviews-card ${
        values.google_place_id ? '' : 'single-col'
      }`}
    >
      <div className="info">
        <img
          src="/assets/icons/icon_google.svg"
          alt="Google Logo"
          className="google-logo"
        />
        <div className="text-content">
          <h5>{t(`${translationKey}.title`)}</h5>
          <p>{t(`${translationKey}.subTitle`)}</p>
        </div>
      </div>
      {values.google_place_id ? (
        <div className="google-reviews-details">
          <div className="edit-review-action">
            <h6>{t(`details`)}</h6>
            <button
              onClick={handleOpenGooglePlacesModal}
              className="cstm-btn text-cstm-btn"
            >
              <Icon type="edit" />
              <span>{t(`edit`)}</span>
            </button>
          </div>
          <div className="remove-review-action">
            <div>
              <h6 style={{ fontWeight: 400 }}>{t('placeId')}</h6>
              <p className="copy-place-id">
                <span>{values.google_place_id}</span>
                <button onClick={handleCopyApiKey}>
                  <img src="/assets/icons/icon_copy.svg" alt="Copy" />
                </button>
              </p>
            </div>
            <button
              onClick={handleRemoveGoogleReviews}
              className="cstm-btn primary-cstm-btn"
            >
              {t(`remove`)}
            </button>
          </div>
        </div>
      ) : (
        <div style={{ paddingLeft: 55 }}>
          <button
            onClick={handleOpenGooglePlacesModal}
            className="cstm-btn secondary-cstm-btn"
          >
            {t(`configure`)}
          </button>
        </div>
      )}
      <GoogleLocationModal
        formik={formik}
        isOpen={isOpenGoogleLocationModal}
        onClose={() => setIsOpenGoogleLocationModal(false)}
      />
    </div>
  );
}

export default GoogleReviewCard;
