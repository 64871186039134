import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { getCities } from 'redux/demographics/demographicsActions';

export default function CityDropDown({
  onChange,
  value,
  stateId,
  error,
  placeholder,
  ...rest
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: cities, loading } = useSelector(
    (state) => state.demographics.cities,
  );

  useEffect(() => {
    if (!stateId) return;
    dispatch(getCities({ stateId }));
  }, [stateId]);

  return (
    <SelectAndCreateOptionDropdown
      options={cities}
      isPaginationLoading={loading}
      selectedOption={value || undefined}
      dropdownError={error}
      onChange={onChange}
      placeholder={placeholder ?? t('filters.select.city')}
      disabled={!stateId}
      {...rest}
    />
  );
}
