import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import {
  PieChartBlurPreview,
  TopPerformersBlurPreview,
  WidgetRatingBlurPreview,
  WorstPerformersBlurPreview,
} from 'components/statBlurWrapper';
import StatBlurWrapper from 'components/statBlurWrapper/StatBlurWrapper';

export default function WidgetsPreview() {
  const { t } = useTranslation();
  const previewUrls = [
    WidgetRatingBlurPreview,
    PieChartBlurPreview,
    WorstPerformersBlurPreview,
    TopPerformersBlurPreview,
  ];

  return (
    <div>
      <span>{t('dashboard.surveyDrivers.title')}</span>
      <section className="chartsWrapper">
        {[1, 2, 3].map((v) => (
          <StatBlurWrapper
            key={v}
            showStat={false}
            previewUrl={previewUrls[_.random(0, previewUrls.length - 1)]}
            alt="Widget Preivew"
          />
        ))}
      </section>
    </div>
  );
}
