import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';

import { Dropdown } from 'components/ui';
import {
  COMPARISON_METHODS,
  COMPARISON_PREVIEWS,
} from 'utils/constants/compareFeedbacks';

import ComparisonControls from './ComparisonControls';

export default function NonSelectedComparisonFilters({
  onChangeComparisonMethod,
  comparisonMethod,
  comparisonPreview,
  setComparisonPreview,
  onChangeComparisonParams,
  onChangePrimaryComparisonParams,
}) {
  const { t } = useTranslation();
  const secondaryFiltersKey = useRef(uuid());
  const comparisonPreviewItems = useMemo(() => {
    return COMPARISON_PREVIEWS.map((preview) => ({
      label: t(preview.name),
      className: 'enabled-link',
      onClick: () => setComparisonPreview(preview),
    }));
  }, [setComparisonPreview]);

  const handlePrimaryComparisonParamsChange = (newFilters) => {
    onChangePrimaryComparisonParams(newFilters);
    secondaryFiltersKey.current = uuid();
  };

  return (
    <>
      <div className="comparison__methods-section">
        <div className="methods-container">
          <p className="m-0">{t('by')}: </p>
          <div className="methods">
            {COMPARISON_METHODS.map((method) => (
              <button
                key={method.id}
                onClick={() => onChangeComparisonMethod(method)}
                className={`method ${
                  comparisonMethod.id === method.id ? 'active-method' : ''
                }`}
              >
                {t(method.name)}
              </button>
            ))}
          </div>
        </div>
        <div className="comparison__preview-type">
          <p className="m-0">{t('compareIn')}</p>
          <Dropdown
            dropdownClass="cxmeter-nav-profile"
            items={comparisonPreviewItems}
          >
            <span className="comparison__preview-dropdown">
              {t(comparisonPreview.name)}
            </span>
          </Dropdown>
        </div>
      </div>

      {isEmpty(comparisonMethod.primaryFilters) ? null : (
        <ComparisonControls
          filterKey="primaryFilters"
          onChangeParams={handlePrimaryComparisonParamsChange}
          comparisonMethod={comparisonMethod}
          isPrimaryFilter
        />
      )}

      <ComparisonControls
        key={secondaryFiltersKey.current}
        onChangeParams={onChangeComparisonParams}
        comparisonMethod={comparisonMethod}
      />
    </>
  );
}
