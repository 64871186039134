import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { OnboardingSurveyPreview } from 'components';
import LocationLinkedExperiences from 'components/locationLinkedExperiences/LocationLinkedExperiences';
import Icon from 'components/ui/icon/Icon';
import { OnboardingBranchContext } from 'contexts';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { BRANCH_SETUP_STEP, URLS } from 'utils/constants';

import '../regOrgaisation/_regOrganisation.scss';
import './_branchFeedbackFormsSetupScreen.scss';

export default function BranchFeedbackFormsSetupScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { branch, setAddedBranch } = useContext(OnboardingBranchContext);

  const {
    surveyDetail: { data: surveyDetailById },
  } = useSelector((s) => s.surveys);

  const [linkedExperiences, setLinkedExperiences] = useState([]);

  const handelRedirectToLocationsScreen = () => {
    setAddedBranch(true);
    navigate(URLS.BRANCH_LIST_URL);
  };

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={BRANCH_SETUP_STEP} />
        <OnboardingLayout.Form
          title={t('onboarding.locationForms.title')}
          subtitle={t('onboarding.locationForms.subTitle')}
        >
          <div className="onboarding-org">
            <Icon type="work" />
            <hgroup>
              <p />
              <h2>{branch?.name}</h2>
            </hgroup>
          </div>
          <div className="expListContainer">
            <LocationLinkedExperiences
              onChangeLinkedExperiences={setLinkedExperiences}
              isOnboarding
            />
          </div>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          disableContinue={!linkedExperiences.length}
          continueText={t('next')}
          onClickBack={() => navigate(`${URLS.BRANCH_SETUP_URL}/${locationId}`)}
          onClickContinue={handelRedirectToLocationsScreen}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        {surveyDetailById ? (
          <OnboardingSurveyPreview isTemplate survey={surveyDetailById} />
        ) : null}
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}
