import { logoutApiCall } from 'apis/authentication';
import store from 'redux/store';
import { LOGOUT_ACTION } from 'utils/constants/reduxActions';

import { getRefreshToken } from './global';

export default function logout({ loggedOut } = {}) {
  if (!loggedOut) {
    const refresh = getRefreshToken();
    logoutApiCall({ refresh });
  }
  localStorage.clear();
  store.dispatch(LOGOUT_ACTION);
}
