import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';

import { Icon } from 'components/ui';
import { SettingsPageContext } from 'contexts';
import SettingsPageContextProvider from 'layouts/settingsPageLayout/SettingsPageContextProvider';
import SettingsPageLayout from 'layouts/settingsPageLayout/SettingsPageLayout';
import { getDropdownLocations } from 'redux/paginatedDropdowns/paginatedDropdownsActions';
import { getUserList } from 'redux/users/usersActions';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { USERS_TABS } from 'utils/constants/settingsPage';
import { attachKeysToDataSource, getAntdPaginationProps } from 'utils/helpers';

import getUserColumns from './columns';
import UserModal from './userModal/UserModal';

import './_userPage.scss';

function UserPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    users: { data: users, loading },
  } = useSelector((state) => state.users);
  const { locations } = useSelector((state) => state.branchComparison);
  const loggedInUser = useSelector((state) => state.authentication.user);

  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState({});

  const { activeTab, params, setParams, onChangePagination } =
    useContext(SettingsPageContext);

  const openModalToEditUser = (userData) => {
    let user = userData ?? {};
    if (userData) {
      const entities = [];
      user.entities.forEach(({ id, name }) => {
        if (id && name) {
          entities.push({ value: id, label: name });
        }
      });
      user = { ...user, entities };
    }
    setEditingUser(user);
    setNewUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setNewUserModalOpen(false);
    setEditingUser({});
  };

  const onChange = (pagination, filters, sorter) => {
    const page = pagination.current !== params.page ? pagination.current : 1;
    const sortBy = sorter?.field ?? params.sort_by;
    const sortOrder = sorter?.order ?? params.sort_order;
    onChangePagination(page, DEFAULT_PAGE_SIZE, sortBy, sortOrder);
  };

  useEffect(() => {
    dispatch(getDropdownLocations());
  }, []);

  useEffect(() => {
    dispatch(getUserList({ ...params }));
  }, [params]);

  return (
    <>
      <SettingsPageLayout
        pageTitle={t('settings.users.title')}
        pageSubTitle={t('settings.users.subTitle')}
        tabs={USERS_TABS}
      >
        {{
          generalAction: activeTab.isActiveSubTab ? null : (
            <div className="controls">
              <button
                className="add-new-user px-4 cstm-btn primary-cstm-btn"
                size="large"
                onClick={() => openModalToEditUser()}
              >
                <Icon style={{ fontSize: 20 }} type="person_add" />
                <span>{t('addUser')}</span>
              </button>
            </div>
          ),
          currentTabContent: (
            <div className="user-list-table">
              <Table
                loading={loading}
                columns={getUserColumns({
                  onEdit: openModalToEditUser,
                  loggedInUser,
                  t,
                })}
                dataSource={attachKeysToDataSource(users.results)}
                onChange={onChange}
                scroll={{ x: true }}
                pagination={{
                  ...getAntdPaginationProps({
                    data: users,
                    currentPage: params.page ?? 1,
                    onChangePagination,
                  }),
                }}
              />
            </div>
          ),
        }}
      </SettingsPageLayout>
      <UserModal
        params={params}
        setParams={setParams}
        open={newUserModalOpen}
        locations={locations.data}
        handleModalClose={handleUserModalClose}
        user={editingUser}
      />
    </>
  );
}

function MangaeUsersList() {
  return (
    <SettingsPageContextProvider tabs={USERS_TABS}>
      <UserPage />
    </SettingsPageContextProvider>
  );
}

export default MangaeUsersList;
