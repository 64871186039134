import { useEffect } from 'react';

import { Typography } from 'antd';
import { get, has } from 'lodash';

import {
  BranchExpFilter,
  BranchListFilter,
  ExpListFilter,
} from 'components/branchExpFilter';
import MultiBranchListFilter from 'components/branchExpFilter/MultiBranchListFilter';
import { DateFilter } from 'components/dateFilter';
import FeebackQuartersFilter from 'components/feebackQuartersFilter/FeebackQuartersFilter';
import { LocationFilter } from 'components/locationFilter';
import QuestionOptionsFilter from 'components/questionOptionsFilter/QuestionOptionsFilter';
import RegionFilter from 'components/regionFilter/RegionFilter';
import { ReviewFilter, StatusFilter } from 'components/reviewsFilters';
import { Searchbar } from 'components/searchbar';
import SurveyQuestionFilter from 'components/surveyQuestionFilter/SurveyQuestionFilter';
import { Icon } from 'components/ui';
import { useFetchFiltersData } from 'utils/hooks';

import {
  BRANCH_EXP_FILTER,
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  FEEDBACK_QUARTERS_FILTER,
  LOCATION_FILTER,
  MULTI_BRANCH_FILTER,
  QUESTION_OPTIONS_FILTER,
  REGION_FILTER,
  REVIEW_FILTER,
  SEARCH_FILTER,
  STATUS_FILTER,
  SURVEY_QUESTION_FILTER,
} from './filterTypes';

import './_filters.scss';

function Filters({
  value,
  addBranchExpFilterWrapper = true,
  doFetchFiltersData = false,
  addMainWrapper = true,
  addControlsWrapper = true,
  onChange,
  disableFilter = [],
  searchPlaceholder,
  showSearchedTextChip = false,
  ...rest
}) {
  const { fetchFiltersData } = useFetchFiltersData();
  const onFilterChange = (key) => (val) => {
    onChange({ ...value, [key]: val });
  };

  useEffect(() => {
    if (doFetchFiltersData) {
      fetchFiltersData(value);
    }
  }, []);

  /* Search bar */
  const SearchFilter = has(value, SEARCH_FILTER) ? (
    <div className="search-filter" style={{ flex: 1, maxWidth: '100%' }}>
      <Searchbar
        value={value[SEARCH_FILTER]}
        onChange={onFilterChange(SEARCH_FILTER)}
        placeholder={searchPlaceholder}
      />
      {value[SEARCH_FILTER] && showSearchedTextChip ? (
        <p className="button button-primary mt-3 search-filter-value">
          <Typography.Text
            style={{ color: 'inherit' }}
            ellipsis={{
              tooltip: {
                title: value[SEARCH_FILTER],
                getPopupContainer: (target) => target.parentElement,
              },
            }}
          >
            {value[SEARCH_FILTER]}
          </Typography.Text>
          <button onClick={() => onFilterChange(SEARCH_FILTER)('')}>
            <Icon type="close" />
          </button>
        </p>
      ) : null}
    </div>
  ) : null;

  const DropdownFilters = (
    <>
      {/* Select Region */}
      {has(value, REGION_FILTER) && (
        <RegionFilter
          value={get(value, REGION_FILTER, {})}
          onChange={onFilterChange(REGION_FILTER)}
          placeholder={rest.regionPlaceholder}
          {...rest}
        />
      )}

      {/* Select Survey */}
      {has(value, SURVEY_QUESTION_FILTER) && (
        <SurveyQuestionFilter
          value={get(value, SURVEY_QUESTION_FILTER, {})}
          onChange={onFilterChange(SURVEY_QUESTION_FILTER)}
          {...rest}
        />
      )}

      {/* Select Question Option */}
      {has(value, QUESTION_OPTIONS_FILTER) && (
        <QuestionOptionsFilter
          value={get(value, QUESTION_OPTIONS_FILTER, {})}
          onChange={onFilterChange(QUESTION_OPTIONS_FILTER)}
          placeholder={rest.questionOptionPlaceholder}
          {...rest}
        />
      )}

      {/* Select Location */}
      {has(value, LOCATION_FILTER) && (
        <LocationFilter
          value={get(value, LOCATION_FILTER, {})}
          onChange={onFilterChange(LOCATION_FILTER)}
        />
      )}

      {has(value, BRANCH_EXP_FILTER) && (
        <BranchExpFilter
          value={get(value, BRANCH_EXP_FILTER)}
          onChange={onFilterChange(BRANCH_EXP_FILTER)}
          addWrapper={addBranchExpFilterWrapper}
          {...rest}
        />
      )}
      {has(value, BRANCH_FILTER) && (
        <BranchListFilter
          value={get(value, BRANCH_FILTER)}
          onChange={onFilterChange(BRANCH_FILTER)}
          placeholder={rest.branchPlaceholder}
          {...rest}
        />
      )}
      {has(value, MULTI_BRANCH_FILTER) && (
        <MultiBranchListFilter
          value={get(value, MULTI_BRANCH_FILTER)}
          onChange={onFilterChange(MULTI_BRANCH_FILTER)}
          disabled={disableFilter.includes(MULTI_BRANCH_FILTER)}
        />
      )}
      {has(value, EXPERIENCE_FILTER) && (
        <ExpListFilter
          value={get(value, EXPERIENCE_FILTER)}
          onChange={onFilterChange(EXPERIENCE_FILTER)}
          placeholder={rest.experiencePlaceholder}
          {...rest}
        />
      )}

      {has(value, REVIEW_FILTER) && (
        <ReviewFilter
          value={get(value, REVIEW_FILTER)}
          onChange={onFilterChange(REVIEW_FILTER)}
          {...rest}
        />
      )}
      {has(value, STATUS_FILTER) && (
        <StatusFilter
          value={get(value, STATUS_FILTER)}
          onChange={onFilterChange(STATUS_FILTER)}
          {...rest}
        />
      )}

      {/* Select Quarter */}
      {has(value, FEEDBACK_QUARTERS_FILTER) && (
        <FeebackQuartersFilter
          value={get(value, FEEDBACK_QUARTERS_FILTER, {})}
          onChange={onFilterChange(FEEDBACK_QUARTERS_FILTER)}
          placeholder={rest.quarterPlaceholder}
          {...rest}
        />
      )}

      {/* Select Date Range */}
      {has(value, DATE_RANGE_FILTER) && (
        <DateFilter
          value={get(value, DATE_RANGE_FILTER)}
          onChange={onFilterChange(DATE_RANGE_FILTER)}
          showText={rest.dateRangePlaceholder}
        />
      )}
    </>
  );

  const DropdownFiltersWrapper = addControlsWrapper ? (
    <div className="controlsWrap">{DropdownFilters}</div>
  ) : (
    DropdownFilters
  );

  return addMainWrapper ? (
    <div className="MainFilterWrap">
      {SearchFilter}
      {DropdownFiltersWrapper}
    </div>
  ) : (
    <>
      {SearchFilter}
      {DropdownFiltersWrapper}
    </>
  );
}
export default Filters;
