import * as Yup from 'yup';

import i18next from 'i18next';
import { CHECKBOX, TEXT } from 'utils/constants/questionTypes';

const getErrorMessage = (errorKey) => {
  const { t } = i18next;
  return t(`settings.payments.cancelPlan.errors.${errorKey}`);
};

const optionSchema = Yup.object().shape({
  option_id: Yup.number(),
  label: Yup.string(),
  selected: Yup.boolean(),
});

const questionSchema = Yup.object()
  .shape({
    label: Yup.string(),
    question_type: Yup.string(),
    options: Yup.array(optionSchema),
    question_uuid: Yup.string(),
    feedback: Yup.string()
      .trim()
      .when('question_type', {
        is: TEXT,
        then: (schema) =>
          schema
            .required(getErrorMessage('requiredFeedback'))
            .min(1, getErrorMessage('emptyFeedback')),
        otherwise: (schema) => schema.notRequired(),
      }),
  })
  .test('checkbox-selected', getErrorMessage('minOption'), (value) => {
    if (value.question_type === CHECKBOX) {
      return value.options.some((option) => option.selected === true);
    }
    return true;
  });

const sectionSchema = Yup.object().shape({
  section_uuid: Yup.string(),
  questions: Yup.array()
    .of(questionSchema)
    .min(1, getErrorMessage('minQuestion')),
});

const cancelPlanSchema = () =>
  Yup.object().shape({
    forms: Yup.object().shape({ sections: Yup.array().of(sectionSchema) }),
  });

export default cancelPlanSchema;
