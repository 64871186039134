import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getInviteTemplatesForEmailModal } from 'redux/emailManagement/emailManagementActions';
import { resetEmailModalInviteTemplates } from 'redux/emailManagement/emailManagementSlice';

function EmailTemplatesList({ apiParams, loadMoreHandler, formik, onConfirm }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    emailModalInviteTemplates: {
      data: { count, results: inviteTemplates },
      loading,
    },
  } = useSelector((s) => s.emailManagement);

  const [selectedTemplate, setSelectedTemplate] = useState();

  const onSelecteTemplate = () => {
    formik.setValues({
      ...formik.values,
      subject: selectedTemplate.name,
      html_message: selectedTemplate.html_message,
      email_json_data: selectedTemplate.email_json_data,
      entity_experience: selectedTemplate.entity_experience,
      updateHTMLEditorState: true,
    });
    onConfirm();
  };

  useEffect(() => {
    dispatch(getInviteTemplatesForEmailModal(apiParams));
  }, [apiParams]);

  useEffect(
    () => () => {
      dispatch(resetEmailModalInviteTemplates());
    },
    [],
  );

  return (
    <>
      <div className="email-tmeplates-overlay">
        <h6 className="text-uppercase">{t('name')}</h6>
        <div className="templates-list">
          {loading && !count ? (
            <p style={{ textAlign: 'center', color: '#de0024', marginTop: 1 }}>
              {t('loadingDot')}
            </p>
          ) : null}
          {inviteTemplates.map((template) => (
            <div
              role="presentation"
              onClick={() => setSelectedTemplate(template)}
              key={template.id}
              className={`template-banner ${
                selectedTemplate?.id === template.id ? 'active' : ''
              }`}
            >
              <div className="radio-input-circle">
                <div />
              </div>
              <p>{template.name}</p>
            </div>
          ))}
        </div>
        {count > inviteTemplates.length ? (
          <button
            className="load-more-btn"
            disabled={loading}
            style={{ opacity: loading ? 0.75 : 1 }}
            onClick={loadMoreHandler}
          >
            {loading ? t('loadingDot') : t('loadMore')}
          </button>
        ) : null}
      </div>
      <button
        disabled={!selectedTemplate}
        style={{ alignSelf: 'flex-end' }}
        className="cstm-btn primary-cstm-btn confirm-overlay-value-btn"
        onClick={onSelecteTemplate}
      >
        {t('confirm')}
      </button>
    </>
  );
}

export default EmailTemplatesList;
