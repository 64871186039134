import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Tag } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { get } from 'lodash';

import { Modal } from 'components/ui';
import { shareQr } from 'redux/qrSetup/qrSetupActions';
import { emailRegexMax128Chars } from 'utils/constants/regex';

import '../_qrSetup.scss';

export default function ShareModal({ qr, onClose }) {
  const { t } = useTranslation();
  const parentEntity = useSelector((s) => s.authentication.parentEntity);
  const { isEmailingQr } = useSelector((s) => s.qrSetup);
  const [form] = useForm();
  const dispatch = useDispatch();
  const [, forceUpdate] = useState(0);

  const onSubmit = (vals) => {
    dispatch(
      shareQr({
        ...vals,
        email_addresses: vals.email_addresses.split(',').map((em) => em.trim()),
      }),
    ).then((action) => {
      if (!action.error) {
        onClose();
      }
    });
  };
  const isEmail = (email = '') => {
    return email.match(emailRegexMax128Chars);
  };

  useEffect(() => {
    form.setFieldsValue({
      body: t('settings.qrSetup.shareQr.prefilledContent', {
        parentEntity: parentEntity.name,
        qrUrl: qr.url,
      }),
    });
  }, [qr]);

  const headerTitle =
    get(qr, 'entity_experience.entity.name', '') &&
    get(qr, 'entity_experience.experience.name', '')
      ? `${get(qr, 'entity_experience.entity.name', '')} - ${get(
          qr,
          'entity_experience.experience.name',
          '',
        )}`
      : t('notLinked');

  return (
    <Modal onClose={onClose} open>
      <Modal.Header
        onClose={onClose}
        title={t('settings.qrSetup.shareQr.shareSurvey')}
      />
      <Modal.Body>
        <Form
          form={form}
          onFinish={onSubmit}
          onChange={() => forceUpdate((v) => !v)}
        >
          <div className="qrDetail">
            {headerTitle ? (
              <p className="text-center">
                <b>{headerTitle}</b>
              </p>
            ) : null}
            <div className="heading mb-30">
              <h3>{t('emails')}</h3>
              <Form.Item
                validateTrigger="onChange"
                rules={[
                  {
                    required: true,
                    message: t('fieldErrors.generic.required'),
                  },
                  {
                    validator: (m, val) => {
                      if (!val) {
                        return Promise.resolve();
                      }
                      const emails = val.split(',');
                      if (emails.length > 5) {
                        return Promise.reject(
                          new Error(
                            t('settings.qrSetup.shareQr.emailError', {
                              count: 5,
                            }),
                          ),
                        );
                      }
                      const invalidEmail = emails.find(
                        (email) => !email || !isEmail(email.trim()),
                      );
                      if (typeof invalidEmail === 'string') {
                        let errorMessage = t(
                          'settings.qrSetup.shareQr.invalidEmail',
                          { email: invalidEmail },
                        );
                        if (invalidEmail.length > 128)
                          errorMessage = t(
                            'settings.qrSetup.shareQr.maxEmailLimit',
                            { email: invalidEmail },
                          );
                        return Promise.reject(new Error(errorMessage));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                name="email_addresses"
              >
                <Input.TextArea
                  placeholder={t('settings.qrSetup.shareQr.emailPlacholder')}
                />
              </Form.Item>
              <div className="mb-30">
                {form
                  .getFieldValue('email_addresses')
                  ?.split(',')
                  .map((email, i) => (
                    <Tag
                      key={i + email.trim()}
                      style={{
                        wordBreak: 'break-all',
                        whiteSpace: 'normal',
                        margin: '3px',
                        ...(!isEmail(email.trim())
                          ? { border: '1px solid red' }
                          : {}),
                      }}
                    >
                      {email.trim()}
                    </Tag>
                  ))}
              </div>

              <h3>Content</h3>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('fieldErrors.generic.required'),
                  },
                ]}
                name="body"
              >
                <Input.TextArea placeholder={t('emailBody')} autoSize />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer
        primaryLabel={t('share')}
        primaryAction={form.submit}
        isLoading={isEmailingQr}
        disablePrimaryAction={isEmailingQr}
        secondaryAction={onClose}
        secondaryLabel={t('back')}
      />
    </Modal>
  );
}
