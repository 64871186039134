import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createOrgAPICall } from 'apis/cxmeter';
import {
  getParentEntitiesList,
  selectParentEntity,
} from 'redux/authentication/authenticationActions';
import { URLS } from 'utils/constants';
import {
  getFullURL,
  showErrorToast,
  toFormData,
  translateToast,
} from 'utils/helpers';

export default function useOrganizationSubmitHandler() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (data) {
      try {
        setLoading(true);
        const formdata = toFormData(data);
        formdata.append(
          'last_onboarding_url',
          getFullURL(URLS.BRANCH_LIST_URL),
        );
        const newOrg = await createOrgAPICall(formdata);
        await dispatch(selectParentEntity(newOrg.id));
        dispatch(getParentEntitiesList());
        toast.success(translateToast('created', 'organization'));
        navigate(URLS.BRANCH_LIST_URL, { replace: true });
      } catch (error) {
        setLoading(false);
        showErrorToast(error);
      }
    }
  };

  return { isLoading, onSubmit };
}
