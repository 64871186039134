import { Fragment } from 'react';

import { markupToText } from 'utils/helpers';

export default function ReviewCheckbox({ question }) {
  return (
    <div className="feedback-checks">
      {question.options.map((option) => (
        <Fragment key={option.option_id}>
          <div>
            <input
              type="checkbox"
              checked={question.answer.includes(option.option_id)}
              disabled
            />
            {markupToText(option.label)}
          </div>
          {option.is_other_option && question.other_text ? (
            <p>{question?.other_text}</p>
          ) : null}
        </Fragment>
      ))}
    </div>
  );
}
