import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import {
  getAllExperiences,
  postExperience,
} from 'redux/experience/experienceActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { customExperienceNameSchema } from 'utils/validations/common';

function ExperienceDropdown({
  selectedExperience,
  handleChangeExperience,
  showCustomExperienceInput = true,
  fetchExperiences = true,
  experienceError,
}) {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const {
    allExperiences: { data: allExperiences, loading },
  } = useSelector((s) => s.experience);
  const [isCustomExperienceLoading, setCustomExperienceLoading] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: customExperienceNameSchema,
    onSubmit: async (v) => {
      setCustomExperienceLoading(true);
      const { data, isError } = await dispatch(
        postExperience({ exp: { name: v.name } }),
        formik,
      );
      if (!isError) {
        handleChangeExperience({ value: data.id, label: data.name });
        dispatch(getAllExperiences());
        formik.resetForm();
      }
      setCustomExperienceLoading(false);
    },
  });

  useEffect(() => {
    if (fetchExperiences) dispatch(getAllExperiences());
  }, [fetchExperiences]);

  return (
    <SelectAndCreateOptionDropdown
      options={allExperiences}
      isPaginationLoading={loading}
      selectedOption={selectedExperience}
      useParentAsPopupContainer
      showSearch
      labelInValue
      onChange={handleChangeExperience}
      newOptionValue={formik.values.name}
      onChangeNewOption={(newOption) => formik.setFieldValue('name', newOption)}
      onAddNewOption={formik.submitForm}
      newOptionError={formik.submitCount ? formik.errors.name : null}
      addOptionPlaceholder={t('customExperience.placeholder')}
      addOptionLabel={t('customExperience.cta')}
      isNewOptionLoading={isCustomExperienceLoading}
      showAddOption={showCustomExperienceInput}
      dropdownError={experienceError}
    />
  );
}

export default ExperienceDropdown;
