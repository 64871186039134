import { isNil } from 'lodash';

import {
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  FEEDBACK_QUARTERS_FILTER,
  QUESTION_OPTIONS_FILTER,
  REGION_FILTER,
  SURVEY_QUESTION_FILTER,
} from 'components/filters/filterTypes';
import {
  getComparisonByBranch,
  getComparisonByDateRange,
  getComparisonByExperience,
  getComparisonByQuestionOptions,
  getComparisonByRegion,
} from 'redux/branchComparison/branchComparisonActions';
import { SENTIMENTS } from 'utils/constants';
import { CHECKBOX, RADIO } from 'utils/constants/questionTypes';

import { stripHtmlTags } from './global';

export const getComparisonParams = ({
  comparisonMethod,
  comparisonByParams,
  comparisonPrimaryParmas,
  comparisonFilters,
} = {}) => {
  const params = {};
  let comparisonAction;

  Object.keys(comparisonPrimaryParmas).forEach((key) => {
    const filter = comparisonPrimaryParmas[key];
    switch (key) {
      case SURVEY_QUESTION_FILTER:
        params.survey_id = filter?.parent?.key;
        params.question_id = filter?.child?.key;
        break;
      default:
        break;
    }
  });

  // To populate current selected filter method.
  comparisonByParams.forEach((filter, index) => {
    switch (comparisonMethod.id) {
      case FEEDBACK_QUARTERS_FILTER:
        // eslint-disable-next-line no-case-declarations
        const { data } = filter;
        params[`date_range[${index * 2}]`] = data.startDate;
        params[`date_range[${index * 2 + 1}]`] = data.endDate;
        comparisonAction = getComparisonByDateRange;
        break;
      case EXPERIENCE_FILTER:
        params[`experience_ids[${index}]`] = filter.value;
        comparisonAction = getComparisonByExperience;
        break;
      case BRANCH_FILTER:
        params[`entity_ids[${index}]`] = filter.value;
        comparisonAction = getComparisonByBranch;
        break;
      case REGION_FILTER:
        params[`regions[${index}]`] = filter.value;
        comparisonAction = getComparisonByRegion;
        break;
      case QUESTION_OPTIONS_FILTER:
        params[`option_ids[${index}]`] = filter.value;
        comparisonAction = getComparisonByQuestionOptions;
        break;
      default:
        break;
    }
  });

  // To populate filters other than current selected filter method.
  Object.keys(comparisonFilters).forEach((key) => {
    const filter = comparisonFilters[key];
    switch (key) {
      case DATE_RANGE_FILTER:
        params['date_range[0]'] = filter?.startDate;
        params['date_range[1]'] = filter?.endDate;
        break;
      case EXPERIENCE_FILTER:
        params.experience_id = filter?.value;
        break;
      case BRANCH_FILTER:
        params.entity_id = filter?.value;
        break;
      case REGION_FILTER:
        params.region = filter?.value;
        break;
      default:
        break;
    }
  });

  return { params, comparisonAction };
};

const getFixedOrNullValue = (value) =>
  isNil(value) ? null : +value.toFixed(1);

const getOptionValue = (option, type) => {
  const { count, percentage, average } = option ?? {};
  const value = type === RADIO || type === CHECKBOX ? count : average;
  return {
    percentage: getFixedOrNullValue(percentage),
    value: getFixedOrNullValue(value),
  };
};

const parseQuickStatsSegments = (
  result,
  segments,
  comparisonIndex,
  comparisonCount,
  t,
) => {
  segments.forEach((segment) => {
    const segmentId = segment.id;
    const value = isNil(segment.count) ? null : +segment.count.toFixed(1);
    const percentage = isNil(segment.percentage)
      ? null
      : +segment.percentage.toFixed(1);
    if (!result[segmentId]) {
      const valuesArray = new Array(comparisonCount).fill(null);
      const percentagesArray = new Array(comparisonCount).fill(null);

      valuesArray[comparisonIndex] = value;
      percentagesArray[comparisonIndex] = percentage;

      result[segmentId] = {
        key: t(segment.name),
        values: valuesArray,
        percentages: percentagesArray,
      };
    } else {
      result[segmentId].values[comparisonIndex] = value;
      result[segmentId].percentages[comparisonIndex] = percentage;
    }
    result[segmentId].sentiment = segment.sentiment;
  });
};

const parseNonBasicComparison = (nonBasicComparison) => {
  const result = [];
  nonBasicComparison.forEach((comparison) => {
    comparison.surveys.forEach((survey) => {
      const surveyData = {
        title: stripHtmlTags(`${comparison.name} • ${survey.survey_title}`),
        data: survey.questions.map((question) => {
          return {
            title: stripHtmlTags(question.label),
            data: question.options.map((option) => {
              const values = [];
              const percentages = [];

              option.comparison.forEach((comp, index) => {
                const compData = Object.values(comp)[0];
                const { value, percentage } = getOptionValue(
                  compData,
                  question.question_type,
                );
                values[index] = value;
                percentages[index] = percentage;
              });
              return {
                key: stripHtmlTags(option.label),
                values,
                percentages,
                sentiment: option.sentiment,
                type: question.question_type,
              };
            }),
          };
        }),
      };

      result.push(surveyData);
    });
  });

  return result;
};

export const parseQuickStats = (quickStatsComparison, t) => {
  const result = {};
  const comparisonCount = quickStatsComparison.length;
  quickStatsComparison.forEach(({ stats: entityQuickStat }, index) => {
    parseQuickStatsSegments(
      result,
      entityQuickStat.segments,
      index,
      comparisonCount,
      t,
    );
  });
  return Object.values(result);
};

const parseGenericComparison = (comparison, nonBasicComparisonKey, t) => {
  const { quick_stats: quickStatsData } = comparison.basic_comparison[0] ?? {};
  const nonBasicComparison = comparison[nonBasicComparisonKey];

  const data = [];
  const parsedQuickStats = parseQuickStats(quickStatsData, t);
  if (parsedQuickStats.length) {
    data.push({
      title: t('quickStats'),
      data: parsedQuickStats,
    });
  }
  const parsedGeneralComparison = {
    title: t('generalComparison'),
    data,
  };

  return [
    parsedGeneralComparison,
    ...parseNonBasicComparison(nonBasicComparison),
  ];
};

const parseExperienceQuestionsComparison = (comparedExperiences, t) => {
  const questionComparison = {};
  const npsComparison = {};
  const experiencesCount = comparedExperiences.length;

  comparedExperiences.forEach((experience, experienceIndex) => {
    const npsStats = experience.nps_stats[0]?.segments ?? [];
    parseQuickStatsSegments(
      npsComparison,
      npsStats,
      experienceIndex,
      experiencesCount,
      t,
    );
    experience.surveys.forEach((survey) => {
      survey.questions.forEach((question) => {
        const questionTitle = stripHtmlTags(question.label).trim();
        const questionData = questionComparison[questionTitle];
        if (questionData) {
          question.options.forEach((option) => {
            const { value, percentage } = getOptionValue(
              option.comparison?.[0],
              question.question_type,
            );
            const key = stripHtmlTags(option.label);
            const { sentiment } = option;
            if (questionData[key]) {
              questionData[key].values[experienceIndex] = value;
              questionData[key].percentages[experienceIndex] = percentage;
            } else {
              const valuesArray = new Array(experiencesCount).fill(null);
              const percentagesArray = new Array(experiencesCount).fill(null);
              valuesArray[experienceIndex] = value;
              percentagesArray[experienceIndex] = percentage;
              questionData[key] = {
                key,
                values: valuesArray,
                percentages: percentagesArray,
                sentiment,
              };
            }
          });
        } else {
          const data = {};
          question.options.forEach((option) => {
            const valuesArray = new Array(experiencesCount).fill(null);
            const percentagesArray = new Array(experiencesCount).fill(null);
            const { value, percentage } = getOptionValue(
              option.comparison?.[0],
              question.question_type,
            );
            const key = stripHtmlTags(option.label);
            const { sentiment } = option;
            valuesArray[experienceIndex] = value;
            percentagesArray[experienceIndex] = percentage;
            data[key] = {
              key,
              values: valuesArray,
              percentages: percentagesArray,
              sentiment,
            };
          });
          questionComparison[questionTitle] = { ...data };
        }
      });
    });
  });

  const result = [];

  const parsedNpsComparison = Object.values(npsComparison);
  if (parsedNpsComparison.length) {
    result.push({
      title: t('generalComparison'),
      data: [
        {
          title: t('quickStats'),
          data: parsedNpsComparison,
        },
      ],
    });
  }

  const parsedQuestions = Object.keys(questionComparison).map((question) => ({
    title: question,
    data: Object.values(questionComparison[question]),
  }));
  if (parsedQuestions.length) {
    result.push({
      data: parsedQuestions,
    });
  }

  return result;
};

const parseExperienceComparison = (comparison, t) => {
  const experienceQuestionsComparison = parseExperienceQuestionsComparison(
    comparison,
    t,
  );
  return experienceQuestionsComparison;
};

const getParsedComparison = (comparison, comparisonMethod, t) => {
  let comparisonResult;
  if (
    comparisonMethod.id === BRANCH_FILTER ||
    comparisonMethod.id === REGION_FILTER ||
    comparisonMethod.id === FEEDBACK_QUARTERS_FILTER
  ) {
    comparisonResult = parseGenericComparison(
      comparison,
      'experience_comparison',
      t,
    );
  } else if (comparisonMethod.id === QUESTION_OPTIONS_FILTER) {
    comparisonResult = parseGenericComparison(
      comparison,
      'entities_comparison',
      t,
    );
  } else if (comparisonMethod.id === EXPERIENCE_FILTER) {
    comparisonResult = parseExperienceComparison(comparison, t);
  }
  return comparisonResult ?? [];
};

const getMinMaxInfo = (arr) => {
  const nonNullValues = arr.filter((v) => v !== null);
  const minValue = Math.min(...nonNullValues);
  const maxValue = Math.max(...nonNullValues);
  const minCount = nonNullValues.filter((v) => v === minValue).length;
  const maxCount = nonNullValues.filter((v) => v === maxValue).length;

  return { minValue, maxValue, minCount, maxCount };
};

const transformData = (inputData) => {
  return inputData.map((category) => ({
    title: category.title,
    data: category.data.map((subCategory) => ({
      title: subCategory.title,
      data: subCategory.data.map((item) => {
        const { key, values, percentages, sentiment } = item;

        const valuesInfo = getMinMaxInfo(values);
        const percentagesInfo = getMinMaxInfo(percentages);

        const updatedValues = values.map((value) => ({
          value,
          isGoodValue:
            sentiment === SENTIMENTS.NEUTRAL ||
            sentiment === SENTIMENTS.NEGATIVE
              ? value === valuesInfo.minValue && valuesInfo.minCount === 1
              : value === valuesInfo.maxValue && valuesInfo.maxCount === 1,
        }));

        const updatedPercentages = percentages.map((percentage) => ({
          value: percentage,
          isGoodValue:
            sentiment === SENTIMENTS.NEUTRAL ||
            sentiment === SENTIMENTS.NEGATIVE
              ? percentage === percentagesInfo.minValue &&
                percentagesInfo.minCount === 1
              : percentage === percentagesInfo.maxValue &&
                percentagesInfo.maxCount === 1,
        }));

        const hasEqualPercentage = !updatedPercentages.find(
          ({ isGoodValue }) => isGoodValue,
        );

        return {
          key,
          values: updatedValues,
          percentages: updatedPercentages,
          hasEqualPercentage,
          sentiment,
        };
      }),
    })),
  }));
};

const parseComparison = (comparison, comparisonMethod, t) => {
  const parsedComparison = getParsedComparison(comparison, comparisonMethod, t);
  return transformData(parsedComparison);
};

export default parseComparison;
