import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';

function SurveyQuestionFilter({ placeholder, ...rest }) {
  const { t } = useTranslation();
  const { data: surveysQuestions } = useSelector(
    (state) => state.surveys.surveysQuestions,
  );

  return (
    <SelectWithCustomSuffixIcon
      currentFilterClassName="survey-question-list-filter"
      options={surveysQuestions}
      parentLabelKey="label"
      childLabelKey="label"
      childrenKey="questions"
      placeholder={placeholder ?? t('filters.select.surveyQuestion')}
      isMultiLevelSelect
      {...rest}
    />
  );
}

export default SurveyQuestionFilter;
