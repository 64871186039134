import { AUTHENTICATION_STATE } from 'redux/authentication/authenticationSlice';

export const RESET_STORE = 'RESET_STORE';
export const ORG_SWITCH_ACTION = { type: RESET_STORE };

export const LOGOUT_ACTION = {
  type: RESET_STORE,
  payload: {
    authentication: {
      ...AUTHENTICATION_STATE,
      isAppLoading: false,
      token: null,
    },
  },
};
