import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { Modal } from 'components/ui';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import {
  createDashboardFilter,
  getDashboardFilters,
} from 'redux/dashboard/dashboardActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { dashboardFilterNameSchema } from 'utils/validations/common';

function CreateNewFiltersModal({ onClose, appliedFilters }) {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const [savingFilter, setSavingFilter] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: dashboardFilterNameSchema,
    onSubmit: async (v) => {
      setSavingFilter(true);
      const { isError } = await dispatch(
        createDashboardFilter({ name: v.name, filter_data: appliedFilters }),
        formik,
      );
      if (!isError) {
        await dispatch(getDashboardFilters());
        formik.resetForm();
        onClose();
      }
      setSavingFilter(false);
    },
  });

  return (
    <Modal onClose={onClose} open className="save-dashboard-filter-modal">
      <Modal.Header onClose={onClose} title={t('saveFilter')} />
      <Modal.Body>
        <FormikLabeledInput
          formik={formik}
          inputName="name"
          label={t('inputFields.name')}
          placeholder={t('dashboard.enterFilterName')}
        />
      </Modal.Body>
      <Modal.Footer
        primaryAction={formik.submitForm}
        primaryLabel={t('saveFilter')}
        disablePrimaryAction={savingFilter}
        isLoading={savingFilter}
        secondaryAction={onClose}
        disableSecondaryAction={savingFilter}
        secondaryLabel={t('discard')}
      />
    </Modal>
  );
}

export default CreateNewFiltersModal;
