import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';

function QuestionOptionsFilter({ placeholder, ...rest }) {
  const { t } = useTranslation();
  const { data: questionOptions, loading } = useSelector(
    (state) => state.surveys.questionOptions,
  );

  return (
    <SelectWithCustomSuffixIcon
      currentFilterClassName="question-options-list-filter"
      id="question-options"
      name="Question Options"
      labelKey="label"
      options={questionOptions}
      isRemoteSearchable={false}
      isPaginationLoading={loading}
      placeholder={placeholder ?? t('filters.select.questionOption')}
      {...rest}
    />
  );
}

export default QuestionOptionsFilter;
