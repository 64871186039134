import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { TABLE_DATA } from 'utils/constants';

import {
  archiveNotificationsAction,
  getNotificationsAction,
  updateNotificationsAction,
} from './notificationActions';

const INITIAL_STATE = {
  notifications: TABLE_DATA,
};

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: INITIAL_STATE,
  reducers: {
    resetNotificationSlice: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationsAction.fulfilled, (state, action) => {
      state.notifications.loading = false;
      const prevNotificationResult = state.notifications.data.results;
      state.notifications.data = {
        ...action.payload,
        results: [
          ...prevNotificationResult,
          ...(action.payload?.results ?? []),
        ],
      };
    });

    builder.addCase(updateNotificationsAction.fulfilled, (state, action) => {
      state.notifications.data.results.forEach((notification) => {
        if (action.meta.arg.mark_read_ids.includes(notification.id)) {
          notification.is_read = true;
        }
      });
      state.notifications.loading = false;
    });

    builder.addCase(archiveNotificationsAction.fulfilled, (state, action) => {
      const targetIndex = state.notifications.results.findIndex(
        ({ id }) => id === action.payload?.id,
      );
      state.notifications.data.results[targetIndex] = action.payload;
      state.notifications.loading = false;
    });

    builder.addMatcher(
      isAnyOf(
        getNotificationsAction.pending,
        updateNotificationsAction.pending,
        archiveNotificationsAction.pending,
      ),
      (state) => {
        state.notifications.loading = true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getNotificationsAction.rejected,
        updateNotificationsAction.rejected,
        archiveNotificationsAction.rejected,
      ),
      (state) => {
        state.notifications.loading = false;
      },
    );

    return builder;
  },
});
export const { resetNotificationSlice } = notificationSlice.actions;
export default notificationSlice;
