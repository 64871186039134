import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import OrganizationSwitcher from 'components/organizationSwitcher/OrganizationSwitcher';
import { FINANCIAL_ADMIN, PARENT_ENTITY_ADMIN } from 'utils/constants';
import { PAYMENT_DASHBOARD_SETTINGS_URL } from 'utils/constants/urlConstants';
import { logout } from 'utils/helpers';

import './_BlockedOrgModal.scss';

export default function BlockedOrgModal() {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.authentication);
  return (
    <div className="blocked__org__modal-container">
      <div className="org__switcher-container">
        <OrganizationSwitcher />
      </div>
      <div className="blocked__org__modal-content">
        <div className="blocked__org__modal-content-body">
          <p>
            {t('blockedOrg.message')}
            &nbsp;
            <a href="mailto: support@cxmeter.io">support@cxmeter.io</a>.
          </p>
        </div>
        <div className="blocked__org__modal-content-footer">
          {user.type === PARENT_ENTITY_ADMIN ||
          user.type === FINANCIAL_ADMIN ? (
            <Link
              className="cstm-btn secondary-cstm-btn"
              to={PAYMENT_DASHBOARD_SETTINGS_URL}
            >
              {t('goToPayment')}
            </Link>
          ) : null}
          <button className="cstm-btn primary-cstm-btn" onClick={logout}>
            {t('logout')}
          </button>
        </div>
      </div>
    </div>
  );
}
