import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { createRegion } from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { getDropdownRegions } from 'redux/paginatedDropdowns/paginatedDropdownsActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import regionValidationSchema from 'utils/validations/locationsAndRegionsValidations';

export default function RegionsDropDown({ error, value, ...rest }) {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const { data: regions, loading } = useSelector(
    (state) => state.paginatedDropdowns.regions,
  );

  const [isAPILoading, setIsAPILoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: regionValidationSchema,
    onSubmit: async (v) => {
      setIsAPILoading(true);
      const { isError } = await dispatch(createRegion({ ...v }), formik);
      if (!isError) {
        dispatch(getDropdownRegions());
        formik.resetForm();
      }
      setIsAPILoading(false);
    },
  });

  useEffect(() => {
    dispatch(getDropdownRegions());
  }, []);

  return (
    <SelectAndCreateOptionDropdown
      options={regions.results}
      paginationData={regions}
      nextDataAction={getDropdownRegions}
      isPaginationLoading={loading}
      newOptionValue={formik.values.name}
      onChangeNewOption={(newOption) => formik.setFieldValue('name', newOption)}
      onAddNewOption={formik.submitForm}
      selectedOption={value || undefined}
      dropdownError={error}
      newOptionError={formik.submitCount ? formik.errors.name : null}
      isNewOptionLoading={isAPILoading}
      addOptionPlaceholder={t('inputFields.regionName')}
      addOptionLabel={t('settings.locations.addRegion')}
      showAddOption
      isRemoteSearchable
      showSearch
      {...rest}
    />
  );
}
