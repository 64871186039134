import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import { Modal } from 'components/ui';
import { updateOrg } from 'redux/settings/settingsActions';
import { translateToast } from 'utils/helpers';
import { billingInfoValidationSchema } from 'utils/validations';

export default function BillingInfoModal({ isOpenModal, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parentEntity = useSelector((s) => s.authentication.parentEntity);
  const isLoading = useSelector((s) => s.settings.isLoading);

  const formik = useFormik({
    initialValues: {
      name: parentEntity.name ?? '',
      email: parentEntity.email ?? '',
    },
    onSubmit: async (values) => {
      dispatch(updateOrg({ ...values, id: parentEntity.id })).then(
        (payload) => {
          if (!payload.error) {
            toast.success(translateToast('updated', 'billingInfo'));
            onClose();
          }
        },
      );
    },
    validateOnChange: true,
    validationSchema: billingInfoValidationSchema,
  });

  const { values, errors, touched, handleChange } = formik;

  return (
    <Modal onClose={onClose} open={isOpenModal}>
      <Modal.Header title={t('billingAddress')} onClose={onClose} />
      <Modal.Body>
        <div className="cxmeter-form">
          <label
            className={`control ${
              touched.name && errors.name ? 'control-invalid' : ''
            }`}
          >
            <span className="required-field">
              {t('name')} <span>*</span>
            </span>
            <input
              disabled
              name="name"
              type="text"
              placeholder={t('name')}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <span className="control-error">{errors.name}</span>
            )}
          </label>
          <label
            className={`control ${
              touched.email && errors.email ? 'control-invalid' : ''
            }`}
          >
            <span className="required-field">
              {t('email')}
              <span>*</span>
            </span>
            <input
              name="email"
              type="text"
              placeholder={t('email')}
              value={values.email}
              onChange={handleChange}
            />
            {touched.email && (
              <span className="control-error">{errors.email}</span>
            )}
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer
        secondaryAction={onClose}
        isLoading={isLoading}
        primaryAction={formik.handleSubmit}
        primaryLabel={t('update')}
      />
    </Modal>
  );
}
