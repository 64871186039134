import {
  allowedNameCharactersRegex,
  containsLettersAndNumberRegex,
  containsLettersRegex,
  emailRegex,
  onlyLettersAndNumbersRegex,
  onlyNumbersRegex,
} from 'utils/constants/regex';

import Yup from './Yup';
import translateFieldError from './validationTranslations';

const t = translateFieldError;

export const getInvalidNameErrorMessage = () =>
  t('invalidSpeciallCharactersName');

export const getEmailError = (email) => {
  let error = '';
  if (!emailRegex.test(email)) error = t('invalidEmail');
  else if ((email ?? '').length > 128) error = t('maxEmailLimit', '', 128);
  return error;
};

export const addressInfoSchema = (optionalFields = {}) => ({
  street_address: Yup.string().requiredTrimmed(
    t('blank', 'address'),
    t('required', 'address'),
  ),
  country: Yup.string()
    .requiredTrimmed(t('blank', 'country'), t('required', 'country'))
    .matches(onlyLettersAndNumbersRegex, t('lettersOnly', 'country')),
  state: Yup.string()
    .requiredTrimmed(t('blank', 'state'), t('required', 'state'))
    .matches(onlyLettersAndNumbersRegex, t('lettersOnly', 'state')),
  city: Yup.string()
    .requiredTrimmed(t('blank', 'city'), t('required', 'city'))
    .matches(onlyLettersAndNumbersRegex, t('lettersOnly', 'city')),
  zip_code: Yup.string()
    .nullable()
    .matches(onlyNumbersRegex, t('numericOnly', 'zipCode'))
    .min(5, t('minLength', 'zipCode', 5))
    .max(9, t('maxLength', 'zipCode', 9))
    .when([], {
      is: () => !optionalFields?.zipCode,
      then: (schema) =>
        schema.requiredTrimmed(t('blank', 'zipCode'), t('required', 'zipCode')),
    }),
});

export const customExperienceNameSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .requiredTrimmed(
        t('blank', 'customExperienceName'),
        t('required', 'customExperienceName'),
      )
      .matches(allowedNameCharactersRegex, getInvalidNameErrorMessage())
      .matches(
        containsLettersAndNumberRegex,
        t('onlySpecialCharactersNotAllowed', 'customExperienceName'),
      )
      .range(
        1,
        128,
        t('minLength', 'customExperienceName', 1),
        t('maxLength', 'customExperienceName', 128),
      ),
  });
};

export const industryTypeNameSchema = customExperienceNameSchema;

export const dashboardFilterNameSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .requiredTrimmed(t('blank', 'filterName'), t('required', 'filterName'))
      .matches(
        onlyLettersAndNumbersRegex,
        t('lettersNumbersOnly', 'filterName'),
      )
      .matches(containsLettersRegex, t('numbersOnlyNotAllowed', 'filterName'))
      .range(
        1,
        128,
        t('minLength', 'filterName', 1),
        t('maxLength', 'filterName', 128),
      ),
  });
};
