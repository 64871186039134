/* eslint-disable camelcase */
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import IndustryTypesDropdown from 'components/industryTypesDropdown/IndustryTypesDropdown';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import Icon from 'components/ui/icon/Icon';
import { getRandomColor } from 'utils/helpers';

import './_organizationForm.scss';

export default function OrganizationForm({
  form,
  isEditing,
  avatarRef,
  isOnboarding,
}) {
  const { t } = useTranslation();
  const colorRef = useRef(getRandomColor());
  const logoRef = useRef();
  const { parentEntity } = useSelector((state) => state.authentication);
  const {
    name = '',
    logo = '',
    industry_type: type = null,
    industry_type_name = '',
    email = '',
    phone_number = '',
  } = parentEntity ?? {};

  const handleChangeIndustryType = ({ value, label }) => {
    form.setFieldValue('type', {
      id: value,
      name: label,
    });
  };

  useEffect(() => {
    if (parentEntity) {
      form.resetForm({
        values: {
          ...form.values,
          name,
          logo,
          type,
          industry_type_name,
          email,
          phone_number,
        },
      });
    }
  }, [parentEntity]);

  return (
    <form
      className={`cxmeter-form update-organization-form ${
        isEditing ? 'small' : ''
      }`}
    >
      <FormikLabeledInput
        formik={form}
        inputName="name"
        placeholder={t('inputFields.organizationName')}
      />
      <FormikLabeledInput
        formik={form}
        inputName="email"
        placeholder={t('inputFields.emailAddress')}
        required={false}
      />
      <FormikLabeledInput
        formik={form}
        inputName="phone_number"
        placeholder={t('inputFields.phoneNumber')}
        required={false}
      />
      {isEditing ? (
        <label className="control">
          <input disabled value={form.values.industry_type_name} />
        </label>
      ) : (
        <IndustryTypesDropdown
          isOnboarding={isOnboarding}
          handleChangeIndustryType={handleChangeIndustryType}
          selectedIndustryType={{
            value: form.values?.type?.id || undefined,
          }}
          dropdownError={form.errors.type}
          placeholder={t('onboarding.orgDetail.type')}
        />
      )}
      <div style={{ order: isEditing ? 1 : 0 }}>
        <h3 className="onboarding-field-title">
          {t('settings.organization.details.logo')}
        </h3>
        <h4 className="onboarding-field-subtitle">
          {t('settings.organization.details.logoSubtitle')}
        </h4>
        <div className="avatarContainer">
          <label className="control image-control">
            <span>
              <Icon type="add" />
              {t('upload')}
            </span>
            <input
              ref={logoRef}
              type="file"
              name="logo"
              accept="image/*"
              onChange={(event) => {
                form.setFieldValue('logo', event.target.files[0]);
              }}
            />
          </label>
          {!form.values.logo && form.values.name && (
            <div
              ref={avatarRef}
              className=" control image-control profile-image"
              style={{ background: `${colorRef.current}` }}
            >
              <p>{_.get(form, 'values.name[0]', '')}</p>
            </div>
          )}
        </div>
        {form.values.logo ? (
          <p className="image-control-name">
            <button
              type="button"
              onClick={() => {
                form.setFieldValue('logo', '');
                logoRef.current.value = '';
              }}
              className="button delete-btn"
            >
              <Icon type="delete" />
            </button>
          </p>
        ) : null}
        {form?.values?.logo && (
          <img
            src={
              form?.values?.logo
                ? typeof form?.values?.logo === 'string'
                  ? `${form?.values?.logo}`
                  : `${URL.createObjectURL(form?.values?.logo)}`
                : ''
            }
            alt="Logo"
            style={{ maxHeight: '4rem' }}
          />
        )}
      </div>
    </form>
  );
}
