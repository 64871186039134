import dayjs from 'dayjs';
import { get } from 'lodash';

import {
  BRANCH_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  REVIEW_FILTER,
  SEARCH_FILTER,
  STATUS_FILTER,
} from 'components/filters/filterTypes';

import { DAILY, MONTHLY, WEEKLY } from '../constants';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return dayjs(date).format('YYYY-MM-DD');
};

export const numberOfMillisecondsInDays = (days) => days * 24 * 60 * 60 * 1000;

export const getEndDate = (startDate, timeline) => {
  if (timeline === DAILY) {
    return startDate;
  }
  const givenDate = dayjs(startDate);
  if (timeline === WEEKLY) {
    return givenDate.add(7, 'day').format('YYYY-MM-DD');
  }
  if (timeline === MONTHLY) {
    return givenDate.endOf('month').format('YYYY-MM-DD');
  }
  return null;
};

export const sortDates = (dates) =>
  dates.sort((a, b) => new Date(b.date) - new Date(a.date));

export const getDateRangeBetweenLabels = (dates) => {
  const [startDate, endDate] = sortDates(dates);
  const oneDay = numberOfMillisecondsInDays(1);

  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffInDays = Math.round(Math.abs((start - end) / oneDay));
  if (diffInDays <= 1) return DAILY;
  if (diffInDays <= 8) return WEEKLY;
  return MONTHLY;
};

const defaultFilters = {
  [STATUS_FILTER]: null,
  [REVIEW_FILTER]: null,
  [BRANCH_FILTER]: {},
  [EXPERIENCE_FILTER]: {},
  [DATE_RANGE_FILTER]: {},
  [SEARCH_FILTER]: '',
};

export const filtersFromQueryParams = (val = {}) => {
  const res = { ...defaultFilters };
  if (val.last_viewed_id) {
    res.last_viewed_id = val.last_viewed_id;
  }
  if (val.sort_order) {
    res.sort_order = val.sort_order;
  }
  if (get(val, SEARCH_FILTER)) {
    res[SEARCH_FILTER] = get(val, [SEARCH_FILTER]);
  }
  if (get(val, 'entity')) {
    res[BRANCH_FILTER] = {
      value: get(val, 'entity'),
      label: get(val, 'entity_name'),
    };
  }
  if (get(val, 'experience')) {
    res[EXPERIENCE_FILTER] = {
      value: get(val, 'experience'),
      label: get(val, 'experience_name'),
    };
  }
  if (get(val, 'segment')) {
    res[REVIEW_FILTER] = {
      value: get(val, 'segment'),
      label: get(val, 'segment_name'),
    };
  }
  if (get(val, 'status')) {
    res[STATUS_FILTER] = {
      value: get(val, 'status'),
      label: get(val, 'status_name'),
    };
  }
  if (get(val, 'start_date') && get(val, 'end_date')) {
    res[DATE_RANGE_FILTER] = {
      startDate: get(val, 'start_date'),
      endDate: get(val, 'end_date'),
      name: get(val, 'range_label'),
    };
  }

  return res;
};
