import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { has, isEmpty } from 'lodash';

import { Filters } from 'components';
import { DATE_RANGE_FILTER } from 'components/filters/filterTypes';
import { Icon } from 'components/ui';

function ComparisonControls({
  comparisonMethod,
  filterKey = 'filters',
  onChangeParams,
  isPrimaryFilter = false,
}) {
  const { t } = useTranslation();
  const populateFilters = () => {
    return comparisonMethod[filterKey];
  };

  const [comparisonFilters, setComparisonFilters] = useState(populateFilters);

  const resetFiltersHandler = () => {
    const filters = populateFilters();
    if (has(filters, DATE_RANGE_FILTER)) {
      filters[DATE_RANGE_FILTER] = {};
    }
    setComparisonFilters({
      ...filters,
    });
  };

  useEffect(() => {
    onChangeParams(comparisonFilters);
  }, [comparisonFilters]);

  useEffect(() => {
    setComparisonFilters(populateFilters);
  }, [comparisonMethod]);

  const showResetBtn = useMemo(
    () =>
      Object.keys(comparisonFilters).length > 1 &&
      Object.values(comparisonFilters).find((filter) => !isEmpty(filter)),
    [comparisonFilters],
  );

  const dateFilterPlaceholder = useMemo(() => {
    if (!comparisonFilters[DATE_RANGE_FILTER]) return t('filters.dateRange');
    const { startDate, endDate } = comparisonFilters[DATE_RANGE_FILTER];
    return startDate && endDate
      ? t('dashboard.dateFilterSubTitle', {
          startDate,
          endDate,
        })
      : t('filters.dateRange');
  }, [comparisonFilters[DATE_RANGE_FILTER]]);

  return (
    <div className="comparison__filters-section">
      <Icon type="filter_alt" />
      <Filters
        addControlsWrapper={false}
        addMainWrapper={false}
        value={comparisonFilters}
        onChange={setComparisonFilters}
        branchPlaceholder={t('location')}
        experiencePlaceholder={t('experience')}
        regionPlaceholder={t('region')}
        dateRangePlaceholder={dateFilterPlaceholder}
        isClearable={!isPrimaryFilter}
        isRoundedDropDown
      />
      {showResetBtn && (
        <button
          type="button"
          onClick={resetFiltersHandler}
          className="comparison__controls-reset"
        >
          {t('filters.resetFilters')}
        </button>
      )}
    </div>
  );
}

export default ComparisonControls;
