import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import DesktopSignupWarning from 'components/ui/modal/SignupDesktopModal';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { forgotPassword } from 'redux/authentication/authenticationActions';
import { URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import Yup from 'utils/validations/Yup';
import translateFieldError from 'utils/validations/validationTranslations';

import './_forgotPassword.scss';

export default function ForgotPassword() {
  const { t } = useTranslation();
  usePageTitle(t('pages.resetPassword'));
  const navigate = useNavigate();
  const { dispatch } = useDispatchWithErrors();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(translateFieldError('required', 'email'))
        .customEmail(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const { isError, data } = await dispatch(
        forgotPassword(values.email),
        formik,
      );
      if (!isError) {
        toast.success(data);
        navigate(URLS.LOGIN_URL);
      }
      setLoading(false);
    },
  });

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header
          showLogoutButton={false}
          showOrgSwitcher={false}
          showStepper={false}
        />
        <OnboardingLayout.Form title={t('forgotPassword.title')}>
          <DesktopSignupWarning pageName="reset your password" />
          <div className="auth-content">
            <p>
              {t('forgotPassword.subTitle')}
              <br />
              {t('forgotPassword.instructions')}
            </p>
            <form onSubmit={formik.handleSubmit} className="auth-form">
              <FormikLabeledInput
                formik={formik}
                type="email"
                inputName="email"
                placeholder={t('inputFields.emailAddress')}
              />
              {formik.errors.nonFieldError ? (
                <span className="control-error">
                  {formik.errors.nonFieldError}
                </span>
              ) : null}
              <button
                aria-busy={loading ? true : null}
                className="button button-primary"
                type="submit"
              >
                {t('forgotPassword.cta')}
              </button>
            </form>
            <span className="askLogin">
              <Trans
                i18nKey="forgotPassword.loginOrSignUp"
                components={{
                  loginLink: <Link to={URLS.LOGIN_URL} />,
                  signupLink: <Link to={URLS.SIGNUP_URL} />,
                }}
              />
            </span>
          </div>
        </OnboardingLayout.Form>
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
