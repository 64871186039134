import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import _ from 'lodash';

import { subscriberPlanApiCall } from 'apis/payments';
import { OrderSummary } from 'components';
import PaymentBillingDetails from 'components/paymentBillingsDetails/PaymentBillingsDetails';
import PaymentCardDetail from 'components/paymentCardDetails/PaymentCardDetails';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { PAYMENT_SETUP_STEP, URLS } from 'utils/constants';
import { showErrorToast } from 'utils/helpers';
import { buildAddress, setCardErrors } from 'utils/helpers/paymentHelpers';
import { paymentAndBillingValidationSchema } from 'utils/validations';

import './_payment.scss';

export default function CheckoutForm() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    countries: { data: countries },
    states: { data: states },
    cities: { data: cities },
  } = useSelector((state) => state.demographics);

  const stripe = useStripe();
  const elements = useElements();
  const { state } = useLocation();
  const navigate = useNavigate();

  const cardNumberElement = elements?.getElement(CardNumberElement);

  const subscribe = async ({
    paymentMethod,
    paymentInfo,
    selectedBranches,
  }) => {
    setLoading(true);
    try {
      await subscriberPlanApiCall(state.plan.id, {
        payment_method_id: paymentMethod.id,
        street_address: paymentInfo.street_address,
        city: parseInt(paymentInfo.city, 10),
        zip_code: paymentInfo.zip_code,
        entity_ids: selectedBranches,
      });
      navigate(URLS.SIGNUP_WELCOME_URL);
    } catch (e) {
      setLoading(false);
      showErrorToast(e);
    }
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: values.cardholderName,
          address: buildAddress(countries, states, cities, {
            countryId: values.country,
            cityId: values.city,
            stateId: values.state,
            zip_code: values.zip_code,
            street_address: values.street_address,
          }),
        },
      });
      if (_.isEmpty(error)) {
        await subscribe({
          paymentMethod,
          paymentInfo: values,
          selectedBranches: state.selectedBranches,
        });
      } else {
        toast.error(error.message);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showErrorToast(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      cardholderName: '',
      street_address: '',
      country: '',
      state: '',
      city: '',
      zip_code: '',
      cvv: '',
      exp: '',
      cardNumber: '',
    },
    validateOnChange: true,
    validateOnBlur: false,
    validate: (v) => {
      let error = {};
      try {
        paymentAndBillingValidationSchema().validateSync(v, {
          abortEarly: false,
        });
      } catch (e) {
        error = _.get(e, 'inner', []).reduce((acc, o) => {
          acc[o.path] = _.get(o, 'errors[0]', null);
          return acc;
        }, {});
      }
      const cardErrors = setCardErrors(elements);
      error = { ...error, ...cardErrors };
      error = _.pickBy(error, (o) => o !== null);
      return error;
    },
    onSubmit,
  });

  if (!state?.plan?.id) return <Navigate to={URLS.PAYMENT_PLANS_URL} />;

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={PAYMENT_SETUP_STEP} />
        <OnboardingLayout.Form
          title={t('onboarding.paymentDetails.title')}
          subtitle={t('onboarding.paymentDetails.subTitle')}
        >
          <Form onSubmit={formik.handleSubmit}>
            <span className="form-section-header">
              {t('onboarding.paymentDetails.cardDetails')}
            </span>
            <PaymentCardDetail formik={formik} />
            <span className="form-section-header">
              {t('onboarding.paymentDetails.billingDetails')}
            </span>
            <PaymentBillingDetails formik={formik} />
          </Form>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          isLoading={loading}
          onClickBack={() => navigate(-1)}
          onClickContinue={formik.handleSubmit}
          continueText={t('next')}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        <OrderSummary plan={state.plan} costs={state.costs} />
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}
