import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import dayjs from 'dayjs';

import { entityExperienceQRCodesCreateAPICall } from 'apis/cxmeter';
import { Loader } from 'components';

import { axios } from '../../apis/utils';

import './_generateQRs.scss';

axios.defaults.withCredentials = true;

function GenerateQRs() {
  const { t } = useTranslation();
  const [qrChar, setQRChar] = useState('');
  const [noOfQRs, setNoOfQRs] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCharCodeChange = (event) => {
    // eslint-disable-next-line no-underscore-dangle
    const _qrChar = event.target.value;
    if (/[a-zA-Z]/.test(_qrChar)) {
      setQRChar(_qrChar);
    } else if (!_qrChar) {
      setQRChar(_qrChar);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = await entityExperienceQRCodesCreateAPICall({
        count: noOfQRs,
        qr_char: qrChar,
      });
      axios({
        url: data.download_link,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `QRCodes-${dayjs().format(
          'ddd, MMM DD, YYYY hh:mm:ss A',
        )}.pdf`;
        a.click();
      });
    } catch (error) {
      toast.error(error.message ? error.message : error);
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="preAppQRWrap">
      <div className="holder">
        <div className="contentMainWrap">
          <div className="logo">
            <img src="/assets/images/appLogo.svg" alt="logo" width={120} />
          </div>
          <h1>{t('generateQRCodes')}</h1>
          <Form className="MainQRWrap" onSubmit={handleSubmit}>
            <div className="controlsWrap">
              <Form.Control
                type="text"
                maxLength={1}
                placeholder={t('QRCharacter')}
                value={qrChar}
                onChange={handleCharCodeChange}
              />
            </div>
            <div className="controlsWrap">
              <Form.Control
                type="number"
                placeholder={t('qrCodeCount')}
                min={1}
                value={noOfQRs}
                required
                onChange={(event) => setNoOfQRs(event.target.value)}
              />
            </div>
            <div className="controlsWrap">
              <div className="boxSHadow FilterButtonWrap Print">
                <button className="button button-primary" type="submit">
                  <img alt="QR Button" src="./assets/icons/icon_qr-code.png" />
                  <span>{t('generate')}</span>{' '}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default GenerateQRs;
