import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import {
  createIndustryType,
  getIndustryTypes,
} from 'redux/settings/settingsActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import { industryTypeNameSchema } from 'utils/validations/common';

function IndustryTypesDropdown({
  selectedIndustryType,
  handleChangeIndustryType,
  showCustomIndustryTypeInput = true,
  fetchIndustryTypes = true,
  isOnboarding,
  ...rest
}) {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const {
    industryTypes: { data: industryTypes, loading },
  } = useSelector((state) => state.settings);
  const [isIndustryTypeLoading, setIndustryTypeLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: industryTypeNameSchema,
    onSubmit: async (v) => {
      setIndustryTypeLoading(true);
      const { data, isError } = await dispatch(
        createIndustryType({ name: v.name }),
        formik,
      );
      if (!isError) {
        handleChangeIndustryType({ value: data.id, label: data.name });
        dispatch(getIndustryTypes());
        formik.resetForm();
      }
      setIndustryTypeLoading(false);
    },
  });

  useEffect(() => {
    if (fetchIndustryTypes)
      dispatch(getIndustryTypes({ is_onboarding: isOnboarding }));
  }, [fetchIndustryTypes]);

  return (
    <SelectAndCreateOptionDropdown
      options={industryTypes}
      isPaginationLoading={loading}
      selectedOption={selectedIndustryType}
      useParentAsPopupContainer
      showSearch
      labelInValue
      onChange={handleChangeIndustryType}
      newOptionValue={formik.values.name}
      onChangeNewOption={(newOption) => formik.setFieldValue('name', newOption)}
      onAddNewOption={formik.submitForm}
      newOptionError={formik.submitCount ? formik.errors.name : null}
      addOptionPlaceholder={t('onboarding.orgDetail.custom.placholder')}
      addOptionLabel={t('onboarding.orgDetail.custom.add')}
      isNewOptionLoading={isIndustryTypeLoading}
      showAddOption={showCustomIndustryTypeInput}
      {...rest}
    />
  );
}

export default IndustryTypesDropdown;
