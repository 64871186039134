import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Modal } from 'components/ui';
import { deleteDashboardFilterById } from 'redux/dashboard/dashboardActions';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

function ManageFiltersModal({ onClose }) {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const {
    savedFiltersLoading,
    savedFilters: { data: savedFilters },
  } = useSelector((state) => state.dashboard);

  const handleDeleteFilter = (filter) => {
    dispatch(deleteDashboardFilterById(filter.id));
  };

  useEffect(() => {
    if (!savedFilters.length) onClose();
  }, [savedFilters]);

  return (
    <Modal onClose={onClose} open className="manage-dashboard-filters-modal">
      <Modal.Header onClose={onClose} title={t('manageFilters')} />
      <Modal.Body>
        <div className="saved-filters-list">
          {savedFilters.map((f) => (
            <div key={f.id}>
              <span>{f.name}</span>
              <button
                aria-busy={savedFiltersLoading[f.id] ? true : null}
                className="cstm-btn tertiary-cstm-btn"
                onClick={() => handleDeleteFilter(f)}
                disabled={savedFiltersLoading[f.id]}
              >
                {savedFiltersLoading[f.id] ? null : (
                  <img src="/assets/icons/icon_bin.svg" alt="Delete" />
                )}
                <span>{t('delete')}</span>
              </button>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ManageFiltersModal;
