import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SEARCH_FILTER } from 'components/filters/filterTypes';
import { SettingsPageContext } from 'contexts';
import { PAGINATION_AND_SORTING_PARAMS, URLS } from 'utils/constants';

function SettingsPageContextProvider({ tabs, children }) {
  const navigate = useNavigate();
  const subTabDataRef = useRef();
  const settingsLayoutContainerRef = useRef();
  const { pathname, state } = useLocation();
  const [searchFilter, setSearchFilter] = useState({
    [SEARCH_FILTER]: '',
  });
  const [params, setParams] = useState({
    ...PAGINATION_AND_SORTING_PARAMS,
    search: '',
  });
  const [subTabsSearch, setSubTabsSearch] = useState({
    [SEARCH_FILTER]: '',
  });
  const [activeTab, setActiveTab] = useState({});
  const [activeSubTab, setActiveSubTab] = useState(null);

  const subTabHandler = ({
    isActive,
    heading,
    subHeading,
    hideSearchFilter,
    updateActiveTab = false,
  } = {}) => {
    if (!isActive) {
      navigate(activeTab.route);
    } else if (updateActiveTab) {
      setActiveSubTab((p) => ({
        ...p,
        heading: heading ?? p?.heading,
        subHeading: subHeading ?? p?.subHeading,
        hideSearchFilter: hideSearchFilter ?? p?.hideSearchFilter,
      }));
    } else {
      subTabDataRef.current = { heading, subHeading, hideSearchFilter };
    }
  };

  // To sync the search params key with Backend requirement.
  const onChangeSearch = useCallback(
    (searchValue) => {
      const search = searchValue[SEARCH_FILTER];
      setParams((p) => ({ ...p, page: 1, search }));
      setSearchFilter(searchValue);
    },
    [setSearchFilter],
  );

  const onChangePagination = useCallback(
    (page, pageSize, sortBy, sortOrder) => {
      setParams((p) => ({
        ...p,
        page: page ?? p.page,
        page_size: pageSize ?? p.page_size,
        sort_by: sortBy ?? p.sort_by,
        sort_order: sortOrder ?? p.sort_order,
      }));
    },
    [setParams],
  );

  const isPathMatchingOrNested = (basePath, pathToCheck) => {
    const regex = new RegExp(`^${basePath}(\\/|$)`);
    return regex.test(pathToCheck);
  };

  useEffect(() => {
    const currentTab = tabs.find((tab) =>
      isPathMatchingOrNested(tab.route, pathname),
    );

    if (!currentTab) {
      navigate(URLS.CUSTOM_FEEDBACK_FORMS_SETTINGS_URL);
    } else if (currentTab.route === pathname.replace(/\/$/, '')) {
      setActiveTab(currentTab);
      setActiveSubTab(null);
    } else {
      setActiveTab({
        ...currentTab,
        isActiveSubTab: true,
      });
      const firstSubTab = currentTab.subTabs?.[0] ?? {};
      const activeSubTabData = {
        ...firstSubTab,
        ...(subTabDataRef.current ?? {}),
      };
      setActiveSubTab(activeSubTabData);
      subTabDataRef.current = null;
    }
  }, [pathname]);

  useEffect(() => {
    if (state?.settingsContextParams) {
      setParams(state.settingsContextParams);
    }
  }, []);

  const contextValues = useMemo(
    () => ({
      searchFilter,
      setSearchFilter: onChangeSearch,
      subTabsSearch,
      setSubTabsSearch,
      params,
      setParams,
      onChangePagination,
      activeTab,
      setActiveTab,
      activeSubTab,
      setActiveSubTab,
      subTabHandler,
      settingsLayoutContainerRef,
    }),
    [
      searchFilter,
      onChangeSearch,
      subTabsSearch,
      setSubTabsSearch,
      params,
      setParams,
      onChangePagination,
      activeTab,
      setActiveTab,
      activeSubTab,
      setActiveSubTab,
      subTabHandler,
      settingsLayoutContainerRef,
    ],
  );

  return (
    <SettingsPageContext.Provider value={contextValues}>
      {children}
    </SettingsPageContext.Provider>
  );
}

export default SettingsPageContextProvider;
