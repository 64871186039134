/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash';

import { LineChart } from 'components/charts';
import { t } from 'i18next';
import {
  getHapinessOverTime,
  getHapinessOverTimeByEntity,
} from 'redux/dashboard/dashboardActions';
import { DAILY, MONTHLY, WEEKLY } from 'utils/constants';
import { reviewHelper } from 'utils/helpers';

import './_statisticsGraph.scss';

const filtersLabelsFormat = {
  [DAILY]: 'DD MMM YY',
  [WEEKLY]: 'DD MMM YY',
  [MONTHLY]: 'MMM YY',
};

function HappinessOverTimeGraph({
  filters,
  redirectToReviewsPage,
  breakdownBranches = null,
  showBreakDownButton = true,
  title,
}) {
  const [filter, setFilter] = useState(DAILY);
  const [showBranchBreakdown, setShowBranchBreakdown] = useState(
    breakdownBranches !== null,
  );
  const [showBranchBreakdownBtn, setShowBranchBreakdownBtn] = useState(true);
  const [showWeeklyFilter, setShowWeeklyFilter] = useState(true);
  const [showDailyFilter, setShowDailyFilter] = useState(true);
  const [showMonthlyFilter, setShowMonthlyFilter] = useState(true);
  const { getEndDate } = reviewHelper;
  const { isLoading, data } = useSelector(
    (state) => state.dashboard.happinessOverTime,
  );
  const dispatch = useDispatch();

  const handleChangeFilter = (_filter) => {
    if (_filter !== filter) {
      setFilter(_filter);
    }
  };

  const handleShowBranchBreakdown = () => {
    if (showBranchBreakdown) {
      dispatch(getHapinessOverTime(filters));
    } else {
      dispatch(getHapinessOverTimeByEntity(filters));
    }
    setShowBranchBreakdown(!showBranchBreakdown);
  };

  const parseData = () => {
    if (showBranchBreakdown && Array.isArray(data)) {
      let branchData = data;
      if (!isEmpty(breakdownBranches)) {
        branchData = data.filter((d) => breakdownBranches.includes(`${d.id}`));
      }
      return branchData?.reduce((acc, i) => {
        Object.entries(i.trends[filter] ?? {}).forEach(([key, value]) => {
          if (!acc[key]) {
            acc[key] = {};
          }
          // acc[key][i.name] = value.happiness_index;
          acc[key][i.name] = {
            happiness_index: value.happiness_index,
            total: value.total,
          };
        });
        return acc;
      }, {});
    }

    // filters daily, weekly, or monthly data
    return get(data, filter, {});
  };

  const getEntityId = (name) =>
    data.find((entity) => entity.name.toLowerCase() === name.toLowerCase())?.id;

  const handleOnChartClick = ({ label, datasetLabel }) => {
    const startDate = dayjs(label, filtersLabelsFormat[filter]).format(
      'YYYY-MM-DD',
    );
    const searchParams = {
      start_date: startDate,
      end_date: getEndDate(startDate, filter),
    };
    if (showBranchBreakdown) searchParams.entity = getEntityId(datasetLabel);

    redirectToReviewsPage(searchParams);
  };

  useEffect(() => {
    if (filters?.entity) {
      setShowBranchBreakdownBtn(false);
      setShowBranchBreakdown(false);
    } else setShowBranchBreakdownBtn(true);

    if (!filters?.entity && showBranchBreakdown) {
      dispatch(getHapinessOverTimeByEntity(filters));
    } else {
      dispatch(getHapinessOverTime(filters));
    }

    setShowDailyFilter(true);
    setShowWeeklyFilter(true);
    setShowMonthlyFilter(true);
  }, [filters]);

  useEffect(() => {
    if (filters['date_range[0]'] && filters['date_range[1]']) {
      const startDate = dayjs(filters['date_range[0]'], 'YYYY-MM-DD');
      const endDate = dayjs(filters['date_range[1]'], 'YYYY-MM-DD');
      const differenceInDays = endDate.diff(startDate, 'day');
      const months = Math.abs(startDate.diff(endDate, 'months'));
      if (months < 1) setShowMonthlyFilter(false);
      if (differenceInDays > 30) setShowDailyFilter(false);
      if (months >= 12) setShowWeeklyFilter(false);
      let currentFilter = MONTHLY;
      if (months < 6 && months >= 1) {
        currentFilter = WEEKLY;
      }
      if (differenceInDays <= 30) {
        currentFilter = DAILY;
      }
      setFilter(currentFilter);
    }
  }, [filters]);

  return (
    <div className="happinessOverTimeContainer">
      <div className="statHolder">
        <div className="titleBtnsBlock">
          <h3>{title ?? t('dashboard.happinessTrend')}</h3>
          <ul className="filterBtns">
            {showDailyFilter && (
              <li>
                <span
                  onClick={() => handleChangeFilter(DAILY)}
                  className={filter === DAILY ? 'active' : ''}
                  tabIndex={0}
                  role="button"
                >
                  {t('daily')}
                </span>
              </li>
            )}
            {showWeeklyFilter && (
              <li>
                <span
                  onClick={() => handleChangeFilter(WEEKLY)}
                  className={filter === WEEKLY ? 'active' : ''}
                  tabIndex={0}
                  role="button"
                >
                  {t('weekly')}
                </span>
              </li>
            )}
            {showMonthlyFilter && (
              <li>
                <span
                  onClick={() => handleChangeFilter(MONTHLY)}
                  className={filter === MONTHLY ? 'active' : ''}
                  tabIndex={0}
                  role="button"
                >
                  {t('monthly')}
                </span>
              </li>
            )}
            {showBranchBreakdownBtn && showBreakDownButton && (
              <li className="breakdownBtnBlock">
                <button
                  type="button"
                  onClick={() => handleShowBranchBreakdown()}
                  className={showBranchBreakdown ? 'active' : ''}
                >
                  {t('dashboard.locationBreakdown')}
                </button>
              </li>
            )}
          </ul>
        </div>
        {isLoading ? (
          <div className="spinnerContainer">
            <Spinner style={{ color: '#de0024' }} />
          </div>
        ) : (
          <div className="graphBody">
            <LineChart
              data={parseData()}
              onClick={handleOnChartClick}
              xLabel={t('date')}
              yLabel={t('happiness')}
              datalabels={{
                display: true,
                align: 'center',
                anchor: 'center',
                color: 'black',
              }}
              nps={data?.nps_benchmark}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default HappinessOverTimeGraph;
