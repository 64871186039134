import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import {
  SurveyBuilder,
  SurveyCustomerDetail,
  SurveyEditedModal,
} from 'components';
import ExperienceDropdown from 'components/experienceDropdown/ExperienceDropdown';
import SimilarityConfirmModal from 'components/surveyBuilder/surveyBuilderAnswers/SimilarityConfirmModal';
import SurveyPreviewModal from 'components/surveyPreviewModal/SurveyPreviewModal';
import { Spinner } from 'components/ui';
import { SettingsPageContext } from 'contexts';
import { resetExperienceSlice } from 'redux/experience/experienceSlice';
import {
  createEntitySurvey,
  getEntitySurveyById,
  updateEntitySurvey,
} from 'redux/surveys/surveysActions';
import { setSurveyDetail } from 'redux/surveys/surveysSlice';
import { PAGINATION_AND_SORTING_PARAMS } from 'utils/constants';
import { getInitialSurvey } from 'utils/constants/surveyConstants';
import { showErrorToast } from 'utils/helpers';
import {
  cleanSurvey,
  convertToRawSurvey,
  validateSurvey,
} from 'utils/helpers/surveyHelpers';
import { useScreenshot } from 'utils/hooks';
import surveySchema from 'utils/validations/surveyValidations';

import './_FeedbackFormSurveyDetail.scss';

function SurveyDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const duplicateOptionsSections = useRef({});

  const {
    surveyDetail: { data: surveyDetailById, loading },
    isLoading,
  } = useSelector((s) => s.surveys);

  const { id: surveyId } = useParams();
  const { redirectParams: { next, preSelectedExperience } = {} } =
    location.state ?? {};

  const formik = useFormik({
    initialValues: {},
    validationSchema: surveySchema,
  });
  const surveyDetail = formik.values;

  const previewRef = useRef(null);
  const [, takeScreenshot] = useScreenshot();
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [questionsEdited, setQuestionsEdited] = useState(false);
  const [updatingSurvey, setupdatingSurvey] = useState(false);
  const [linkEditedQuestions, setLinkEditedQuestions] = useState(null);
  const [isOpenEditedModal, setIsOpenEditedModal] = useState(false);
  const [isOpenSimilarityModal, setIsOpenSimilarityModal] = useState(false);
  const [isSimilarErrorActive, setIsSimilarErrorActive] = useState(false);

  const { setParams, subTabHandler } = useContext(SettingsPageContext);

  const handleCloseModal = () => {
    setIsOpenEditedModal(false);
    setupdatingSurvey(false);
  };

  const handlePrimaryAction = () => {
    setLinkEditedQuestions(true);
    setQuestionsEdited(false);
    handleCloseModal();
  };

  const handleSecondaryAction = () => {
    setLinkEditedQuestions(false);
    setQuestionsEdited(false);
    handleCloseModal();
  };

  const closePreviewModal = () => {
    setIsOpenPreview(false);
  };

  const handleSurveyTitleChange = (event) => {
    formik.setFieldValue('title', event.target.value);
  };

  const handleExperienceChange = ({ value, label }) => {
    formik.setFieldValue('experience', {
      id: value,
      name: label,
    });
  };

  const redirectToParent = (extraPayload) => {
    if (!isEmpty(extraPayload.error)) return;
    if (next) {
      navigate(next, {
        state: location.state,
      });
    } else {
      if (surveyId === 'new') {
        setParams({ ...PAGINATION_AND_SORTING_PARAMS });
      }
      subTabHandler({
        isActive: false,
      });
    }
  };

  const updateSurvey = async (cleanedSurvey, preview, experienceId) => {
    if (!questionsEdited || linkEditedQuestions !== null) {
      dispatch(
        updateEntitySurvey({
          surveyId,
          survey: {
            ...cleanedSurvey,
            preview,
            experience_id: experienceId,
            link_edited_questions: linkEditedQuestions,
          },
        }),
      ).then(redirectToParent);
    }
  };

  const checkSurveyValidity = async () => {
    const surveyErrors = await formik.validateForm();
    if (!isEmpty(surveyErrors) || !isEmpty(duplicateOptionsSections.current)) {
      return false;
    }
    return validateSurvey(surveyDetail);
  };

  const saveSurvey = async () => {
    const isSurveyValid = await checkSurveyValidity();
    if (!isSurveyValid || !previewRef.current) return;
    const experienceId = surveyDetail?.experience?.id;
    const isTemplate = surveyDetail?.is_template;
    const cleanedSurvey = cleanSurvey(surveyDetail, surveyId && !isTemplate);
    const preview = await takeScreenshot(previewRef.current);

    try {
      const surveyData = {
        experience: experienceId,
        survey: { ...cleanedSurvey, preview },
      };
      if (surveyId !== 'new' && !isTemplate) {
        setupdatingSurvey(true);
        updateSurvey(cleanedSurvey, preview, experienceId);
      } else {
        dispatch(createEntitySurvey(surveyData)).then(redirectToParent);
      }
    } catch (e) {
      showErrorToast(e);
    }
  };

  const handleOnSaveSurvey = async () => {
    setIsOpenSimilarityModal(false);
    saveSurvey();
  };

  const checkSimilarityError = async () => {
    const isSurveyValid = await checkSurveyValidity();
    if (!isSurveyValid) {
      return;
    }
    if (isSimilarErrorActive) {
      setIsOpenSimilarityModal(true);
    } else {
      handleOnSaveSurvey();
    }
  };

  useEffect(() => {
    if (surveyId !== 'new') {
      dispatch(getEntitySurveyById(surveyId));
    } else {
      const preSelectedExp = preSelectedExperience ?? null;
      formik.resetForm({
        values: {
          ...getInitialSurvey(),
          experience: preSelectedExp,
        },
      });
    }
    return () => {
      dispatch(setSurveyDetail(null));
      dispatch(resetExperienceSlice());
    };
  }, [surveyId]);

  useEffect(() => {
    if (surveyDetailById) {
      formik.resetForm({
        values: convertToRawSurvey(structuredClone(surveyDetailById)),
      });
    }
  }, [surveyDetailById]);

  useEffect(() => {
    if (linkEditedQuestions != null) {
      saveSurvey();
    }
  }, [linkEditedQuestions]);

  useEffect(() => {
    if (questionsEdited && updatingSurvey) {
      setIsOpenEditedModal(true);
    }
  }, [updatingSurvey]);

  const showLoading = isLoading || loading;

  return (
    <div
      className="feedback-form-detail-container"
      style={{ opacity: showLoading ? 0.5 : 1 }}
    >
      {showLoading ? <Spinner isTransparentBackground /> : null}
      <SurveyEditedModal
        onClose={handleCloseModal}
        isOpenModal={isOpenEditedModal}
        primaryAction={handlePrimaryAction}
        secondaryAction={handleSecondaryAction}
      />
      <div className="selected-survey-header">
        <div className="selected-survey-heading">
          <div>
            <input
              type="text"
              value={surveyDetail?.title || ''}
              onChange={handleSurveyTitleChange}
              className="surveyFormTitle"
              placeholder={t('formBuilder.surveyTitle')}
            />
            {formik.errors.title ? (
              <p className="control-error">{formik.errors.title}</p>
            ) : (
              <p>{t('formBuilder.surveySubtitle')}</p>
            )}
          </div>
        </div>
        <div className="builder-actions">
          <button
            type="button"
            className="cstm-btn primary-cstm-btn"
            onClick={checkSimilarityError}
          >
            {t('formBuilder.save')}
          </button>
          <button
            type="button"
            className="cstm-btn secondary-cstm-btn"
            onClick={() => setIsOpenPreview(true)}
          >
            {t('formBuilder.preview')}
          </button>
        </div>
      </div>
      <div style={{ flexGrow: 1, overflow: 'auto' }}>
        <div>
          <h1 className="m-0 h5">{t('formBuilder.customExperience.title')}</h1>
          <p className="m-0">{t('formBuilder.customExperience.subTitle')}</p>
        </div>
        <div className="experience-dropdown">
          <ExperienceDropdown
            selectedExperience={{
              value: parseInt(surveyDetail?.experience?.id, 10) || undefined,
            }}
            handleChangeExperience={handleExperienceChange}
            experienceError={formik.errors.experience}
          />
        </div>
        {surveyDetail?.sections && (
          <>
            <SurveyBuilder
              surveySections={surveyDetail.sections}
              setQuestionsEdited={setQuestionsEdited}
              setIsSimilarErrorActive={setIsSimilarErrorActive}
              formik={formik}
              duplicateOptionsSections={duplicateOptionsSections}
            />
            <SurveyCustomerDetail formik={formik} />
            <SurveyPreviewModal
              isOpenModal={isOpenPreview}
              survey={surveyDetail}
              previewRef={previewRef}
              onClose={closePreviewModal}
            />
          </>
        )}
        <SimilarityConfirmModal
          onClose={() => setIsOpenSimilarityModal(false)}
          isOpenModal={isOpenSimilarityModal}
          primaryAction={handleOnSaveSurvey}
          secondaryAction={() => setIsOpenSimilarityModal(false)}
        />
      </div>
    </div>
  );
}
export default SurveyDetails;
