import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Pagination } from 'antd';

import { Filters } from 'components/filters';
import { SEARCH_FILTER } from 'components/filters/filterTypes';
import { SurveyThumbnail } from 'components/surveyThumbnail';
import { Spinner } from 'components/ui';
import {
  deleteEntitySurvey,
  duplicateSurvey,
} from 'redux/surveys/surveysActions';
import { PAGINATION_AND_SORTING_PARAMS } from 'utils/constants';
import { getAntdPaginationProps, translateToast } from 'utils/helpers';
import { useEffectAfterMount } from 'utils/hooks';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

function ExperienceFormListing({
  linkedExperience,
  setLinkedExperience,
  getDataAction,
  navigateToFeedbackFormDetailPage,
  isTemplate,
}) {
  const { t } = useTranslation();
  const { dispatch } = useDispatchWithErrors();
  const {
    [isTemplate ? 'templateSurveyForms' : 'customSurveyForms']: {
      data: surveyForms,
      loading,
    },
  } = useSelector((s) => s.surveys);

  const linkedExperienceId = linkedExperience?.experience.id;
  const [actionsLoading, setIsActionsLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState({
    [SEARCH_FILTER]: '',
  });
  const [params, setParams] = useState(PAGINATION_AND_SORTING_PARAMS);

  const handleChangeSearchFilter = (value) => {
    setSearchFilter(value);
    setParams({ ...PAGINATION_AND_SORTING_PARAMS });
  };

  const handleSurveyDetailPageNavigation = (surveyId) => {
    navigateToFeedbackFormDetailPage(surveyId, isTemplate);
  };

  const onChangePagination = (page, pageSize, sortBy, sortOrder) => {
    setParams((p) => ({
      ...p,
      page: page ?? p.page,
      page_size: pageSize ?? p.page_size,
      sort_by: sortBy ?? p.sort_by,
      sort_order: sortOrder ?? p.sort_order,
    }));
  };

  const handleDeleteSurvey = async (survey) => {
    setIsActionsLoading(true);
    const { isError } = await dispatch(deleteEntitySurvey(survey.survey_id));
    if (!isError) {
      await dispatch(
        getDataAction({
          ...params,
          experience_id: linkedExperienceId,
        }),
      );
      toast.success(translateToast('deleted', 'survey'));
    }
    setIsActionsLoading(false);
  };

  const handleDuplicateSurvey = async (survey) => {
    setIsActionsLoading(true);
    const { isError, data } = await dispatch(duplicateSurvey(survey.survey_id));
    if (!isError && data) {
      // eslint-disable-next-line no-underscore-dangle
      handleSurveyDetailPageNavigation(data?._id.$oid);
    }
    setIsActionsLoading(false);
  };

  const handleEditSurvey = (survey) => {
    handleSurveyDetailPageNavigation(survey.survey_id);
  };

  const handleSelectSurvey = (survey) => {
    setLinkedExperience((p) => ({
      ...p,
      survey_id: survey.survey_id,
    }));
  };

  useEffect(() => {
    if (linkedExperienceId) {
      const queryParams = {
        experience_id: linkedExperienceId,
        search: searchFilter[SEARCH_FILTER],
        ...params,
      };
      dispatch(getDataAction(queryParams));
    }
  }, [params, searchFilter[SEARCH_FILTER]]);

  useEffectAfterMount(() => {
    if (linkedExperienceId) {
      setSearchFilter({
        [SEARCH_FILTER]: '',
      });
      setParams({ ...PAGINATION_AND_SORTING_PARAMS });
    }
  }, [linkedExperienceId]);

  const noResultFound = surveyForms.results.length < 1 && !loading;
  const isLoading = actionsLoading || loading;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position: 'relative',
        opacity: isLoading ? 0.5 : 1,
      }}
    >
      {isLoading ? <Spinner isTransparentBackground /> : null}
      <Filters
        value={searchFilter}
        addMainWrapper={false}
        onChange={handleChangeSearchFilter}
      />
      {noResultFound ? (
        <div className="no-result-found" style={{ textAlign: 'center' }}>
          <img src="/assets/icons/icon_noResultFound.svg" alt="No Data" />
          <h3>{t('noResultFound')}</h3>
        </div>
      ) : surveyForms.results.length ? (
        <>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            {surveyForms.results.map((survey) => (
              <SurveyThumbnail
                onChange={handleSelectSurvey}
                selected={survey.survey_id === linkedExperience?.survey_id}
                key={survey.survey_id}
                icon={survey.experience_icon}
                onEdit={() => handleEditSurvey(survey)}
                data={survey}
                {...(isTemplate
                  ? {}
                  : {
                      onDelete: () => handleDeleteSurvey(survey),
                      onCopy: () => handleDuplicateSurvey(survey),
                    })}
              />
            ))}
          </div>
          <Pagination
            style={{ alignSelf: 'flex-end', color: 'black' }}
            {...getAntdPaginationProps({
              data: surveyForms,
              currentPage: params.page ?? 1,
              onChangePagination,
            })}
          />
        </>
      ) : null}
    </div>
  );
}

export default ExperienceFormListing;
