import { useState } from 'react';

import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';

import { Icon } from 'components/ui';

import DateSelector from './DateSelector';

import './_dateFilter.scss';
import 'bootstrap/dist/css/bootstrap.css';

function DateFilter({ value, onChange, showText }) {
  const [showSelector, setShowSelector] = useState(false);

  const togglePicker = () => {
    setShowSelector(!showSelector);
  };

  const onDateChange = (val) => {
    onChange(val);
    togglePicker();
  };

  return (
    <Popover
      content={<DateSelector value={value} onChange={onDateChange} />}
      trigger="click"
      open={showSelector}
      onOpenChange={setShowSelector}
      overlayClassName="datePopOver"
    >
      <div className="dropdown">
        <button
          className={`calendar-btn ${showText ? 'calendar-btn-text' : ''}`}
          id="dropdown-basic"
          type="button"
          onClick={togglePicker}
        >
          {showText ? (
            <>
              <span>{showText}</span>
              <FontAwesomeIcon icon={faCaretDown} />
            </>
          ) : (
            <Icon type="calendar_month" />
          )}
        </button>
      </div>
    </Popover>
  );
}

export default DateFilter;
