/* eslint-disable camelcase */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { get, isEmpty } from 'lodash';

import {
  ConfigurationPanel,
  HappinessOverTimeGraph,
  Loader,
  QuickStats,
} from 'components';
import DashboardFilters from 'components/dashboard/filters/DashboardFilters';
import {
  BRANCH_EXP_FILTER,
  DATE_RANGE_FILTER,
} from 'components/filters/filterTypes';
import {
  HappinessTrendBlurPreview,
  PieChartBlurPreview,
} from 'components/statBlurWrapper';
import StatBlurWrapper from 'components/statBlurWrapper/StatBlurWrapper';
import { getDashboardFilters } from 'redux/dashboard/dashboardActions';
import { REVIEWS_LISTING_URL } from 'utils/constants/urlConstants';
import { getFilters, renameAndOmitKeys } from 'utils/helpers';
import { getDashboardDefaultDateRange } from 'utils/helpers/dashboardHelpers';
import { usePageTitle } from 'utils/hooks';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

import Widgets from './components/widgets/Widgets';
import WidgetsPreview from './components/widgets/WidgetsPreview';

import './_dashboard.scss';

export const REGION_FILTER_KEY = 'region';
export const BRANCH_FILTER_KEY = 'branch';
export const EXPERIENCE_FILTER_KEY = 'experience';

export const DAHSBOARD_FILTER = {
  [DATE_RANGE_FILTER]: {},
  [BRANCH_EXP_FILTER]: {},
};

function Dashboard() {
  const navigate = useNavigate();
  const { dispatch } = useDispatchWithErrors();
  const { t } = useTranslation();
  const dashboardRef = useRef();

  const [filters, setFilters] = useState({
    ...DAHSBOARD_FILTER,
    [DATE_RANGE_FILTER]: getDashboardDefaultDateRange(),
  });
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const { parentEntity, user, isLoading } = useSelector(
    (state) => state.authentication,
  );
  const { allowed_analytics: { analytics } = {} } = parentEntity ?? {};

  usePageTitle(t('pages.dashboard'));

  const parsedFiltersData = useMemo(() => {
    const parsedFilters = {};
    if (!isEmpty(filters[DATE_RANGE_FILTER])) {
      parsedFilters['date_range[0]'] = filters[DATE_RANGE_FILTER].startDate;
      parsedFilters['date_range[1]'] = filters[DATE_RANGE_FILTER].endDate;
    }
    if (
      !isEmpty(filters, `${filters[BRANCH_EXP_FILTER]?.[BRANCH_FILTER_KEY]}`)
    ) {
      parsedFilters.entity = get(
        filters,
        `${[BRANCH_EXP_FILTER]}.${[BRANCH_FILTER_KEY]}.value`,
      );
    }
    if (
      !isEmpty(filters, `${filters[BRANCH_EXP_FILTER]?.[REGION_FILTER_KEY]}`)
    ) {
      parsedFilters.region = get(
        filters,
        `${[BRANCH_EXP_FILTER]}.${[REGION_FILTER_KEY]}.value`,
      );
    }
    if (
      !isEmpty(
        filters,
        `${filters[BRANCH_EXP_FILTER]?.[EXPERIENCE_FILTER_KEY]}`,
      )
    ) {
      parsedFilters.experience = get(
        filters,
        `${[BRANCH_EXP_FILTER]}.${[EXPERIENCE_FILTER_KEY]}.value`,
      );
    }
    return parsedFilters;
  }, [filters]);

  const redirectToReviewsPage = (params = {}) => {
    const searchParams = {
      ...renameAndOmitKeys(getFilters(parsedFiltersData), {}),
      ...params,
    };
    if (!searchParams.start_date && !searchParams.end_date) {
      const last30DaysDateRange = getDashboardDefaultDateRange();
      searchParams.start_date = last30DaysDateRange.startDate;
      searchParams.end_date = last30DaysDateRange.endDate;
    }
    navigate({
      pathname: REVIEWS_LISTING_URL,
      search: createSearchParams(searchParams).toString(),
    });
  };

  useEffect(() => {
    dispatch(getDashboardFilters());
  }, []);

  return !isLoading ? (
    <main ref={dashboardRef}>
      <DashboardFilters
        setIsConfigOpen={setIsConfigOpen}
        dashboardRef={dashboardRef}
        filters={filters}
        setFilters={setFilters}
      />
      <section className="chartsWrapper">
        <StatBlurWrapper
          showStat={Boolean(analytics?.segment_analysis)}
          previewUrl={PieChartBlurPreview}
          alt="Quick Stats Preview"
        >
          <QuickStats
            filters={parsedFiltersData}
            redirectToReviewsPage={redirectToReviewsPage}
          />
        </StatBlurWrapper>
        <StatBlurWrapper
          showStat={Boolean(analytics?.nps_trend)}
          previewUrl={HappinessTrendBlurPreview}
          alt="NPS Trend Preview"
        >
          <HappinessOverTimeGraph
            filters={parsedFiltersData}
            redirectToReviewsPage={redirectToReviewsPage}
          />
        </StatBlurWrapper>
      </section>
      {analytics?.per_question_widgets || user?.is_superuser ? (
        <div>
          <ConfigurationPanel
            isOpen={isConfigOpen}
            setIsOpen={setIsConfigOpen}
          />
          <Widgets
            analytics={analytics}
            filters={parsedFiltersData}
            redirectToReviewsPage={redirectToReviewsPage}
          />
        </div>
      ) : (
        <WidgetsPreview />
      )}
    </main>
  ) : (
    <Loader />
  );
}

export default Dashboard;
