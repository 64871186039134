import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  hapinessOverTimeAPICall,
  hapinessOverTimeByEntityAPICall,
  questionAnalyticsAPICall,
  quickStatsAPICall,
} from 'apis/analytics';
import {
  getConfigWidgetsAPICall,
  setConfigWidgetsAPICall,
} from 'apis/configurations';
import {
  createDashboardFilterApiCall,
  createHelpItemAPICall,
  deleteDashboardFilterByIdApiCall,
  getDashboardFiltersApiCall,
} from 'apis/cxmeter';
import { showErrorToast } from 'utils/helpers';

export const getHapinessOverTime = createAsyncThunk(
  'dashboard/getHapinessOverTime',
  async (params) => hapinessOverTimeAPICall(params).catch(showErrorToast),
);

export const getQuickStatsGraph = createAsyncThunk(
  'dashboard/getQuickStatsGraph',
  async (params) => quickStatsAPICall(params).catch(showErrorToast),
);

export const getQuestionsAnalytics = createAsyncThunk(
  'dashboard/getQuestionsAnalytics',
  async ({ questionId, filters }) => {
    try {
      return await questionAnalyticsAPICall(questionId, filters);
    } catch (error) {
      showErrorToast(error);
      throw error;
    }
  },
);

export const getConfigWidgets = createAsyncThunk(
  'dashboard/getConfigWidgets',
  async () => getConfigWidgetsAPICall().catch(showErrorToast),
);

export const postConfigWidgets = createAsyncThunk(
  'dashboard/postConfigWidgets',
  async (data) => setConfigWidgetsAPICall(data).catch(showErrorToast),
);

export const getHapinessOverTimeByEntity = createAsyncThunk(
  'dashboard/getHapinessOverTimeByEntity',
  async (params) =>
    hapinessOverTimeByEntityAPICall(params).catch(showErrorToast),
);

export const createHelpItem = createAsyncThunk(
  'dashboard/createHelpItem',
  async (params, { rejectWithValue }) =>
    createHelpItemAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const getDashboardFilters = createAsyncThunk(
  'dashboard/getDashboardFilters',
  async (data, { rejectWithValue }) =>
    getDashboardFiltersApiCall().catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const createDashboardFilter = createAsyncThunk(
  'dashboard/createDashboardFilter',
  async (data, { rejectWithValue }) =>
    createDashboardFilterApiCall(data).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const deleteDashboardFilterById = createAsyncThunk(
  'dashboard/deleteDashboardFilterById',
  async (data, { rejectWithValue }) =>
    deleteDashboardFilterByIdApiCall(data)
      .then(() => {})
      .catch((e) => showErrorToast(e, rejectWithValue)),
);
