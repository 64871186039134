import { useState } from 'react';

import { DEVICES, DEVICE_PREVIEWS } from 'utils/constants';

import PreviewSizeControls from './PreviewSizeControls';
import SurveyPreviewForm from './SurveyPreviewForm';

import './_surveyPreview.scss';

function OnboardingSurveyPreview({ survey, innerRef, isTemplate = false }) {
  const [selectedDevice, setSelectedDevice] = useState(
    DEVICE_PREVIEWS[DEVICES.MOBILE],
  );

  return (
    <div className="onboarding-survey-preview">
      <div className={`survey-device-preview ${selectedDevice?.className}`}>
        <SurveyPreviewForm
          isTemplate={isTemplate}
          survey={survey}
          innerRef={innerRef}
        />
      </div>
      <PreviewSizeControls
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
      />
    </div>
  );
}

export default OnboardingSurveyPreview;
