import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { populateFormikErrors } from 'utils/helpers';

const useDispatchWithErrors = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState({});
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const dispatchAction = useCallback(
    async (
      mainAction,
      formik,
      { nextAction, params, shouldAwait = true, actionKey } = {},
    ) => {
      if (actionKey) {
        setLoading((prev) => ({ ...prev, [actionKey]: true }));
      }
      try {
        const { error, payload, meta } = await dispatch(mainAction);
        const { data } = payload?.response ?? {};
        if (!error && nextAction) {
          if (shouldAwait) await dispatch(nextAction(params));
          else dispatch(nextAction(params));
        }
        if (error && data && formik) {
          populateFormikErrors(data, formik);
        }
        if (isMounted.current && actionKey) {
          setLoading((prev) => ({ ...prev, [actionKey]: false }));
        }
        return { isError: Boolean(error), data: payload, meta };
      } finally {
        if (isMounted.current && actionKey) {
          setLoading((prev) => ({ ...prev, [actionKey]: false }));
        }
      }
    },
    [dispatch],
  );

  return { dispatch: dispatchAction, loading };
};

export default useDispatchWithErrors;
