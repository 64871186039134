import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PreviewSizeControls from 'components/surveyBuilder/surveyPreview/PreviewSizeControls';
import SurveyPreviewForm from 'components/surveyBuilder/surveyPreview/SurveyPreviewForm';
import { Modal } from 'components/ui';
import { DEVICES, DEVICE_PREVIEWS } from 'utils/constants';

import './_SurveyPreviewModal.scss';

const { MOBILE } = DEVICES;

export default function SurveyPreviewModal({
  isOpenModal,
  survey,
  previewRef,
  onClose,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);

  const [selectedDevice, setSelectedDevice] = useState(DEVICE_PREVIEWS[MOBILE]);

  useEffect(() => {
    if (previewRef && ref.current) {
      previewRef.current = ref.current;
    }
  });

  return (
    <Modal
      open={isOpenModal}
      onClose={onClose}
      className="survey-preview-modal"
    >
      <Modal.Header title={t('formBuilder.preview')} onClose={onClose} />
      <Modal.Body>
        <div className={`survey-device-preview ${selectedDevice?.className}`}>
          <SurveyPreviewForm survey={survey} innerRef={ref} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PreviewSizeControls
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
        />
      </Modal.Footer>
    </Modal>
  );
}
