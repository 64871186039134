import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';

import { chartHelper } from 'utils/helpers';

import colors from 'scss/_customVariables.scss';

const { externalTooltipHandlerBarChart } = chartHelper;
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

const options = {
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    tooltip: {
      enabled: false,
      external: externalTooltipHandlerBarChart,
    },
    datalabels: {
      display: (context) => context.datasetIndex === 0,
      color: 'black',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

/**
 *
 * @param { object } statistics
 * @returns { JSX } The Bar Chart
 */

export default function BarChart({ statistics }) {
  const { t } = useTranslation();
  const labels = [];
  const happyData = [];
  const neutralData = [];
  const unhappyData = [];
  const happinessIndexData = [];
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in statistics) {
    labels.push(key);
    happyData.push(statistics[key].total_happy_feedbacks);
    neutralData.push(statistics[key].total_neutral_feedbacks);
    unhappyData.push(statistics[key].total_unhappy_feedbacks);
    happinessIndexData.push(statistics[key].happiness_index);
  }

  const data = {
    labels,
    datasets: [
      {
        label: t('happyIndex'),
        type: 'line',
        data: happinessIndexData,
        borderWidth: 1,
        borderColor: 'black',
        backgroundColor: 'black',
        fill: true,
        datalabels: {
          align: 'top',
          anchor: 'start',
        },
      },
      {
        type: 'bar',
        label: t('unhappy'),
        data: unhappyData,
        backgroundColor: colors.danger,
      },
      {
        type: 'bar',
        label: t('neutral'),
        data: neutralData,
        backgroundColor: colors.secondary,
      },
      {
        type: 'bar',
        label: t('happy'),
        data: happyData,
        backgroundColor: colors.primary,
        borderRadius: 8,
      },
    ],
  };
  return _.isEmpty(data) ? (
    <span>{t('noDataFound')}</span>
  ) : (
    <Bar options={options} data={data} plugins={[ChartDataLabels]} />
  );
}
