import { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Table } from 'antd';
import { get, isEmpty, isNil, omit, some } from 'lodash';
import qs from 'qs';

import { Filters } from 'components';
import { SEARCH_FILTER } from 'components/filters/filterTypes';
import ReviewPanel from 'components/reviewsListing/ReviewPanel';
import { getReviews } from 'redux/reviewsListing/reviewsListingActions';
import { OPTION_TYPE_MAPPING } from 'utils/constants/questionTypes';
import {
  attachKeysToDataSource,
  getAntdPaginationProps,
  getFilters,
} from 'utils/helpers';
import { filtersFromQueryParams } from 'utils/helpers/reviewsHelpers';
import { useEffectAfterMount, usePageTitle } from 'utils/hooks';
import useOrdering from 'utils/hooks/useOrdering';

import { reviewListingColumns } from './reviewsListingColumns';

import './_reviewsListing.scss';

function ReviewsListing() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [filterStore, setFilterStore] = useState(() => {
    const params = Object.fromEntries(searchParams);
    return filtersFromQueryParams(params);
  });
  const [showDetailPanel, setShowDetailPanel] = useState(false);
  const [currentReview, setCurrentReview] = useState(null);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(null);
  const [showReviewPanelNav, setShowReviewPanelNav] = useState(true);
  const [pageControl, setPageControl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    reviewsList: { data: reviewsList, loading },
  } = useSelector((state) => state.reviews);
  const [ordering, updateSorting, getOrderingForTable] = useOrdering([]);

  usePageTitle(t('pages.feedbacks'));

  const onView = (index) => {
    setCurrentReview(reviewsList.results[index].id);
    setCurrentReviewIndex(index);
    setShowReviewPanelNav(true);
    setShowDetailPanel(true);
  };

  const onFilterChange = (val) => {
    navigate(
      {
        search: qs.stringify({ ...getFilters(val), last_viewed_id: null }),
      },
      { replace: true },
    );
  };

  const parseExtras = (pl) => {
    let res = {};
    if (pl.type) {
      res = {
        [OPTION_TYPE_MAPPING[pl.type]]: pl.option_id,
        ...omit(pl, [
          'entity',
          'experience',
          'segment',
          'status',
          'start_date',
          'end_date',
          'option_id',
          'type',
        ]),
      };
    }
    if (pl.from_word_cloud) {
      res.from_word_cloud = true;
    }
    return res;
  };

  const renameDateKeys = (filtersObj) => {
    if (get(filtersObj, 'start_date') && get(filtersObj, 'end_date')) {
      filtersObj['date_range[0]'] = get(filtersObj, 'start_date');
      filtersObj['date_range[1]'] = get(filtersObj, 'end_date');
    }
    if (get(filtersObj, [SEARCH_FILTER])) {
      filtersObj.search = get(filtersObj, [SEARCH_FILTER]);
    }
    return omit(filtersObj, ['start_date', 'end_date', [SEARCH_FILTER]]);
  };

  const onSortChange = (pagination, filters, sorter) => {
    updateSorting(sorter);
  };

  useEffectAfterMount(() => {
    const params = Object.fromEntries(searchParams);
    setPageControl(null);
    setFilterStore({ ...filterStore, ...filtersFromQueryParams(params) });
  }, [searchParams]);

  useEffect(() => {
    const allFilters = {
      ...parseExtras(Object.fromEntries(searchParams)),
      ...renameDateKeys(getFilters(filterStore)),
    };
    if (!Object.fromEntries(searchParams).last_viewed_id) {
      delete allFilters.last_viewed_id;
    }
    dispatch(
      getReviews({
        ordering: `${ordering.join(',')}`,
        ...pageControl,
        ...allFilters,
      }),
    );
  }, [filterStore, ordering, pageControl]);

  useEffect(() => {
    if (showDetailPanel) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [showDetailPanel]);

  useEffect(() => {
    const openFeedbackId = searchParams.get('openFeedback');
    if (openFeedbackId) {
      setCurrentReview(openFeedbackId);
      setShowReviewPanelNav(false);
      setShowDetailPanel(true);
    }
  }, [searchParams]);

  const filtersApplied = useMemo(() =>
    some(filterStore, (value) => !isEmpty(value) && !isNil(value)),
  );

  return (
    <main>
      <section className="header">
        <h1>{t('feedbacks.title')}</h1>
      </section>

      <section className="filters-section dashboard-and-reviews-page-filters">
        <div className="filters-container">
          <div className="filter-title">
            <p> {t('filters.title')}</p>
            {filtersApplied ? (
              <button
                className="cstm-btn text-cstm-btn"
                onClick={() => onFilterChange({})}
              >
                {t('resetAll')}
              </button>
            ) : null}
          </div>
          <Filters
            isRoundedDropDown
            value={filterStore}
            doFetchFiltersData
            onChange={onFilterChange}
            searchPlaceholder={t('searchTextRes')}
          />
        </div>
      </section>

      <section className="tableWrapper">
        <Table
          loading={loading && { indicator: <Spinner /> }}
          columns={reviewListingColumns(onView, getOrderingForTable(ordering))}
          scroll={{ x: 'max-content' }}
          dataSource={attachKeysToDataSource(reviewsList.results)}
          onChange={onSortChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => onView(rowIndex),
            };
          }}
          pagination={{
            ...getAntdPaginationProps({
              data: reviewsList,
              currentPage: pageControl?.page ?? 1,
              onChangePagination: (page, pageSize) =>
                setPageControl({ page, size: pageSize }),
            }),
          }}
          className="feedback-table"
        />

        <ReviewPanel
          isOpen={showDetailPanel}
          setIsOpen={setShowDetailPanel}
          reviewId={currentReview}
          reviewIndex={currentReviewIndex}
          totalReviews={reviewsList.results.length}
          handleView={onView}
          showNav={showReviewPanelNav}
        />
      </section>
    </main>
  );
}

export default ReviewsListing;
