import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Input, Typography } from 'antd';
import { includes, toLower } from 'lodash';
import List from 'rc-virtual-list';

import { URLS } from 'utils/constants';
import useOrgSwitcher from 'utils/hooks/useOrgSwitcher';

import EntityLogo from './EntityLogo';
import OrganizationCard from './OrganizationCard';

import './_organizationSwitcher.scss';

const { Text } = Typography;

function OrganizationSwitcher({ showOrgLogo = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentication = useSelector((state) => state.authentication);
  const {
    parentEntity,
    parentEntities: { data: parentEntities },
  } = authentication;

  const [open, setOpen] = useState(false);
  const [searchOrg, setSearchOrg] = useState('');

  const { switchOrg, switchingOrgData } = useOrgSwitcher();

  const handleCreateNewOrg = () => {
    navigate(URLS.REGISTER_ORG_URL);
  };

  const filteredParentEntities = useMemo(() => {
    return parentEntities.filter(
      (entity) =>
        entity?.id !== parentEntity?.id &&
        (!searchOrg || includes(toLower(entity.name), toLower(searchOrg))),
    );
  }, [searchOrg, parentEntity?.id, parentEntities]);

  if (parentEntities?.length <= 1 && !showOrgLogo) return null;
  if (parentEntities?.length <= 1)
    return <EntityLogo entity={parentEntity} isSelectedParentEntity />;

  const items = [
    {
      key: '1',
      label: (
        <div
          className="dropdown-content"
          role="presentation"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-3 selected-org">
            <EntityLogo entity={parentEntity} />
            <Text ellipsis={{ tooltip: { title: parentEntity?.name } }}>
              <span className="title">{parentEntity?.name}</span>
            </Text>
          </div>
          <div className="p-3 search-org-wrapper">
            <p className="m-0 switch-org-title">{t('switchOrg')}</p>
            <Input
              className="org-search-input"
              prefix={<SearchOutlined style={{ color: '#de0024' }} />}
              onChange={({ target: { value } }) => setSearchOrg(value)}
              value={searchOrg}
            />
          </div>
          <div className="org-list-container">
            <List
              data={filteredParentEntities}
              height={355}
              itemHeight={71}
              aria-disabled
              itemKey="id"
            >
              {(org) => (
                <OrganizationCard
                  setOpen={setOpen}
                  org={org}
                  switchOrg={switchOrg}
                  switchingOrgData={switchingOrgData}
                />
              )}
            </List>
          </div>
          <div className="p-3">
            <button
              className="cstm-btn secondary-cstm-btn"
              onClick={handleCreateNewOrg}
            >
              <PlusOutlined />
              <span>{t('addAccount')}</span>
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      overlayClassName="organization-switcher-dropdown"
      trigger={['click']}
      open={open}
      onOpenChange={setOpen}
      placement="bottomLeft"
    >
      <div className="organization-switcher-trigger">
        <EntityLogo entity={parentEntity} isSelectedParentEntity />
        <DownOutlined style={{ marginLeft: 8, color: '#de0024' }} />
      </div>
    </Dropdown>
  );
}

export default OrganizationSwitcher;
