import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';

import SelectAndCreateOptionDropdown from 'components/selectAndCreateOptionDropdown/SelectAndCreateOptionDropdown';
import { Modal } from 'components/ui';
import FormikLabeledInput from 'components/ui/formikLabeledInput/formikLabeledInput';
import { LocationAndRegionContext } from 'pages/locationsAndRegions/LocationsAndRegions';
import {
  createRegion,
  getLocationWithoutRegions,
  getRegionById,
  getRegions,
  updateRegionById,
} from 'redux/locationsAndRegions/locationsAndRegionsActions';
import {
  resetLocationsWithoutRegions,
  resetRegion,
} from 'redux/locationsAndRegions/locationsAndRegionsSlice';
import { translateToast } from 'utils/helpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';
import regionValidationSchema from 'utils/validations/locationsAndRegionsValidations';

import './_regions.scss';

function RegionModal() {
  const { t } = useTranslation();
  const regionModalRef = useRef();
  const { dispatch } = useDispatchWithErrors();
  const {
    locationsWithoutRegions: { data: locationsWithoutRegions, loading },
    region: { data: region, loading: regionDataLoading },
  } = useSelector((s) => s.locationsAndRegions);

  const { setIsOpenRegionModal, regionDataRef, params } = useContext(
    LocationAndRegionContext,
  );
  const regionId = regionDataRef.current;

  const [isAPILoading, setIsAPILoading] = useState(false);
  const availableLocationsDict = useRef({});

  const onClose = () => {
    regionDataRef.current = null;
    dispatch(resetRegion());
    dispatch(resetLocationsWithoutRegions());
    setIsOpenRegionModal(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      locations: [],
    },
    validationSchema: regionValidationSchema,
    onSubmit: async (v) => {
      setIsAPILoading(true);
      let isError = false;
      if (regionId) {
        const response = await dispatch(
          updateRegionById({ id: regionId, data: { ...v } }),
          formik,
        );
        isError = response.isError;
      } else {
        const response = await dispatch(createRegion({ ...v }), formik);
        isError = response.isError;
      }
      if (!isError) {
        toast.success(
          translateToast(regionId ? 'updated' : 'created', 'region'),
        );
        onClose();
        dispatch(getRegions(params));
      }
      setIsAPILoading(false);
    },
  });
  const { values, errors, touched } = formik;

  const onChangeSelectedLocations = (locationsIds) => {
    formik.setFieldValue('locations', locationsIds);
  };

  const removeLocationHandler = (locationIndex) => {
    const locationsIds = [...values.locations];
    locationsIds.splice(locationIndex, 1);
    formik.setFieldValue('locations', locationsIds);
  };

  useEffect(() => {
    if (region && region.id === regionId) {
      const selectedLocationsIds = region.locations.map((loc) => loc.id);
      formik.resetForm({
        values: { ...region, locations: selectedLocationsIds },
      });
    }
  }, [region]);

  useEffect(() => {
    if (regionId) {
      dispatch(getRegionById(regionId));
    }
    dispatch(getLocationWithoutRegions());
  }, [regionId]);

  const availableLocations = useMemo(() => {
    const locationsList = [
      ...(region?.locations ?? []),
      ...(locationsWithoutRegions ?? []),
    ];
    const locationsListDict = {};
    locationsList.forEach((loc) => {
      locationsListDict[loc.id] = loc;
    });
    availableLocationsDict.current = locationsListDict;
    return locationsList;
  }, [region?.locations, locationsWithoutRegions]);

  return (
    <Modal
      className="region-modal"
      onClose={onClose}
      open
      modalRef={regionModalRef}
      isLoading={regionDataLoading || isAPILoading}
    >
      <Modal.Header
        title={`${regionId ? t('update') : t('add')} ${t('region')}`}
        onClose={onClose}
      />
      <Modal.Body>
        <FormikLabeledInput
          formik={formik}
          inputName="name"
          placeholder={t('inputFields.regionName')}
          label={t('name')}
        />
        <div className="days-dropdown">
          <p className="m-0">{t('attachedLocations')}</p>
          <SelectAndCreateOptionDropdown
            selectMode="multiple"
            options={availableLocations}
            isPaginationLoading={loading}
            selectedOption={values.locations}
            dropdownError={touched.locations && errors.locations}
            onChange={onChangeSelectedLocations}
            popupContainerRef={regionModalRef.current}
            useParentAsPopupContainer={false}
            placeholder={t('attachLocations')}
          />
        </div>
        <div className="selected-items-list">
          {values.locations.map((selectedLocationId, index) => (
            <span
              className="selected-item-chip"
              key={selectedLocationId}
              type="button"
            >
              <span className="name">
                {availableLocationsDict.current[selectedLocationId]?.name}
              </span>
              <FontAwesomeIcon
                onClick={() => removeLocationHandler(index)}
                icon={faRemove}
              />
            </span>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer
        disablePrimaryAction={isAPILoading || regionDataLoading}
        isLoading={isAPILoading}
        primaryAction={formik.submitForm}
        primaryLabel={regionId ? t('update') : t('create')}
      />
    </Modal>
  );
}
export default RegionModal;
