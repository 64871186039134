import { useTranslation } from 'react-i18next';

import { Tabs } from 'antd';

import {
  getCustomSurveyForms,
  getTemplateSurveyForms,
} from 'redux/surveys/surveysActions';

import ExperienceFormListing from './ExperienceFormListing';

const translationKey =
  'settings.locations.linkedExperiences.linkExperienceModal.surveyForms';

const FORMS_TABS = [
  {
    label: 'settings.feedbackForms.customForms',
    id: 'custom-forms',
    tabKey: 'custom-forms',
    isTemplate: false,
    getDataAction: getCustomSurveyForms,
  },
  {
    label: 'settings.feedbackForms.surveyTemplates',
    id: 'cxmeter-forms',
    tabKey: 'cxmeter-forms',
    isTemplate: true,
    getDataAction: getTemplateSurveyForms,
  },
];

function FeedbackFormsByExperience({
  linkedExperience,
  setLinkedExperience,
  navigateToFeedbackFormDetailPage,
}) {
  const { t } = useTranslation();

  const handleCreateNewForm = () => {
    navigateToFeedbackFormDetailPage('new', false);
  };

  return (
    <div className="feedback-forms-container">
      <div className="feedback-forms-header">
        <div>
          <h6>{t(`${translationKey}.title`)}</h6>
          <p>{t(`${translationKey}.subTitle`)}</p>
        </div>
        <button
          onClick={handleCreateNewForm}
          className="cstm-btn secondary-cstm-btn"
        >
          <span>{t(`${translationKey}.createNewForm`)}</span>
        </button>
      </div>
      <div className="anx">
        <Tabs
          tabPosition="top"
          defaultActiveKey={
            FORMS_TABS[linkedExperience?.is_template ? 1 : 0].tabKey
          }
          style={{ minHeight: 220 }}
          items={FORMS_TABS.map((tab) => {
            return {
              label: t(tab.label),
              key: tab.tabKey,
              children: (
                <ExperienceFormListing
                  linkedExperience={linkedExperience}
                  setLinkedExperience={setLinkedExperience}
                  navigateToFeedbackFormDetailPage={
                    navigateToFeedbackFormDetailPage
                  }
                  {...tab}
                />
              ),
            };
          })}
        />
      </div>
    </div>
  );
}

export default FeedbackFormsByExperience;
