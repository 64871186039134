import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getUserAPICall } from 'apis/authentication';
import { parentEntitySelectAPICall } from 'apis/cxmeter';
import { URLS } from 'utils/constants';
import { ORG_SWITCH_ACTION } from 'utils/constants/reduxActions';
import { getPathNameFromFullURL, storeParentEntity } from 'utils/helpers';

const useOrgSwitcher = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const {
    parentEntity,
    user: loggedInUser,
    parentEntities: { data: parentEntities },
  } = authentication;
  const [switchingOrgData, setSwitchingOrg] = useState({
    org: null,
    loading: false,
  });

  const updateOrgState = ({
    parentEntity: newOrg,
    user,
    lastSelectedParentEntity: lastParentEntity,
  } = {}) => {
    const lastSelectedParentEntity =
      lastParentEntity ?? parentEntities[0] ?? {};
    if (newOrg?.id) storeParentEntity(newOrg);
    dispatch({
      ...ORG_SWITCH_ACTION,
      payload: {
        authentication: {
          ...authentication,
          user: user ?? loggedInUser,
          parentEntity: newOrg ?? {},
          lastSelectedParentEntity,
          isAppLoading: false,
          isActiveOrg: true,
        },
      },
    });
  };

  const switchOrg = async (org) => {
    if (org?.id && org.id !== parentEntity?.id) {
      setSwitchingOrg({ org, loading: true });

      const newOrg = await parentEntitySelectAPICall(org?.id);
      const user = await getUserAPICall('me'); // User api should be called after selecting Parent entity cookie

      updateOrgState({
        parentEntity: newOrg,
        user,
        lastSelectedParentEntity: parentEntity,
      });
      setSwitchingOrg({ org: null, loading: false });
      let redirectTo = URLS.DASHBOARD_URL;
      if (newOrg?.last_onboarding_url) {
        const onboardingPath = getPathNameFromFullURL(
          newOrg?.last_onboarding_url,
        );
        redirectTo = onboardingPath;
      }
      const redirectToSameRoute = pathname === redirectTo;
      navigate(redirectTo, {
        replace: true,
        state: { remount: redirectToSameRoute },
      });
    }
  };
  return { switchingOrgData, switchOrg, updateOrgState };
};

export default useOrgSwitcher;
