import { LATEST_FILTER } from 'utils/constants';

import Yup from './Yup';
import translateFieldError from './validationTranslations';

const t = translateFieldError;

const insightFiltersSchema = () => {
  return Yup.object().shape({
    filter: Yup.object().shape({
      id: Yup.string().required(t('required', 'filterType')),
      value: Yup.mixed().when('id', (id, schema) =>
        id === LATEST_FILTER
          ? schema.nullable()
          : schema.required(t('required', 'year')),
      ),
    }),
  });
};

export default insightFiltersSchema;
