import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { Filters } from 'components/filters';
import {
  BRANCH_EXP_FILTER,
  DATE_RANGE_FILTER,
  EXPERIENCE_FILTER,
  REGION_BRANCH_FILTER,
} from 'components/filters/filterTypes';
import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import { Icon } from 'components/ui';
import { DAHSBOARD_FILTER } from 'pages/dashboard/Dashboard';
import {
  getDropdownExperiences,
  getDropdownLocations,
  getDropdownRegions,
} from 'redux/paginatedDropdowns/paginatedDropdownsActions';
import {
  getActiveFilterId,
  getDashboardDefaultDateRange,
} from 'utils/helpers/dashboardHelpers';

import CreateNewFiltersModal from './CreateNewFiltersModal';
import ManageFiltersModal from './ManageFiltersModal';

function DashboardFilters({
  setIsConfigOpen,
  dashboardRef,
  filters,
  setFilters,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { parentEntity } = useSelector((state) => state.authentication);
  const {
    savedFilters: { data: savedFilters },
  } = useSelector((state) => state.dashboard);

  const [showManageFilters, setShowManageFilters] = useState(false);
  const [showNewFilterModal, setShowNewFilterModal] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => dashboardRef.current,
    pageStyle: '@page { size: auto;  margin: 15mm; } }',
    documentTitle: `${parentEntity?.name} comparison_${dayjs().format(
      'YYYYMMDD',
    )}`,
  });

  const handleApplyFilter = (filter) => {
    const { data } = filter ?? {};
    const primaryFilter = data?.filter_data?.[BRANCH_EXP_FILTER]?.primaryFilter;
    // Update dropdowns option as it was when filter was created
    if (primaryFilter?.name === EXPERIENCE_FILTER) {
      const filterParams = {
        page: 1,
        [primaryFilter.key]: primaryFilter.value,
      };
      dispatch(getDropdownExperiences({ page: 1 }));
      dispatch(getDropdownRegions(filterParams));
      dispatch(getDropdownLocations(filterParams));
    } else if (primaryFilter?.name === REGION_BRANCH_FILTER) {
      dispatch(getDropdownRegions({ page: 1 }));
      dispatch(getDropdownLocations({ page: 1 }));
      dispatch(
        getDropdownExperiences({
          page: 1,
          [primaryFilter.key]: primaryFilter.value,
        }),
      );
    } else if (!data?.filter_data) {
      dispatch(getDropdownRegions({ page: 1 }));
      dispatch(getDropdownLocations({ page: 1 }));
      dispatch(getDropdownExperiences({ page: 1 }));
    }
    setFilters(
      data?.filter_data ?? {
        ...DAHSBOARD_FILTER,
        [DATE_RANGE_FILTER]: getDashboardDefaultDateRange(),
      },
    );
  };

  const handleChangeFilter = (value) => {
    const filter = getActiveFilterId(savedFilters, value);
    if (filter?.id) {
      handleApplyFilter({
        value: filter.id,
        label: filter.name,
        data: { filter_data: filter.filter_data },
      });
      return;
    }
    if (isEmpty(value[DATE_RANGE_FILTER])) {
      setFilters({
        ...value,
        [DATE_RANGE_FILTER]: getDashboardDefaultDateRange(),
      });
      return;
    }

    setFilters(value);
  };

  const activeFilter = useMemo(() => {
    const filter = getActiveFilterId(savedFilters, filters);
    return filter?.id
      ? {
          value: filter.id,
          label: filter.name,
          filter_data: filter.filter_data,
        }
      : filter;
  }, [filters, savedFilters]);

  const disableNewFilter = useMemo(() => {
    const { dateRangeFilter, branchExpFilter } = filters;
    if (activeFilter) return true;
    if (
      dateRangeFilter?.startDate ||
      dateRangeFilter?.endDate ||
      branchExpFilter?.region ||
      branchExpFilter?.branch ||
      branchExpFilter.experience
    )
      return false;
    return true;
  }, [activeFilter, filters]);

  return (
    <section className="dashboardHeader">
      <div className="org-detail">
        <div>
          <h1>{parentEntity?.name}</h1>
          <p className="m-0">
            {`${dayjs(filters[DATE_RANGE_FILTER].startDate).format(
              'MM-DD-YYYY',
            )} to ${dayjs(filters[DATE_RANGE_FILTER].endDate).format(
              'MM-DD-YYYY',
            )}`}
          </p>
        </div>
        <button
          onClick={handlePrint}
          className="cstm-btn primary-cstm-btn print-analytics-btn"
          type="button"
        >
          <Icon style={{ fontSize: 18 }} type="print" />
          {t('printPdf')}
        </button>
      </div>
      <div className="dashboard-applied-filters-container">
        <div className="dashboard-applied-filters dashboard-and-reviews-page-filters">
          <p className="m-0">{t('filters.title')}: </p>
          <Filters
            isRoundedDropDown
            addControlsWrapper={false}
            addMainWrapper={false}
            addBranchExpFilterWrapper={false}
            doFetchFiltersData
            value={filters}
            onChange={handleChangeFilter}
          />
          <span className="seprator" />
          <Tooltip
            title={
              disableNewFilter
                ? activeFilter
                  ? ''
                  : t('filters.select.any')
                : ''
            }
          >
            <button
              disabled={disableNewFilter}
              onClick={() => setShowNewFilterModal(true)}
              className="cstm-btn tertiary-cstm-btn create-filter-btn"
            >
              <Icon style={{ fontSize: 17 }} type="filter_alt" />
              <span>{t('dashboard.filterShortcut')}</span>
            </button>
          </Tooltip>
        </div>
        <button
          className="cstm-btn tertiary-cstm-btn widgets-config"
          type="button"
          onClick={() => setIsConfigOpen(true)}
        >
          <Icon style={{ fontSize: 18 }} type="settings" />
          <span>{t('manageWidgets')}</span>
        </button>
      </div>
      {savedFilters.length ? (
        <div
          className={`saved-filters dashboard-and-reviews-page-filters ${
            activeFilter ? 'active-filter-applied' : ''
          }`}
        >
          <p className="m-0">{t('dashboard.savedFilters')}: </p>
          <SelectWithCustomSuffixIcon
            isRoundedDropDown
            id="dashboard-saved-filters"
            name="dashboard-saved-filters"
            currentFilterClassName="dashboard-saved-filters"
            options={savedFilters}
            placeholder={t('dashboard.savedFilters')}
            value={activeFilter}
            onChange={handleApplyFilter}
          />
          <span className="seprator" />
          <button
            className="cstm-btn tertiary-cstm-btn manage-filters"
            onClick={() => setShowManageFilters(true)}
          >
            <Icon style={{ fontSize: 18 }} type="settings" />
            <span>{t('manageFilters')}</span>
          </button>
        </div>
      ) : null}
      {showManageFilters ? (
        <ManageFiltersModal onClose={() => setShowManageFilters(false)} />
      ) : null}
      {showNewFilterModal ? (
        <CreateNewFiltersModal
          appliedFilters={filters}
          onClose={() => setShowNewFilterModal(false)}
        />
      ) : null}
    </section>
  );
}

export default DashboardFilters;
