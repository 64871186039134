import { useTranslation } from 'react-i18next';

function FormikLabeledInput({
  formik,
  inputName,
  label,
  required = true,
  type = 'text',
  placeholder,
  onChange,
  wrapperClassName = '',
  ...props
}) {
  const { values, touched, errors } = formik;
  const { t } = useTranslation();
  return (
    <label
      className={`${wrapperClassName} control ${
        touched[inputName] && errors[inputName] ? 'control-invalid' : ''
      }`}
    >
      {label ? (
        <span className="required-field">
          {label} {required ? <span>*</span> : null}
        </span>
      ) : null}
      <input
        type={type}
        name={inputName}
        placeholder={`${placeholder ?? t('enter')} ${
          required && !label ? '*' : ''
        }`}
        onChange={onChange ?? formik.handleChange}
        value={values[inputName] || ''}
        {...props}
      />
      {touched[inputName] && (
        <span className="control-error">{errors[inputName]}</span>
      )}
    </label>
  );
}
export default FormikLabeledInput;
