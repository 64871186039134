import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Typography } from 'antd';
import { includes, toLower } from 'lodash';

import { SurveyThumbnail } from 'components/surveyThumbnail';
import { getExperiencesByLocation } from 'redux/experience/experienceActions';
import { resetEntityLinkedExperiences } from 'redux/experience/experienceSlice';

function FeedbackTemplatesList({ formik, onConfirm }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    entitiesLinkedExperiences: { data: locations, loading },
  } = useSelector((s) => s.experience);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState();
  const [locationSearch, setLocationSearch] = useState('');

  const onSelecteExperience = () => {
    if (selectedExperience)
      formik.setFieldValue('entity_experience', selectedExperience);
    onConfirm();
  };

  useEffect(() => {
    dispatch(getExperiencesByLocation());
  }, []);

  useEffect(
    () => () => {
      dispatch(resetEntityLinkedExperiences());
    },
    [],
  );

  const filteredLocations = useMemo(() => {
    return locationSearch
      ? (locations ?? []).filter((loc) =>
          includes(toLower(loc.entity_name), toLower(locationSearch)),
        )
      : locations;
  }, [locationSearch, locations]);

  const selectedLocationData = selectedLocation ?? locations[0];

  return (
    <>
      <div className="feedback-tmeplates">
        {loading ? (
          <p>{t('loadingDot')}</p>
        ) : (
          <div className="locations-and-experiences-container">
            <div className="locations-tabs">
              <div className="location-search-input-container">
                <Input
                  placeholder="Search Location"
                  value={locationSearch}
                  onChange={(e) => setLocationSearch(e.target.value)}
                />
              </div>
              {filteredLocations.map((location) => (
                <div
                  role="presentation"
                  onClick={() => setSelectedLocation(location)}
                  key={location.entity_id}
                  className={`location-tab ${
                    selectedLocationData?.entity_id === location.entity_id
                      ? 'selected-location-tab'
                      : ''
                  }`}
                >
                  <Typography.Text
                    ellipsis={{
                      tooltip: {
                        title: location.entity_name,
                        getPopupContainer: (target) => target.parentNode,
                      },
                    }}
                  >
                    {location.entity_name}
                  </Typography.Text>
                </div>
              ))}
            </div>
            <div className="linked-experiences-container">
              <div>
                {selectedLocationData?.entity_experiences?.length ? (
                  selectedLocationData?.entity_experiences?.map((exp) => {
                    return (
                      <SurveyThumbnail
                        selected={
                          (selectedExperience?.id ||
                            formik.values.entity_experience?.id) === exp.id
                        }
                        onChange={() => setSelectedExperience(exp)}
                        key={exp.id}
                        icon={exp.experience_icon}
                        data={exp}
                      />
                    );
                  })
                ) : (
                  <p>{t('noExperienceFound')}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <button
        disabled={!selectedExperience && !formik.values.entity_experience}
        style={{ alignSelf: 'flex-end' }}
        className="cstm-btn primary-cstm-btn confirm-overlay-value-btn"
        onClick={onSelecteExperience}
      >
        {t('confirm')}
      </button>
    </>
  );
}

export default FeedbackTemplatesList;
