import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SectionBanner({ title, section, toggleSection, id, isOpen }) {
  return (
    <div
      className="comparison__banner pointer"
      onClick={() => toggleSection(id)}
    >
      <div className="comparison__title">
        {title ? <p className="comparison__experience">{title}</p> : null}
        {section ? (
          <p className="comparison__experience-question">{section}</p>
        ) : null}
      </div>
      <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleUp} />
    </div>
  );
}

export default SectionBanner;
