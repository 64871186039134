import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';

import RelativeTime from 'components/ui/relativeTime/RelativeTime';
import {
  ENTITY_ADMIN,
  FINANCIAL_ADMIN,
  PARENT_ENTITY_ADMIN,
} from 'utils/constants';

export default function getUserColumns({ onEdit, loggedInUser, t }) {
  return [
    {
      title: t('inputFields.firstName'),
      dataIndex: 'first_name',
      render(_, record) {
        return <p>{record.first_name}</p>;
      },
      onCell: () => ({
        style: {
          maxWidth: 300,
          minWidth: 125,
        },
      }),
      sorter: true,
    },
    {
      title: t('inputFields.lastName'),
      dataIndex: 'last_name',
      sorter: true,
      render(_, record) {
        return <p>{record.last_name}</p>;
      },
      onCell: () => ({
        style: {
          maxWidth: 300,
          minWidth: 125,
        },
      }),
    },
    {
      title: t('email'),
      dataIndex: 'email',
      sorter: true,
      render(_, record) {
        return <p>{record.email}</p>;
      },
      onCell: () => ({
        style: {
          maxWidth: 200,
          minWidth: 125,
        },
      }),
    },
    {
      title: t('locations'),
      sorter: false,
      render(text, user) {
        if (user.type !== ENTITY_ADMIN) {
          return 'All';
        }
        const selectedEntities = user.entities.filter(
          (entity) => !isEmpty(entity.name),
        );
        return (
          <ul className="user-location-list">
            {selectedEntities.map((e) => (
              <li key={e.id}>{e.name}</li>
            ))}
          </ul>
        );
      },
    },
    {
      title: t('permissions'),
      dataIndex: 'type',
      sorter: true,
      render(_, record) {
        return (
          <p>
            {
              {
                [PARENT_ENTITY_ADMIN]: t('orgAdmin'),
                [ENTITY_ADMIN]: t('locAdmin'),
                [FINANCIAL_ADMIN]: t('financeAdmin'),
              }[record.type]
            }
          </p>
        );
      },
    },
    {
      title: t('lastActivity'),
      dataIndex: 'last_activity_at',
      sorter: true,
      render: (text, record) =>
        record.last_activity_at ? (
          <RelativeTime date={record.last_activity_at} />
        ) : (
          '-'
        ),
      onCell: () => ({
        style: {
          minWidth: 150,
          whiteSpace: 'nowrap',
        },
      }),
    },
    {
      title: t('status'),
      dataIndex: 'is_active',
      sorter: true,
      render(_, record) {
        return (
          <span className={`active-pill ${record.is_active ? 'active' : ''}`}>
            {record.is_active ? t('active') : t('inactive')}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      render(_, record) {
        return loggedInUser?.id === record.id ? null : (
          <button
            type="button"
            className="button"
            onClick={() => {
              onEdit(record);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        );
      },
    },
  ];
}
