import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip } from 'antd';

import { WordCloud } from 'components';
import { HorizontalBarChart, PieChart } from 'components/charts';
import { RatingPerformers } from 'components/ratingPerformers';
import { getQuestionsAnalytics } from 'redux/dashboard/dashboardActions';
import { CHECKBOX, RATING, TEXT } from 'utils/constants/questionTypes';
import { markupToText } from 'utils/helpers';

function QuestionAnalytics({ questionUUID, filters, redirectToReviewsPage }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const questionAnalytics = useSelector(
    (state) => state.dashboard.questionAnalytics,
  );
  const question = questionAnalytics[questionUUID];
  const [analytics, setAnalytics] = useState({});

  const getOptionId = (label) =>
    question?.data?.analytics.find(
      (analytic) =>
        markupToText(analytic.label).toLowerCase() === label.toLowerCase(),
    ).option_id;

  const handleOnChartClick = ({ label }) => {
    const searchParams = {
      option_id: getOptionId(label),
      type: question.data?.question_type,
    };
    redirectToReviewsPage(searchParams);
  };

  useEffect(() => {
    dispatch(getQuestionsAnalytics({ questionId: questionUUID, filters }));
  }, [filters]);

  useEffect(() => {
    if (question?.data) {
      const { analytics: qAnalytics, question_type: qType } = question.data;
      if (qAnalytics && qType && qType !== TEXT) {
        setAnalytics(
          qAnalytics
            .map((analytic) => {
              return {
                ...analytic,
                label: analytic?.label?.replace(/&nbsp;/g, ' ').trim(),
              };
            })
            .reduce((acc, i) => {
              acc[markupToText(i.label)] = i.count;
              return acc;
            }, {}),
        );
      }
    }
  }, [question]);

  if (!question || question.isLoading)
    return <Spinner style={{ color: '#de0024' }} />;
  if (question.data?.question_type === RATING) {
    return (
      <RatingPerformers
        isQuestion
        questionAnalytics={question?.data?.analytics || []}
        title={markupToText(question?.data?.label)}
        redirectToReviewsPage={redirectToReviewsPage}
      />
    );
  }
  if (question.data?.question_type === TEXT) {
    return (
      <WordCloud
        data={question?.data?.analytics}
        label={question?.data?.label}
        onWordClick={(searchParams) => redirectToReviewsPage(searchParams)}
      />
    );
  }
  return (
    <div className="chartContainer">
      <Tooltip title={markupToText(question?.data?.label ?? '')}>
        <h3
          className="chart-title"
          dangerouslySetInnerHTML={{ __html: question?.data?.label }}
        />
      </Tooltip>
      {question.data?.question_type === CHECKBOX ? (
        <HorizontalBarChart
          data={analytics}
          label={t('responses')}
          onClick={handleOnChartClick}
        />
      ) : (
        <PieChart analytics={analytics} small onClick={handleOnChartClick} />
      )}
    </div>
  );
}

export default QuestionAnalytics;
