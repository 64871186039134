import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import _ from 'lodash';

import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

export default function VerifyEmail() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  usePageTitle(t('pages.verification'));

  useEffect(() => {
    if (_.isEmpty(state)) {
      navigate(URLS.LOGIN_URL);
    }
  }, [navigate]);

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header
          showLogoutButton={false}
          showOrgSwitcher={false}
          showStepper={false}
        />
        <OnboardingLayout.Form
          title={t('signup.registerTitle')}
          subtitle={t('signup.registerSubTitle')}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
