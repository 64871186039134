import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { OnboardingLayout } from 'layouts';
import { URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

export default function ResetPasswordSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onProceed = () => {
    navigate(URLS.LOGIN_URL);
  };

  usePageTitle(t('pages.resetPassword'));

  return (
    <OnboardingLayout centered>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header
          showLogoutButton={false}
          showOrgSwitcher={false}
          showStepper={false}
        />
        <OnboardingLayout.Form title={t('resetPassword.successTitle')}>
          {t('resetPassword.success')}
          <br />
          {t('resetPassword.proceed')}
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          hideBackButton
          onClickContinue={onProceed}
          continueText={t('proceed')}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
