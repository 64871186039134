import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Table } from 'antd';

import { SettingsPageContext } from 'contexts';
import { LocationAndRegionContext } from 'pages/locationsAndRegions/LocationsAndRegions';
import {
  deleteRegionById,
  getRegions,
} from 'redux/locationsAndRegions/locationsAndRegionsActions';
import { attachKeysToDataSource, getAntdPaginationProps } from 'utils/helpers';
import { getRegionsColumns } from 'utils/helpers/locationsAndRegionsHelpers';
import useDispatchWithErrors from 'utils/hooks/useDispatchWithErrors';

import '../_locationsAndRegions.scss';

function Regions() {
  const { dispatch } = useDispatchWithErrors();
  const {
    deleteRegionsLoading,
    regions: { data: regions, loading },
  } = useSelector((s) => s.locationsAndRegions);
  const { params, onChangePagination } = useContext(SettingsPageContext);
  const { setIsOpenRegionModal, regionDataRef } = useContext(
    LocationAndRegionContext,
  );

  const editRegion = (region) => {
    regionDataRef.current = region.id;
    setIsOpenRegionModal(true);
  };

  const deleteRegion = async (region) => {
    const { isError } = await dispatch(deleteRegionById(region.id));
    if (!isError) {
      dispatch(getRegions({ ...params }));
    }
  };

  useEffect(() => {
    dispatch(getRegions({ ...params }));
  }, [params]);

  return (
    <div>
      <Table
        className="left-align"
        scroll={{ x: 'max-content' }}
        columns={getRegionsColumns({
          onEditRegion: editRegion,
          onDeleteRegion: deleteRegion,
          deleteRegionsLoading,
        })}
        dataSource={attachKeysToDataSource(regions.results)}
        loading={loading}
        pagination={{
          ...getAntdPaginationProps({
            data: regions,
            currentPage: params.page ?? 1,
            onChangePagination,
          }),
        }}
      />
    </div>
  );
}

export default Regions;
