import { useTranslation } from 'react-i18next';

import { ReactComponent as Desktop } from 'assets/icons/icon_desktop.svg';
import { ReactComponent as Mobile } from 'assets/icons/icon_mobile.svg';
import { ReactComponent as Tablet } from 'assets/icons/icon_tablet.svg';
import { DEVICES, DEVICE_PREVIEWS } from 'utils/constants';

const { MOBILE, TABLET, DESKTOP } = DEVICES;

function PreviewSizeControls({ selectedDevice, setSelectedDevice }) {
  const { t } = useTranslation();

  return (
    <div className="preview-devices-controls">
      <button
        className={`cstm-btn tertiary-cstm-btn ${
          selectedDevice.device === MOBILE ? 'active-device' : ''
        }`}
        onClick={() => setSelectedDevice(DEVICE_PREVIEWS[MOBILE])}
      >
        <Mobile />
        {t('mobile')}
      </button>
      <button
        className={`cstm-btn tertiary-cstm-btn ${
          selectedDevice.device === TABLET ? 'active-device' : ''
        }`}
        onClick={() => setSelectedDevice(DEVICE_PREVIEWS[TABLET])}
      >
        <Tablet />
        {t('tablet')}
      </button>
      <button
        className={`cstm-btn tertiary-cstm-btn ${
          selectedDevice.device === DESKTOP ? 'active-device' : ''
        }`}
        onClick={() => setSelectedDevice(DEVICE_PREVIEWS[DESKTOP])}
      >
        <Desktop />
        {t('desktop')}
      </button>
    </div>
  );
}

export default PreviewSizeControls;
