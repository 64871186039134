/* eslint-disable no-unsafe-optional-chaining */
import { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';

import { chartColors } from 'utils/constants';
import { chartHelper, convertToTitleCase } from 'utils/helpers';

import './_charts.scss';

const { getColor } = chartHelper;
ChartJS.register(ArcElement, Tooltip, Legend, Title);

/**
 *
 * @param {object} analytics {label: value, label: value}
 * @param {string} title
 * @param {boolean} small
 * @returns {React.JSX} A pie chart for given data
 */
export default function PieChart({
  analytics,
  title,
  small,
  onClick,
  additionalLegends,
  showDataLabel = false,
  statColors,
  onLegendClick,
}) {
  const { t } = useTranslation();
  const [legends, setLegends] = useState([]);
  const [values, setValues] = useState([]);
  const labels = Object.keys(analytics).map((key) => convertToTitleCase(key));

  const handleSetLegends = ({
    legendLabels,
    // eslint-disable-next-line no-unused-vars
    toggleChartVisibility,
    legendValues,
  }) => {
    if (!_.isEqual(legends, legendLabels) || !_.isEqual(values, legendValues)) {
      setLegends(legendLabels);
      setValues(legendValues);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      htmlLegend: {
        handleSetLegends,
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        backgroundColor: 'white',
        titleColor: 'black',
        titleFont: {
          size: 18,
        },
        titleFontSize: 28,
        caretSize: 10,
        borderColor: '#868584',
        bodyColor: '#868584',
        borderWidth: 0.5,
        boxHeight: 7,
        boxPadding: 2,
        padding: 10,
        usePointStyle: true,
        callbacks: {
          title(data) {
            return data[0].formattedValue;
          },
          label() {
            return '';
          },
          labelTextColor: () => 'black',
          beforeBody(data) {
            const percent = Math.round(
              (data[0].parsed /
                data[0]?.dataset?.data?.reduce((a, v) => a + v)) *
                100,
            );
            return `${data[0].label} ( ${percent}% )`;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: 'white',
        display: showDataLabel,
        formatter: (value, ctx) => {
          let sum = 0;
          const dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach((data) => {
            sum += data;
          });
          return `${Math.round((value * 100) / sum)}% `;
        },
        font: {
          size: small ? 14 : 12,
        },
      },
    },
    layout: {
      padding: 10,
    },
    onClick(e, item) {
      if (item.length < 1) return;
      const currentElement = {
        label: e.chart.data.labels[item[0].index],
        value: e.chart.data.datasets[0].data[item[0].index],
      };
      onClick(currentElement);
    },
  };

  const getBackgroundColors = (data, colors) =>
    data.map((value, index) =>
      value === 'happy' ? '#0C9618' : getColor(index, colors),
    );
  const data = {
    labels,
    datasets: [
      {
        label: { title },
        data: analytics ? Object.values(analytics) : [],
        borderWidth: 0,
        backgroundColor: _.isEmpty(statColors)
          ? getBackgroundColors(Object.keys(analytics), chartColors)
          : statColors,
      },
    ],
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, chartOptions) {
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      chartOptions.handleSetLegends({
        legendLabels: items,
        toggleChartVisibility: chart.toggleDataVisibility,
        legendValues: chart.data.datasets[0].data,
      });
    },
  };

  return (
    <div className="pieChartWrapper">
      {_.isEmpty(analytics) ||
      !_.find(Object.values(analytics), (o) => o > 0) ? (
        <span>{t('noDataFound')}</span>
      ) : (
        <>
          <div className={small ? 'PieChartSmall' : 'PieChart'}>
            <Pie
              data={data}
              options={options}
              plugins={[ChartDataLabels, htmlLegendPlugin]}
            />
          </div>
          <div className="pieChartLegend">
            <div className="legendUl">
              {legends.map((item, idx) => (
                // eslint-disable-next-line
                <li
                  className="legendLi"
                  key={idx}
                  onClick={() => onLegendClick(item.text)}
                >
                  <span className="legendHeaderRow">
                    <span
                      className="legendLabelColor"
                      style={{ backgroundColor: item.fillStyle }}
                    />
                    <p className="legend-content">
                      <span className="legend-key">{item.text}</span> -{' '}
                      {values[item.index]}
                    </p>
                  </span>
                </li>
              ))}
              {/* eslint-disable-next-line */}
              <li className="legendLi" onClick={() => onLegendClick()}>
                <div className="legendHeaderRow">
                  <div className="legendLabelColor total" />
                  <p className="legend-content">
                    <span className="legend-key">{t('total')}</span> -{' '}
                    {values.length > 0 && values.reduce((a, v) => a + v)}
                  </p>
                </div>
              </li>
              {additionalLegends?.map((legend, idx) => (
                <li className="legendLi" key={idx}>
                  <div className="legendHeaderRow">
                    <div className="legendLabelColor multi-color" />
                    <p className="legend-content">
                      <span className="legend-key">{legend.label}</span> -{' '}
                      {legend.value}
                    </p>
                  </div>
                </li>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
