/* eslint-disable no-else-return */
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import { Loader } from 'components';
import { PermissionDenied } from 'pages';
import {
  ENTITY_ADMIN,
  FINANCIAL_ADMIN,
  PARENT_ENTITY_ADMIN,
  URLS,
} from 'utils/constants';
import useOrgSwitcher from 'utils/hooks/useOrgSwitcher';

export default function ProtectedRoute({
  Component,
  isSuperuser,
  isRestrictedToParentEntityAdmin,
  isRestrictedToEntityAdmin,
  isRestrictedToFinancialAdmin,
  ...rest
}) {
  const navigate = useNavigate();
  const { state: locationState, pathname } = useLocation();
  const {
    user,
    token,
    parentEntity,
    isAppLoading,
    lastSelectedParentEntity,
    parentEntities: { data: parentEntities },
  } = useSelector((state) => state.authentication);
  const lastParentEntity = isEmpty(lastSelectedParentEntity)
    ? parentEntities[0]
    : lastSelectedParentEntity;
  const { switchOrg } = useOrgSwitcher();

  // ComponentKey is maintained only to remount the component if it is needed.
  const componentKey = useRef(pathname);
  useEffect(() => {
    if (locationState?.remount) {
      componentKey.current = `${pathname}-${parentEntity?.id}`;
      navigate(pathname, {
        replace: true,
        state: { ...locationState, remount: false },
      });
    }
  }, [locationState?.remount]);

  useEffect(() => {
    if (!isAppLoading && isEmpty(parentEntity) && lastParentEntity) {
      switchOrg(lastParentEntity);
    }
  }, [pathname, parentEntities, isAppLoading]);

  useEffect(() => {
    if (!isAppLoading) {
      if (!isEmpty(user) && !user.is_active) {
        navigate(URLS.VERIFY_EMAIL_URL);
      }
    }
  }, [parentEntity, user, isAppLoading]);

  if (isAppLoading) {
    return <Loader />;
  }

  if (isEmpty(user) || !token) {
    return (
      <Navigate
        to={URLS.LOGIN_URL}
        replace
        state={locationState?.refreshState ? null : { redirectTo: pathname }}
      />
    );
  }

  if (isSuperuser) {
    if (user.is_superuser) {
      return <Component key={componentKey.current} {...rest} />;
    }
    return <PermissionDenied />;
  }

  if (!isEmpty(user)) {
    if (isRestrictedToParentEntityAdmin && user.type === PARENT_ENTITY_ADMIN) {
      return <Component key={componentKey.current} {...rest} />;
    } else if (isRestrictedToEntityAdmin && user.type === ENTITY_ADMIN) {
      return <Component key={componentKey.current} {...rest} />;
    } else if (isRestrictedToFinancialAdmin && user.type === FINANCIAL_ADMIN) {
      return <Component key={componentKey.current} {...rest} />;
    } else {
      return <PermissionDenied />;
    }
  }
}
