import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CXMETER_SUBSCRIPTION_TITLE } from 'config';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import { Modal } from 'components/ui';
import { cancelPlan } from 'redux/payments/paymentsActions';
import { getPortalFeedbackForms } from 'redux/portalFeedback/portalFeedbackActions';
import { UNHAPPY } from 'utils/constants';
import { TEXT } from 'utils/constants/questionTypes';
import { getSegmentId, markupToText } from 'utils/helpers';
import selectFeedbackForm from 'utils/helpers/portalFeedbackHelpers';
import { useSubmitFeedback } from 'utils/hooks';
import cancelPlanSchema from 'utils/validations/plansValidations';

import CancelPlanFeedback from './Feedback/Feedback';

import './_CancelPaymentPlanModal.scss';

export default function CancelPaymentPlanModal({ onClose, isOpenModal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setExpId, setStartTime, submitFeedback } = useSubmitFeedback();
  const { forms, isLoading: formsLoading } = useSelector(
    (state) => state.portalFeedback,
  );

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: { forms: null },
    validationSchema: cancelPlanSchema,
  });
  const { values, isValid } = formik;

  const modalCloseHandler = () => {
    onClose();
    setIsLoading(false);
  };

  const feedbackSubmitHandler = async () => {
    if (formsLoading) return;
    setIsLoading(true);
    const feedback = [];
    values.forms.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.question_type === TEXT) {
          feedback.push({
            key: question.question_uuid,
            value: question.feedback,
          });
        } else {
          question.options.forEach((option) => {
            if (option.selected) {
              feedback.push({
                key: question.question_uuid,
                value: option.option_id,
              });
            }
          });
        }
      });
    });
    const segmentId = getSegmentId(UNHAPPY); // As user is leaving the platform so SegmentId will always be UNHAPPY
    try {
      await submitFeedback(segmentId, feedback);
      await dispatch(cancelPlan());
      modalCloseHandler();
    } catch (error) {
      modalCloseHandler();
    }
  };

  useEffect(() => {
    if (isOpenModal && forms) {
      const [expId, survey] = selectFeedbackForm(
        forms,
        CXMETER_SUBSCRIPTION_TITLE,
      );
      setExpId(expId);
      formik.setFieldValue('forms', survey);
    }
  }, [isOpenModal, forms]);

  useEffect(() => {
    if (isOpenModal) {
      setStartTime(new Date());
      dispatch(getPortalFeedbackForms());
    }
  }, [isOpenModal]);

  return (
    <Modal
      open={isOpenModal}
      onClose={modalCloseHandler}
      className="cancel-plan-modal-container"
    >
      <Modal.Header
        title={t('settings.payments.cancelPlan.farewellMessage')}
        onClose={modalCloseHandler}
      />
      <Modal.Body>
        {formsLoading && !forms ? (
          <p>{t('loadingDot')}</p>
        ) : (
          <div className="sections-container">
            {values.forms?.sections.map((section, sectionIndex) => (
              <div className="section" key={section.section_uuid}>
                {section.questions.map((question, questionIndex) => (
                  <div
                    className={`question-${question.question_type}`}
                    key={question.question_uuid}
                  >
                    <p>{markupToText(question.label)}</p>
                    <CancelPlanFeedback
                      formik={formik}
                      question={question}
                      sectionIndex={sectionIndex}
                      questionIndex={questionIndex}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        disablePrimaryAction={!isValid || isEmpty(values.forms) || isLoading}
        isLoading={formsLoading && forms}
        primaryLabel={t('submit')}
        primaryAction={feedbackSubmitHandler}
      />
    </Modal>
  );
}
