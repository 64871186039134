/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchPaymentPlansAPICall } from 'apis/payments';
import { PaymentPlan } from 'components';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { getSubscribedPlan } from 'redux/payments/paymentsActions';
import { setSelectedPlan as setSelectedPlanStore } from 'redux/payments/paymentsSlice';
import { PAYMENT_SETUP_STEP, URLS } from 'utils/constants';
import { usePageTitle } from 'utils/hooks';

import './_paymentPlans.scss';

function PaymentPlans() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: subscribedPlan, subscribedPlanLoading } = useSelector(
    (s) => s.payments?.subscribedPlan ?? {},
  );
  const { selectedPlanStore } = useSelector((s) => s.payments);

  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(selectedPlanStore);
  const navigate = useNavigate();
  const {
    parentEntity: { type_name: orgType },
  } = useSelector((state) => state.authentication);

  usePageTitle(t('pages.paymentSetup'));

  const fetchPaymentPlans = async () => {
    setLoading(true);
    setPlans(await fetchPaymentPlansAPICall());
    setLoading(false);
  };

  const handlePlanSubscription = () => {
    if (selectedPlan)
      navigate(URLS.BRANCH_ACTIVATION_URL, { state: { plan: selectedPlan } });
  };

  const onPlanSelect = (plan) => {
    setSelectedPlan(plan);
    dispatch(setSelectedPlanStore(plan));
  };

  useEffect(() => {
    if (subscribedPlan && !subscribedPlan.is_active) {
      fetchPaymentPlans();
    } else if (subscribedPlan?.is_active) {
      navigate(URLS.PAYMENT_URL);
    }
  }, [subscribedPlan?.is_active]);

  useEffect(() => {
    dispatch(getSubscribedPlan());
  }, []);

  return (
    <OnboardingLayout>
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={PAYMENT_SETUP_STEP} />
        <OnboardingLayout.Form
          title={t('onboarding.payment.title')}
          subtitle={t('onboarding.payment.subTitle', {
            orgType: orgType || 'organization',
          })}
        >
          <div className="templatesList--payment templatesList">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={`addItem addItem--payment ${
                  selectedPlan?.id === plan.id && 'selectedItem'
                }`}
                onClick={() => onPlanSelect(plan)}
                role="button"
                tabIndex={0}
              >
                <h4>{plan.title}</h4>
                {plan.overall_cost ? (
                  <div className="planPrice">
                    <h3 className="planCost">${plan.overall_cost}</h3>
                    <p style={{ textTransform: 'lowercase' }}>{t('total')}</p>
                  </div>
                ) : (
                  <>
                    {plan.cost_per_user ? (
                      <div className="planPrice">
                        <h3 className="planCost">${plan.cost_per_user}</h3>
                        <p
                          style={{ lineHeight: 1, textTransform: 'lowercase' }}
                        >
                          {t('perUser')}
                        </p>
                      </div>
                    ) : null}
                    {plan.cost_per_branch ? (
                      <div className="planPrice">
                        <h3 className="planCost">${plan.cost_per_branch}</h3>
                        <p
                          style={{ lineHeight: 1, textTransform: 'lowercase' }}
                        >
                          {t('perLocation')}
                        </p>
                      </div>
                    ) : null}
                  </>
                )}
                {plan.trial_period_days && (
                  <div className="planPrice">
                    <p style={{ lineHeight: 1 }}>
                      {t('settings.payments.plans.trialPeriod', {
                        days: plan.trial_period_days,
                      })}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          isLoading={loading || subscribedPlanLoading || !subscribedPlan}
          continueText={t('onboarding.payment.continue')}
          onClickContinue={handlePlanSubscription}
          disableContinue={!selectedPlan}
          hideBackButton
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane>
        {selectedPlan ? <PaymentPlan plan={selectedPlan} /> : null}
      </OnboardingLayout.Sidepane>
    </OnboardingLayout>
  );
}

export default PaymentPlans;
