import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import {
  EXPERIENCE_FILTER,
  LOCATION_FILTER,
  REGION_BRANCH_FILTER,
  REGION_FILTER,
} from 'components/filters/filterTypes';
import SelectWithCustomSuffixIcon from 'components/selectAndCreateOptionDropdown/SelectWithCustomSuffixIcon';
import {
  BRANCH_FILTER_KEY,
  EXPERIENCE_FILTER_KEY,
  REGION_FILTER_KEY,
} from 'pages/dashboard/Dashboard';
import {
  getDropdownExperiences,
  getDropdownLocations,
  getDropdownRegions,
} from 'redux/paginatedDropdowns/paginatedDropdownsActions';

import { ExpListFilter } from '.';

import './_branchExpFilter.scss';

/**
 * @author
 * @function BranchList
 * */

function RegionLocationExpFilter({
  onChange,
  addWrapper = true,
  value,
  ...rest
}) {
  const regionLocationSelectRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const primaryFilterRef = useRef(null);
  const { data: locations, loading: locationsLoading } = useSelector(
    (state) => state.paginatedDropdowns.locations,
  );
  const { data: regions, loading: regionsLoading } = useSelector(
    (state) => state.paginatedDropdowns.regions,
  );

  const regionAndLocationsTabs = useMemo(() => {
    return [
      {
        id: REGION_FILTER,
        key: REGION_FILTER_KEY,
        label: t('regions'),
        options: regions.results,
        paginationData: regions,
        isPaginationLoading: regionsLoading,
        nextDataAction: getDropdownRegions,
      },
      {
        id: LOCATION_FILTER,
        key: BRANCH_FILTER_KEY,
        label: t('locations'),
        options: locations.results,
        paginationData: locations,
        isPaginationLoading: locationsLoading,
        nextDataAction: getDropdownLocations,
      },
    ];
  }, [regions, regionsLoading, locations, locationsLoading]);

  const currentTabRef = useRef(regionAndLocationsTabs[0]);

  const onTabChange = (newTab) => {
    currentTabRef.current = newTab;
  };

  const getRegionBranchFilterData = (experienceId) => {
    dispatch(getDropdownRegions({ page: 1, experience_id: experienceId }));
    dispatch(getDropdownLocations({ page: 1, experience_id: experienceId }));
  };

  const handleFilterChange = (newValue, activeTab) => {
    // When filter is cleared
    if (newValue === null && !activeTab) {
      if (primaryFilterRef.current === REGION_BRANCH_FILTER) {
        primaryFilterRef.current = null;
        onChange({
          [REGION_FILTER_KEY]: null,
          [BRANCH_FILTER_KEY]: null,
          [EXPERIENCE_FILTER_KEY]: null,
        });
        dispatch(getDropdownExperiences({ page: 1 }));
      } else {
        onChange({
          ...value,
          [REGION_FILTER_KEY]: null,
          [BRANCH_FILTER_KEY]: null,
        });
      }
      return;
    }

    if (
      !primaryFilterRef.current ||
      primaryFilterRef.current === REGION_BRANCH_FILTER
    ) {
      primaryFilterRef.current = REGION_BRANCH_FILTER;
      if (activeTab?.id === REGION_FILTER) {
        onChange({
          [REGION_FILTER_KEY]: newValue,
          [BRANCH_FILTER_KEY]: null,
          [EXPERIENCE_FILTER_KEY]: null,
          primaryFilter: {
            name: REGION_BRANCH_FILTER,
            key: 'region_id',
            value: newValue.value,
          },
        });
        dispatch(
          getDropdownExperiences({ page: 1, region_id: newValue.value }),
        );
      } else if (activeTab?.id === LOCATION_FILTER) {
        onChange({
          [BRANCH_FILTER_KEY]: newValue,
          [REGION_FILTER_KEY]: null,
          [EXPERIENCE_FILTER_KEY]: null,
          primaryFilter: {
            name: REGION_BRANCH_FILTER,
            key: 'entity_id',
            value: newValue.value,
          },
        });
        dispatch(
          getDropdownExperiences({ page: 1, entity_id: newValue.value }),
        );
      }
    } else if (activeTab?.id === REGION_FILTER) {
      onChange({
        ...value,
        [REGION_FILTER_KEY]: newValue,
        [BRANCH_FILTER_KEY]: null,
      });
    } else if (activeTab?.id === LOCATION_FILTER) {
      onChange({
        ...value,
        [BRANCH_FILTER_KEY]: newValue,
        [REGION_FILTER_KEY]: null,
      });
    }
  };

  const handleExperienceChange = (newExperience) => {
    if (
      !primaryFilterRef.current ||
      primaryFilterRef.current === EXPERIENCE_FILTER
    ) {
      primaryFilterRef.current =
        newExperience === null ? null : EXPERIENCE_FILTER;
      onChange({
        [BRANCH_FILTER_KEY]: null,
        [REGION_FILTER_KEY]: null,
        [EXPERIENCE_FILTER_KEY]: newExperience,
        primaryFilter: {
          name: EXPERIENCE_FILTER,
          key: 'experience_id',
          value: newExperience?.value,
        },
      });
      getRegionBranchFilterData(newExperience?.value);
    } else {
      onChange({ ...value, [EXPERIENCE_FILTER_KEY]: newExperience });
    }
  };

  useEffect(() => {
    const { activeTab, setActiveTab, activeTabRef } =
      regionLocationSelectRef.current?.selectRef ?? {};
    if (
      activeTab?.key !== REGION_FILTER_KEY &&
      !isEmpty(value[REGION_FILTER_KEY])
    ) {
      const [activeTabData] = regionAndLocationsTabs;
      setActiveTab(activeTabData);
      activeTabRef.current = activeTabData;
      currentTabRef.current = activeTabData;
    }
    if (
      activeTab?.key !== BRANCH_FILTER_KEY &&
      !isEmpty(value[BRANCH_FILTER_KEY])
    ) {
      const [, activeTabData] = regionAndLocationsTabs;
      setActiveTab(activeTabData);
      activeTabRef.current = activeTabData;
      currentTabRef.current = activeTabData;
    }
  }, [value]);

  useEffect(() => {
    if (
      value?.primaryFilter?.name &&
      primaryFilterRef.current !== value?.primaryFilter?.name
    ) {
      primaryFilterRef.current = value.primaryFilter.name;
    }
  }, [value?.primaryFilter?.name]);

  const filterDropdowns = (
    <>
      <SelectWithCustomSuffixIcon
        ref={regionLocationSelectRef}
        id="region-location-list"
        name="region-location-name"
        currentFilterClassName="region-location-list-filter"
        placeholder={`${t('region')}/${t('location')}`}
        selectTabs={regionAndLocationsTabs}
        onTabChange={onTabChange}
        onChange={handleFilterChange}
        disableVirtualScroll
        value={
          currentTabRef.current?.id === REGION_FILTER
            ? value[REGION_FILTER_KEY]
            : value[BRANCH_FILTER_KEY]
        }
        {...rest}
      />
      <ExpListFilter
        value={value[EXPERIENCE_FILTER_KEY]}
        onChange={handleExperienceChange}
        {...rest}
      />
    </>
  );

  return addWrapper ? (
    <div className="region-branch-exp-filter">{filterDropdowns}</div>
  ) : (
    filterDropdowns
  );
}

export default RegionLocationExpFilter;
