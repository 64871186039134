import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import dayjs from 'dayjs';
import { every, isEmpty } from 'lodash';

import { Loader } from 'components';
import {
  BRANCH_FILTER,
  EXPERIENCE_FILTER,
  QUESTION_OPTIONS_FILTER,
  REGION_FILTER,
  SURVEY_QUESTION_FILTER,
} from 'components/filters/filterTypes';
import { Icon } from 'components/ui';
import UnavailableFeatureWrapper from 'components/unavailableFeatures/UnavailableFeatureWrapper';
import { setBranchComparison } from 'redux/branchComparison/branchComparisonSlice';
import { getQuestionOptions } from 'redux/surveys/surveysActions';
import {
  COMPARISON_METHODS,
  COMPARISON_PREVIEWS,
} from 'utils/constants/compareFeedbacks';
import parseComparison, {
  getComparisonParams,
} from 'utils/helpers/branchComparisonHelper';
import useFetchFiltersData from 'utils/hooks/useFetchFiltersData';

import ComparisonSection from './ComparisonSection';
import NoDataSection from './NoDataSection';
import NonSelectedComparisonFilters from './NonSelectedComparisonFilters';
import SelectedComparisonFilters from './SelectedComparisonFilters';

import './_branchCompare.scss';

function BranchCompare() {
  const { fetchFiltersData } = useFetchFiltersData();
  const { t } = useTranslation();
  const reportRef = useRef();
  const locationBanner = useRef();

  const dispatch = useDispatch();
  const { parentEntity, user } = useSelector((state) => state.authentication);
  const { allowed_analytics: { analytics } = {} } = parentEntity ?? {};
  const showComparison = analytics?.location_comparison || user?.is_superuser;
  const { isLoading, comparison } = useSelector(
    (state) => state.branchComparison,
  );

  const [comparisonMethod, setComparisonMethod] = useState(
    COMPARISON_METHODS[0],
  );
  const [comparisonPreview, setComparisonPreview] = useState(
    COMPARISON_PREVIEWS[2],
  );
  const [selectedComparisonFilters, setSelectedComparisonFilters] = useState([
    {},
    {},
  ]);
  const [comparisonPrimaryParmas, setComparisonPrimaryParmas] = useState({});
  const [comparisonParams, setComparisonParams] = useState({});

  const [selectedComparison, setSelectedComparison] = useState([]); // COMPARISON RESULT

  useEffect(() => {
    if (isEmpty(comparison)) {
      setSelectedComparison({});
    } else {
      setSelectedComparison(parseComparison(comparison, comparisonMethod, t));
    }
  }, [comparison]);

  // To Clear the Old Comparison data when Component Unmount(Page got Changed)
  useEffect(() => {
    if (showComparison)
      fetchFiltersData({
        [EXPERIENCE_FILTER]: {},
        [REGION_FILTER]: {},
        [BRANCH_FILTER]: {},
        [SURVEY_QUESTION_FILTER]: {},
      });
    return () => {
      dispatch(setBranchComparison({}));
    };
  }, [showComparison]);

  const printTable = useReactToPrint({
    content: () => reportRef.current,
    pageStyle: '@page { size: auto;  margin: 15mm; } }',
    documentTitle: `${parentEntity.name} comparison_${dayjs().format(
      'YYYYMMDD',
    )}`,
  });

  const handlePrint = () => {
    locationBanner.current.style.top = '0';
    printTable();
    locationBanner.current.style.top = '5rem';
  };

  const getBranchComparisonHandler = (comparisonFilters, otherFilters) => {
    const isValidFilters =
      comparisonFilters.filter((filter) => !isEmpty(filter)).length > 1;
    if (isValidFilters) {
      const { params, comparisonAction } = getComparisonParams({
        comparisonMethod,
        comparisonByParams: comparisonFilters,
        comparisonPrimaryParmas,
        comparisonFilters: otherFilters,
      });
      if (comparisonAction && showComparison)
        dispatch(comparisonAction(params));
    }
  };

  const addSelectedComparisonFilters = () => {
    setSelectedComparisonFilters((p) => [...p, {}]);
  };

  const onChangeComparisonMethod = (method) => {
    if (method.id === comparisonMethod.id) return;
    fetchFiltersData({ ...method.filters, [method.id]: {} });
    if (comparisonMethod.resetDropdownsAction) {
      dispatch(comparisonMethod.resetDropdownsAction());
    }
    setComparisonMethod(method);
    setSelectedComparisonFilters([{}, {}]);
    setSelectedComparison({});
  };

  const onChangeSelectedComparisonFilter = (newFilters) => {
    setSelectedComparisonFilters(newFilters);
    getBranchComparisonHandler(newFilters, comparisonParams);
  };

  const onChangePrimaryComparisonParams = (primaryParams) => {
    if (
      comparisonMethod.id === QUESTION_OPTIONS_FILTER &&
      !isEmpty(primaryParams[SURVEY_QUESTION_FILTER])
    ) {
      const surveyQuestionFilter = primaryParams[SURVEY_QUESTION_FILTER];
      const apiParams = {
        survey_id: surveyQuestionFilter.parent.key,
        question_id: surveyQuestionFilter.child.key,
      };
      fetchFiltersData(comparisonMethod.filters, {
        survey_id: surveyQuestionFilter.parent.key,
        page: 1,
      });
      dispatch(getQuestionOptions(apiParams));
    }
    if (
      !isEmpty(comparisonMethod.primaryFilters) &&
      every(primaryParams, isEmpty) &&
      comparisonMethod.resetDropdownsAction
    ) {
      dispatch(comparisonMethod.resetDropdownsAction());
    }
    setComparisonPrimaryParmas(primaryParams);
    // Empty all selected and non-selected filters
    setSelectedComparison({});
    setSelectedComparisonFilters([{}, {}]);
  };

  const onChangeSecondaryComparisonParams = (otherComparisonFilters) => {
    setComparisonParams(otherComparisonFilters);
    getBranchComparisonHandler(
      selectedComparisonFilters,
      otherComparisonFilters,
    );
  };

  return (
    <UnavailableFeatureWrapper
      featureName={t('comparison')}
      wrapperRef={reportRef}
      isFeatureAllowed={showComparison}
      className="comparison__container"
    >
      <div className="comparison__filters-container">
        <div className="comparison__header">
          <h2 className="m-0">{t('locationComparison.title')}</h2>
          {isEmpty(selectedComparison) ? null : (
            <button
              onClick={handlePrint}
              className="button comparison__print font-body"
              type="button"
            >
              <Icon type="print" />
              <span>{t('printPdf')}</span>
            </button>
          )}
        </div>
        <div className="comparison__filters">
          <NonSelectedComparisonFilters
            comparisonMethod={comparisonMethod}
            onChangeComparisonMethod={onChangeComparisonMethod}
            comparisonPreview={comparisonPreview}
            setComparisonPreview={setComparisonPreview}
            onChangeComparisonParams={onChangeSecondaryComparisonParams}
            onChangePrimaryComparisonParams={onChangePrimaryComparisonParams}
          />
        </div>
      </div>
      <div className="comparison__results-container">
        <div className="comparison__results">
          <div
            style={{
              gridTemplateColumns: `minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) ${
                selectedComparisonFilters.length > 2
                  ? 'minmax(100px, 1fr)'
                  : '40px'
              }`,
            }}
            className="results-header comparison__banner--sticky MainFilterWrap"
            ref={locationBanner}
          >
            <div>
              <h4>{t(comparisonMethod.name)}s</h4>
              <p>{t('locationComparison.chooseComparison')}</p>
            </div>
            <SelectedComparisonFilters
              selectedComparisonFilters={selectedComparisonFilters}
              onChangeSelectedComparisonFilter={
                onChangeSelectedComparisonFilter
              }
              addSelectedComparisonFilters={addSelectedComparisonFilters}
              comparisonMethod={comparisonMethod}
              comparisonPrimaryParmas={comparisonPrimaryParmas}
            />
          </div>
          <div>
            {isLoading ? (
              <div>
                <Loader />
              </div>
            ) : isEmpty(selectedComparison) ? (
              <NoDataSection />
            ) : (
              <div className="comparison__table">
                {selectedComparison.map((section, idx) => {
                  return (
                    <ComparisonSection
                      selectedComparisonFilters={
                        selectedComparisonFilters.length
                      }
                      previewType={comparisonPreview}
                      title={section.title}
                      data={section.data}
                      key={idx}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </UnavailableFeatureWrapper>
  );
}

export default BranchCompare;
