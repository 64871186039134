import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { entityListAPICallV2 } from 'apis/cxmeter';
import { LocationThumbnail } from 'components/locationThumbnail';
import WarningModal from 'components/ui/modal/WarningModal';
import { OnboardingBranchContext } from 'contexts';
import OnboardingLayout from 'layouts/onboardingLayout/OnboardingLayout';
import { updateParentEntity } from 'redux/authentication/authenticationActions';
import { setLastOnboardingStep } from 'redux/authentication/authenticationSlice';
import { BRANCH_SETUP_STEP, URLS } from 'utils/constants';
import { getFullURL } from 'utils/helpers';
import { usePageTitle } from 'utils/hooks';

import './_branchList.scss';

export default function BranchListScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const { setBranch, addedBranch, setAddedBranch } = useContext(
    OnboardingBranchContext,
  );

  const dispatch = useDispatch();
  const { parentEntity } = useSelector((state) => state.authentication);
  const { type_name: orgType, name } = parentEntity ?? {};

  usePageTitle(t('pages.locationSetup'));

  const addBranch = () => {
    setBranch({});
    setAddedBranch(false);
    navigate(URLS.BRANCH_SETUP_URL);
  };

  const openBranch = (branch) => {
    setBranch(branch);
    navigate(`${URLS.BRANCH_SETUP_URL}/${branch.id}`);
  };

  const handleBranchSetupSkip = () => {
    setAddedBranch(false);
    dispatch(
      updateParentEntity({
        last_onboarding_url: getFullURL(URLS.ONBOARDING_SAAS_AGREEMENT),
        name,
      }),
    );
    dispatch(setLastOnboardingStep(URLS.ONBOARDING_SAAS_AGREEMENT));
    navigate(URLS.ONBOARDING_SAAS_AGREEMENT, { replace: true });
  };

  useEffect(() => {
    entityListAPICallV2()
      .then(setBranches)
      .finally(() => setLoading(false));
  }, []);

  return (
    <OnboardingLayout>
      <WarningModal
        isOpen={addedBranch}
        className="branch-list-warning-modal"
        onClose={() => setAddedBranch(false)}
        title={t('onboarding.locationSetup.locationAdded.title')}
        subTitle={t('onboarding.locationSetup.locationAdded.subTitle')}
        primaryActionTitle={t('yes')}
        primaryAction={addBranch}
        secondaryActionTitle={t('no')}
        secondaryAction={handleBranchSetupSkip}
      />
      <OnboardingLayout.Main>
        <OnboardingLayout.Header step={BRANCH_SETUP_STEP} />
        <OnboardingLayout.Form
          title={t('onboarding.locationSetup.title')}
          subtitle={t('onboarding.locationSetup.subTitle', {
            orgType: orgType || 'organization',
          })}
        >
          <div className="branches">
            {branches.map((branch) => (
              <LocationThumbnail
                key={branch.id}
                onSelect={() => openBranch(branch)}
              >
                {branch.name}
              </LocationThumbnail>
            ))}
            <LocationThumbnail onSelect={addBranch}>
              <FontAwesomeIcon icon={faPlus} />
            </LocationThumbnail>
          </div>
        </OnboardingLayout.Form>
        <OnboardingLayout.Footer
          hideBackButton
          isLoading={loading}
          disableContinue={branches.length === 0}
          onClickContinue={handleBranchSetupSkip}
        />
      </OnboardingLayout.Main>
      <OnboardingLayout.Sidepane />
    </OnboardingLayout>
  );
}
