import { toast } from 'react-toastify';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';

import {
  createIndustryTypeAPICall,
  createOrgApiKeyAPICall,
  deleteOrgApiKeyAPICall,
  getOrgApiKeysAPICall,
  listEntitySurveyAssignment,
  parentEntityGetAPICall,
  parentEntityTypeAPICall,
  setBranchActivationAPICall,
  updateOrgAPICall,
  updateOrgApiKeyAPICall,
  updateOrgConfigAPICall,
} from 'apis/cxmeter';
import getLangsAPICall from 'apis/localization';
import { getParentEntitiesList } from 'redux/authentication/authenticationActions';
import { setParentEntity } from 'redux/authentication/authenticationSlice';
import { showErrorToast, translateToast } from 'utils/helpers';

export const getOrg = createAsyncThunk('settings/getOrg', async (params) =>
  parentEntityGetAPICall(params).catch(showErrorToast),
);

const updateOrgData = (data, getState, dispatch) => {
  const parentEntity = get(getState(), 'authentication.parentEntity', {});
  dispatch(setParentEntity({ ...parentEntity, ...data }));
  return data;
};

export const updateOrg = createAsyncThunk(
  'settings/updateOrg',
  async (params, { dispatch, getState }) =>
    updateOrgAPICall(params)
      .then((data) => {
        dispatch(getParentEntitiesList());
        return updateOrgData(data, getState, dispatch);
      })
      .catch(showErrorToast),
);

export const updateOrgConfig = createAsyncThunk(
  'settings/updateOrgConfig',
  async (params, { dispatch, getState, rejectWithValue }) =>
    updateOrgConfigAPICall(params)
      .then((data) => updateOrgData(data, getState, dispatch))
      .catch((e) => showErrorToast(e, rejectWithValue)),
);

export const getBranchAssignments = createAsyncThunk(
  'settings/getBranchAssignments',
  async (params) => listEntitySurveyAssignment(params).catch(showErrorToast),
);

export const changeBranchStatus = createAsyncThunk(
  'settings/changeBranchStatus',
  async (params) =>
    setBranchActivationAPICall(params)
      .then((data) => {
        toast.success(translateToast('updated', 'location'));
        return data;
      })
      .catch((e) => Promise.reject(e.response.data.detail)),
);

export const getLocalization = createAsyncThunk(
  'settings/getLocalization',
  async (params) => getLangsAPICall(params).catch(showErrorToast),
);

export const getIndustryTypes = createAsyncThunk(
  'settings/getIndustryTypes',
  async (params) => parentEntityTypeAPICall(params).catch(showErrorToast),
);

export const createIndustryType = createAsyncThunk(
  'settings/createIndustryType',
  async (data) => createIndustryTypeAPICall(data).catch(showErrorToast),
);

export const getOrgApiKey = createAsyncThunk(
  'settings/getOrgApiKey',
  async () =>
    getOrgApiKeysAPICall()
      .then((apiKeys) => {
        return apiKeys[0];
      })
      .catch(showErrorToast),
);

export const generateOrgApiKey = createAsyncThunk(
  'settings/generateOrgApiKey',
  async (params, { rejectWithValue }) =>
    createOrgApiKeyAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const updateOrgApiKey = createAsyncThunk(
  'settings/updateOrgApiKey',
  async (params, { rejectWithValue }) =>
    updateOrgApiKeyAPICall(params).catch((e) =>
      showErrorToast(e, rejectWithValue),
    ),
);

export const deleteOrgApiKey = createAsyncThunk(
  'settings/deleteOrgApiKey',
  async (id, { rejectWithValue }) =>
    deleteOrgApiKeyAPICall(id).catch((e) => showErrorToast(e, rejectWithValue)),
);
