import dayjs from 'dayjs';

import i18next from 'i18next';
import {
  allowedNameCharactersRegex,
  containsLettersAndNumberRegex,
  genericPhoneNumberRegex,
} from 'utils/constants/regex';
import { isNumberOrParsableString } from 'utils/helpers';

import Yup from './Yup';
import { getInvalidNameErrorMessage } from './common';
import translateFieldError from './validationTranslations';

const { t } = i18next;

const tfe = translateFieldError;

const regOrganizationSchema = () => {
  const tncError = t('settings.organization.tncError');
  const phoneNumberError = t('fieldErrors.invalidPhoneNumber');

  return Yup.object().shape({
    name: Yup.string()
      .requiredTrimmed(
        tfe('blank', 'organizationName'),
        tfe('required', 'organizationName'),
      )
      .matches(allowedNameCharactersRegex, getInvalidNameErrorMessage())
      .matches(
        containsLettersAndNumberRegex,
        tfe('onlySpecialCharactersNotAllowed', 'organizationName'),
      )
      .range(
        1,
        128,
        tfe('minLength', 'organizationName', 1),
        tfe('maxLength', 'organizationName', 128),
      ),
    logo: Yup.mixed().nullable(),
    type: Yup.mixed().test(
      'valid-industry-type',
      tfe('required', 'industryType'),
      (industryType) => {
        if (isNumberOrParsableString(industryType)) return true;
        return industryType?.id;
      },
    ),
    email: Yup.string().nullable().customEmail().notRequired(),
    tnc: Yup.boolean().oneOf([true], tncError),
    phone_number: Yup.string()
      .nullable()
      .notRequired()
      .range(
        9,
        15,
        tfe('minLength', 'phoneNumber', 9),
        tfe('maxLength', 'phoneNumber', 15),
      )
      .matches(genericPhoneNumberRegex, phoneNumberError),
  });
};
export default regOrganizationSchema;

export const OrgAPIKeyScehma = () => {
  return Yup.object().shape({
    expiration_date: Yup.date()
      .nullable()
      .test(
        'is-future',
        t('settings.organization.apiKeyModal.futureDateError'),
        (value) => !value || dayjs(value).isAfter(dayjs()),
      ),
    is_active: Yup.boolean().nullable(),
  });
};
